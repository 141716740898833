import React, { useEffect, useState, useRef, useContext } from "react";
import commonHeader from "../../../json/commonHeader.json";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import { CallCenterContext } from "../../../context/CallCenterContext";
import DataTable from "../../pagination/DataTable";

import { useSearchParams } from "react-router-dom";

const AllContactData = ({ classPage = true }) => {
  document.title = "Consumer Data - Digital Call Center";

  const { jsonApi, mediaApi } = useApis();
  const [error, setError] = useState("");
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const formRef = useRef(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [vcfFile, setVcfFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [sortField, setSortField] = useState("fullName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [totalData, setTotalData] = useState(0);
  const [openImportContactModal, setOpenImportContactModal] = useState(false);

  const [openModelForCall, setOpenModelForCall] = useState({
    model: false,
    array: [],
  });
  const setOpenModelForCallHandler = (array) => {
    setOpenModelForCall({ model: true, array });
  };
  const makeCallModelHandleClose = () => {
    setOpenModelForCall({ model: false, array: [] });
  };
  const { makeCall } = useContext(CallCenterContext);

  const fetchPersonalContactDetails = async () => {
    const personalContactData = await jsonApi(
      appConstant.getContactData,
      {
        page,
        limit,
        search: searchParams.get("query") ? searchParams.get("query") : "",
        sortField,
        sortOrder,
      },
      null,
      false
    );

    if (!personalContactData.error) {
      setAllContactDetails(personalContactData.data.data || []);
      setTotalData(personalContactData.data.total);
    } else {
      setError(
        personalContactData.message
          ? personalContactData.message
          : "No data received"
      );
    }
  };

  useEffect(() => {
    fetchPersonalContactDetails();
  }, [page, limit, sortField, sortOrder, searchParams.get("query")]);

  const contactVcfFileHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("contact", vcfFile);
      const response = await mediaApi(
        appConstant.importContacts,
        null,
        formData,
        null
      );

      if (!response.error) {
        setSuccessMessage(response.message);
        setError("");
        fetchPersonalContactDetails();
      } else {
        setError(response.error.message || "An error occurred");
      }
    } catch (err) {
      setError("An unexpected error occurred");
    }
    setVcfFile(null);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <>
              {error ? (
                <div className="alert alert-danger">{error}</div>
              ) : (
                <div className={classPage ? "container-fluid" : "card"}>
                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <div className="row mt-4">
                    <div className="col-10">
                      <h4 className="page-title text-truncate text-dark font-weight-medium mb-2 ml-3">
                        Consumer Contact Data
                      </h4>
                    </div>
                    <div className="col-2">
                      <button
                        data-toggle="modal"
                        data-target="#personalContact"
                        className="page-link"
                      >
                        Import Contact{" "}
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card-body custom-field-button">
                        <div className="row ">{/*/Col*/}</div>
                        <DataTable
                          headers={commonHeader.allDataPersonalHeaders}
                          fetchedData={allContactDetails}
                          page={page}
                          limit={limit}
                          setPage={setPage}
                          totalData={totalData}
                          setLimit={setLimit}
                          init={fetchPersonalContactDetails}
                          setSortField={setSortField}
                          setSortOrder={setSortOrder}
                          sortOrder={sortOrder}
                          isFav={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-11 my-2 d-flex justify-content-end">
                    <div
                      className="modal fade"
                      id="personalContact"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4
                              className="modal-title text-dark font-weight-medium"
                              id="exampleModalLabel"
                            >
                              Consumer Contact
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              // onClick={() => setSelectedFileName("")}
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-lg-12">
                                <h5 className="mb-3 text-black font-weight-bold">
                                  Add Contacts Via VCF File
                                </h5>
                                <p className="mb-3 text-black">
                                  To Contacts via CSV, upload a CSV file
                                  containing the contact's name,phone,etc
                                </p>

                                <h6 className="mb-3 text-black font-weight-bold">
                                  File Upload (.vcf)
                                </h6>
                                <div className="row">
                                  <div className="col-sm-5 mb-3">
                                    <span className="btn btn-secondary btn-file">
                                      Choose a File{" "}
                                      <input
                                        type="file"
                                        accept=".vcf"
                                        onChange={(e) =>
                                          setVcfFile(e.target.files[0])
                                        }
                                      />
                                    </span>
                                  </div>
                                  <div className="col-sm-7 mb-3"></div>
                                  {/* <div className="col-sm-7 mb-3 d-flex align-items-center">
                        {selectedFileName && (
                          <div className="file-name ml-3">
                            {selectedFileName}
                          </div>
                        )}
                      </div> */}
                                  {/* {excelError ? (
                        <div className="col-12 my-4">
                          <div class="alert alert-danger" role="alert">
                          </div>
                        </div>
                      ) : (
                        <></>
                      )} */}
                                  <div className="col-12 my-4">
                                    <button
                                      type="button"
                                      onClick={contactVcfFileHandler}
                                      className="btn btn-primary"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      disabled={!vcfFile}
                                    >
                                      Bulk Add contacts
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto d-block img-fluid"
            title="Ads"
            alt="ads"
          />
        </div>
      </div>
    </div>
  );
};

export default AllContactData;
