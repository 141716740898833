// import React, { useEffect, useState } from "react";
// import SearchForm from "./SearchForm";
// import ContactHeader from "./contactHeader/ContactHeader";
// import { Outlet, useLocation, useSearchParams } from "react-router-dom";
// import SearchBased from "./SearchBased";

// const Contact = () => {
//   const [query, setQuery] = useState("");
//   const location = useLocation();
//   const [searchParams, setSearchParams] = useSearchParams();

//   useEffect(() => {
//     handleEmptySearch();
//   }, [location.pathname]);
//   useEffect(() => {
//     if (!query) {
//       searchParams.delete("query");
//       setSearchParams(searchParams);
//     } else {
//       setSearchParams({ query });
//     }
//   }, [query]);

//   const handleEmptySearch = () => {
//     console.log("firsta");
//     searchParams.delete("query");
//     setSearchParams(searchParams);
//     setQuery("");
//   };

//   return (
//     <>
//       <div className="page-breadcrumb">
//         <div className="row">
//           <SearchForm
//             query={query}
//             setQuery={setQuery}
//             handleEmptySearch={handleEmptySearch}
//           />
//           <ContactHeader />
//         </div>
//       </div>
//       <Outlet />
//     </>
//   );
// };

// export default Contact;
import React, { useEffect, useState } from "react";
import SearchForm from "./SearchForm";
import ContactHeader from "./contactHeader/ContactHeader";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import SearchBased from "./SearchBased";

const Contact = () => {
  const [query, setQuery] = useState("");
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const queryParam = searchParams.get("query");
    if (queryParam) {
      setQuery(queryParam);
    } else {
      setQuery("");
    }
  }, [searchParams]);

  useEffect(() => {
    handleEmptySearch();
  }, [location.pathname]);

  useEffect(() => {
    if (!query) {
      searchParams.delete("query");
      setSearchParams(searchParams);
    } else {
      setSearchParams({ query });
    }
  }, [query]);

  const handleEmptySearch = () => {
    searchParams.delete("query");
    setSearchParams(searchParams);
    setQuery("");
  };

  return (
    <>
      <Outlet />
    </>
  );
};

export default Contact;
