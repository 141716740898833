import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useApis from "../apis/useApis";
import appConstant from "./../json/appConstant.json";
import { Context } from "./Context";
import ModelUiBig from "../components/model/ModelUiBig";
import ViciDialLeadForm from "../components/LeadData/ViciDialLeadForm";
import ViewForm from "../components/customForm/viewForm/ViewForm";
import ModelUi from "../components/model/ModelUi";
export const VICIDialContext = createContext();
export const VICIDialContextProvider = ({ children }) => {
  const iframeRef = useRef(null);
  const [muted, setMuted] = useState(true);

  const toggleMute = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { type: muted ? "unmute" : "mute" },
        "*"
      );
    }
    setMuted(!muted);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isIframeVisible, setIframeVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [customFromData, setCustomFromData] = useState([]);
  const [selectedSkeleton, setSelectedSkeleton] = useState(null);
  const [viciDialUrl, setViciDialUrl] = useState("");
  const [currentLeadStatus, setCurrentLeadStatus] = useState("Not Login");
  const {
    currentLeadPhoneNumber,
    setCurrentLeadPhoneNumber,
    currentLead,
    setCurrentLead,
    selectedStatus,
    isAuthorized,
  } = useContext(Context);
  const { jsonApi } = useApis();
  const toggleIframe = () => {
    setIframeVisible(!isIframeVisible);
  };
  //
  const getStatus = async () => {
    const res = await jsonApi(appConstant.viciDialStatus, null, {}, true);
    if (res.error) {
    } else {
      if (res.data.data.error) {
        console.log(res.data.data.data.status);
        setCurrentLeadStatus(res.data.data.data.status);
      } else {
        if (res.data.data.status === "INCALL") {
          const { phone_number } = res.data.data;
          console.log(phone_number);
          if (currentLeadPhoneNumber !== phone_number) {
            setCurrentLeadPhoneNumber(phone_number);
            setShowModal(true);
            setCurrentLead({ ...res.data.data });
          } else {
          }
        }
      }
    }
  };
  const closeModal = () => {
    setShowModal(false);
    setShowFormModal(false);
    setSelectedSkeleton(null);
    hangUpViCidialCall();
  };
  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     if (isAuthorized) {
  //       await getStatus();
  //     }
  //   }, 2000); // Corrected interval duration

  //   return () => clearInterval(interval);
  // }, []);
  useEffect(() => {
    let isMounted = true;

    const fetchStatus = async () => {
      while (isMounted && isAuthorized) {
        await getStatus();
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    };

    fetchStatus();

    return () => {
      isMounted = false; // Cleanup to stop execution
    };
  }, [isAuthorized]); // Depend on `isAuthorized` to restart if it changes

  const initFunctions = async () => {
    const fetchData = await jsonApi(appConstant.formSkeletonGet, {}, {});
    if (!fetchData.error) {
      setCustomFromData(fetchData.data.data);
    } else {
      setCustomFromData([]);
    }
  };
  useEffect(() => {
    initFunctions();
  }, []);

  const viCiCallAgain = async () => {
    await jsonApi(appConstant.viCiCallAgain, {}, {});
  };

  useEffect(() => {
    setTimeout(() => {
      viCiCallAgain();
    }, 10000);
  }, [selectedStatus]);

  // console.log(selectedSkeleton);
  const selectSkeletonHandler = async (id) => {
    if (id) {
      setSelectedSkeleton(customFromData.find((item) => item._id === id));
    }
  };

  const getLoginUrlViCiDial = async () => {
    setViciDialUrl("");
    const res = await jsonApi(appConstant.viciDialLoginUrl, null, null, null);
    setViciDialUrl(res.data.data);
  };
  const logOutViCidial = async () => {
    const res = await jsonApi(appConstant.viciDialLogout, null, null, null);
    setViciDialUrl("");
  };
  const hangUpViCidialCall = async () => {
    const res = await jsonApi(appConstant.hangUpViCidialCall, null, null, null);
    closeModal();
  };

  useEffect(() => {
    if (selectedStatus === "Available") {
      getLoginUrlViCiDial();
    } else {
      logOutViCidial();
    }
  }, [selectedStatus]);

  const setViciDialStatus = async (status) => {
    const res = await jsonApi(
      appConstant.setViciDialStatus,
      null,
      { status },
      null
    );
  };

  return (
    <VICIDialContext.Provider
      value={{
        getLoginUrlViCiDial,
        currentLeadStatus,
        setCurrentLeadStatus,
        setViciDialStatus,
      }}
    >
      {children}
      {
        <div
          style={{
            position: "fixed",
            display: isIframeVisible ? "block" : "none",
            top: "20px",
            right: "20px",
            zIndex: 9999,
            width: "50vw",
            height: "90vh",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <button
              onClick={toggleIframe}
              style={{
                backgroundColor: "red",
                border: "none",
                color: "white",
                fontSize: "16px",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Hide
            </button>

            <button
              // onClick={handlePing}
              style={{
                backgroundColor: "green",
                border: "none",
                color: "white",
                fontSize: "16px",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Ping
            </button>
          </div>

          {viciDialUrl ? (
            <iframe
              src={viciDialUrl}
              title="VICI DIAL"
              allow="camera; microphone; autoplay; encrypted-media"
              sandbox="allow-scripts allow-same-origin allow-modals allow-forms"
              width="100%"
              height="100%"
              style={{
                border: "none",
              }}
            ></iframe>
          ) : (
            <></>
          )}
        </div>
      }
      <button
        className="btn btn-primary rounded-circle"
        style={{
          position: "fixed",
          bottom: "16px",
          right: "30px",
          width: "40px",
          zIndex: 10000,
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
        }}
        onClick={toggleIframe}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        +
      </button>

      {isHovered && (
        <div
          className="m-2 p-2 bg-dark text-white rounded"
          style={{
            position: "fixed",
            right: "16px",
            bottom: "72px",
            width: "200px",
            height: "30px",
            fontSize: "14px",
          }}
        >
          Click to open the VICI DIAL
        </div>
      )}

      <ModelUi show={showModal} handleClose={closeModal} title={"Lead"}>
        <h4>Lead Information</h4>

        <div className="row">
          <div className="col-4">
            <strong>Name:</strong>
          </div>
          <div className="col-8">
            {currentLead?.first_name} {currentLead?.last_name}
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <strong>Date Of Birth:</strong>
          </div>
          <div className="col-8">{currentLead?.date_of_birth}</div>
        </div>
        <div className="row">
          <div className="col-4">
            <strong>Phone Number:</strong>
          </div>
          <div className="col-8">{currentLead?.phone_number}</div>
        </div>

        <div className="row">
          <div className="col-4">
            <strong>Email:</strong>
          </div>
          <div className="col-8">{currentLead?.email}</div>
        </div>
        <div className="row">
          <div className="col-4">
            <strong>City:</strong>
          </div>
          <div className="col-8">{currentLead?.city}</div>
        </div>
        <div className="row">
          <div className="col-4">
            <strong>State:</strong>
          </div>
          <div className="col-8">{currentLead?.state}</div>
        </div>
        <div className="row">
          <div className="col-4">
            <strong>Zip Code:</strong>
          </div>
          <div className="col-8">{currentLead?.postal_code}</div>
        </div>

        <div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor={`leadTypeSelect`}>Select Lead Type</label>
              <select
                className="form-control black-border"
                id="leadTypeSelect"
                onChange={(e) => selectSkeletonHandler(e.target.value)}
              >
                <option value={""}>Select Lead Type</option>
                {customFromData.map((optionItem) => (
                  <option value={optionItem._id}>{optionItem.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div className="form-group row">
                      <button
                        className="next-step btn_green mx-2"
                        disabled={!selectedSkeleton}
                        onClick={() => setShowFormModal(true)}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                  <div className="col d-flex justify-content-center">
                    <div className="form-group row">
                      <button
                        className="  btn btn-danger mx-2"
                        onClick={hangUpViCidialCall}
                      >
                        End Call
                      </button>
                    </div>
                  </div>
                  <div className="col d-flex justify-content-center">
                    <div className="form-group row">
                      <button
                        className="  btn btn-danger mx-2"
                        onClick={hangUpViCidialCall}
                      >
                        Not Interested
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModelUi>
      <ModelUiBig show={showFormModal} handleClose={closeModal} title={"Lead"}>
        <ViewForm
          data={currentLead}
          fromSkeleton={selectedSkeleton ? selectedSkeleton.skeleton : []}
          closeModal={closeModal}
          formListName={currentLead?.first_name}
          show={showModal}
          demo={false}
          customForm={selectedSkeleton}
        />
      </ModelUiBig>
    </VICIDialContext.Provider>
  );
};
