import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAuthApis from "../../apis/useAuthApis";
import { useForm, Controller } from "react-hook-form";
import PasswordResetForm from "./PasswordResetForm";

const ForgetPassword = () => {
  document.title = "Forgot Password";
  const { resetPasswordApi, forgetPasswordApi } = useAuthApis();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data) => {
    const response = await forgetPasswordApi({ email: data.email });
    if (response.error) {
      setMessage(response.message);
    } else {
      setEmail(data.email);
      setMessage(`Passcode Sent Successfully to ${data.email}!`);
      setOtpSent(true); // Update state to disable button and show password reset form
    }
  };

  return (
    <>
      <div className="d-lg-flex half">
        <div
          className="bg order-1 order-md-2"
          style={{
            backgroundImage: 'url("/assets/images/background/login-bg.jpg")',
          }}
        />
        <div className="contents order-2 order-md-1">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-7">
                <Link
                  to="/"
                  className="login-back"
                  style={{ color: "blue", marginBottom: "40px" }}
                >
                  Back to Login
                </Link>
                <h3>Forgot Password</h3>
                {message && (
                  <div
                    className={`alert ${
                      message.includes("Successfully")
                        ? "alert-success"
                        : "alert-danger"
                    }`}
                  >
                    {message}
                  </div>
                )}

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group first">
                    <label htmlFor="email">Email</label>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: "Email is required" }}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          maxLength={256}
                          {...field}
                          onClick={() => setError("")}
                          placeholder="Your Email"
                          disabled={otpSent} // Disables input if OTP is sent
                        />
                      )}
                    />
                    {errors.email && (
                      <medium className="text-danger d-block mt-2">
                        {errors.email.message}
                      </medium>
                    )}
                  </div>
                  <button
                    type="submit"
                    disabled={otpSent}
                    defaultValue="Send Passcode"
                    className="btn btn-block btn-primary"
                  >
                    Get Passcode
                  </button>
                </form>
                {otpSent && (
                  <PasswordResetForm
                    email={email}
                    resetPasswordApi={resetPasswordApi}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
