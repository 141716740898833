import React, { useEffect, useState } from "react";
import ContactHeader from "./contactHeader/ContactHeader";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import SearchForm from "./SearchForm";

const MainHeader = () => {
  const [query, setQuery] = useState("");
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    handleEmptySearch();
  }, [location.pathname]);
  useEffect(() => {
    if (!query) {
      searchParams.delete("query");
      setSearchParams(searchParams);
    } else {
      setSearchParams({ query });
    }
  }, [query]);

  const handleEmptySearch = () => {
    console.log("firsta");
    searchParams.delete("query");
    setSearchParams(searchParams);
    setQuery("");
  };
  return (
    <>
      <Outlet />
    </>
  );
};

export default MainHeader;
