import React from "react";
import { validateEmail } from "../validation/Validation";

const ArrayDoubleFieldEmail = ({
  errors,
  fieldName,
  fieldShowName,
  register,
  useFieldArray,
  control,
  showButton
}) => {
  const {
    fields: personalFieldsEmail,
    append: appendPersonalEmail,
    remove: removePersonalEmail,
  } = useFieldArray({
    control,
    name: fieldName,
  });
  return (
    <div className="row my-2">
      <div className="col-7 pt-2">
        <label htmlFor={fieldName} className="">
          {fieldShowName}
        </label>
      </div>
      <div className="col-5">
        <div className="form-group d-flex align-items-center">
          <div className="form-group" style={{ width: "100%" }}>
            {personalFieldsEmail.map((item, index) => (
              <>
                <div className="d-flex ">
                  <input
                    type="email"
                    className={`form-control black-border mb-2 ${
                      errors.email ? "border border-danger" : ""
                    }`}
                    id="email"
                    maxLength={256}
                    //placeholder="abc@gmail.com"
                    {...register(`${fieldName}.${index}.value`, {
                      validate: validateEmail,
                    })}
                  />
                  <input
                    type="text"
                    className={`form-control black-border mb-2 ${
                      errors.email ? "border border-danger" : ""
                    }`}
                    maxLength={256}
                    //placeholder="abc@gmail.com"
                    {...register(`${fieldName}.${index}.type`)}
                  />

                  {index !== 0 ? (
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-circle m-2"
                      onClick={() => {
                        removePersonalEmail(index);
                      }}
                      style={{ color: "red" }}
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                  ) : (
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-circle m-2"
                      style={{ visibility: "hidden" }}
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                  )}
                </div>
                {errors?.email && (
                  <p className="text-danger">
                    {errors?.email[index]?.value.message}
                  </p>
                )}
              </>
            ))}
          </div>
          {showButton && 
          <div className="d-flex justify-content-center align-items-center pe-4">
          <button
            type="button"
            className="btn btn-outline-primary btn-circle"
            data-toggle="modal"
            data-target="#primaryContactModal"
            onClick={() => {
              appendPersonalEmail({
                value: "",
                type: "Personal",
              });
            }}
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
        }
          
        </div>
      </div>
    </div>
  );
};

export default ArrayDoubleFieldEmail;
