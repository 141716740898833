import React, { useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import appConstant from "../../json/appConstant.json";
import { Context } from "../../context/Context";
import useApis from "../../apis/useApis";
import DragsAndDrop from "../../image/DragsAndDrop";
import useAuthApis from "../../apis/useAuthApis";

const UpdateProfile = () => {
  document.title = "Update Profile - Digital Call Center";
  const { homeApi } = useAuthApis();
  const { userData, setUserData } = useContext(Context);
  const { jsonApi, mediaApi } = useApis();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const [error, setError] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const today = new Date();
  const minAgeDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];

  useEffect(() => {
    if (userData?.name) {
      setValue("name", userData?.name || "");
      setValue("phone", userData?.phone || "");
      setValue(
        "dateOfBirth",
        userData?.dateOfBirth
          ? new Date(userData?.dateOfBirth).toISOString().split("T")[0]
          : ""
      );
      setValue("address", userData?.address || "");
      setValue("email", userData?.email || "");
      setValue("gender", userData?.gender || "");
    }
  }, [userData, setValue]);

  const onSubmit = async (data) => {
    try {
      // Assuming updateUserProfileDataApi is a function that accepts the updated data
      const response = await jsonApi(
        appConstant.updateProfile,
        null,
        data,
        null
      );

      if (!response.error) {
        setSuccessMessage("Profile updated successfully!");
        setError("");
        // Update the userData context with the new data
        setUserData({
          ...userData,
          ...data,
        });
      } else {
        setError(response.error.message || "An error occurred");
      }
      if (data.logo) {
        const formData = new FormData();
        formData.append("logo", data.logo); // data.logo is a FileList, get the first file
        const response2 = await mediaApi(
          appConstant.updateProfileImageOfAdmin,
          null,
          formData,
          null
        );
        if (!response2.error) {
          setSuccessMessage("Profile image updated successfully!");
          setError("");
          await homeApi();
        }
      }
    } catch (err) {
      setError("An unexpected error occurred");
    }
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000); // Adjust time (in milliseconds) as needed

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }
  }, [successMessage]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <h3 className="page-title text-dark font-weight-medium mt-2 ml-3">
              Update Profile
            </h3>
            <div className="card">
              <div className="card-body custom-field-button">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">Name</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            maxLength={256}
                            placeholder="Name"
                            className={`form-control ${
                              errors.name ? "is-invalid" : ""
                            }`}
                            {...field}
                            {...register("name", {
                              required: "Name is required",
                            })}
                          />
                        )}
                      />
                      {errors.name && (
                        <p className="text-danger">{errors.name.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">Phone</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            placeholder="phone"
                            maxLength={256}
                            className={`form-control ${
                              errors.phone ? "is-invalid" : ""
                            }`}
                            {...field}
                            {...register("phone", {
                              required: "Phone is required",
                            })}
                          />
                        )}
                      />
                      {errors.phone && (
                        <p className="text-danger">{errors.phone.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Date of Birth
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="dateOfBirth"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="date"
                            placeholder="Date of Birth"
                            className={`form-control ${
                              errors.dateOfBirth ? "is-invalid" : ""
                            }`}
                            max={minAgeDate}
                            {...field}
                            {...register("dateOfBirth", {
                              required: "Date of Birth is required",
                            })}
                          />
                        )}
                      />
                      {errors.dateOfBirth && (
                        <p className="text-danger">
                          {errors.dateOfBirth.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Address
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            placeholder="Address"
                            className={`form-control ${
                              errors.address ? "is-invalid" : ""
                            }`}
                            {...field}
                            {...register("address", {
                              required: "Address is required",
                            })}
                          />
                        )}
                      />
                      {errors.address && (
                        <p className="text-danger">{errors.address.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">Email</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="email"
                            placeholder="Email"
                            maxLength={256}
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            {...field}
                            disabled
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Invalid email address",
                              },
                            })}
                          />
                        )}
                      />
                      {errors.email && (
                        <p className="text-danger">{errors.email.message}</p>
                      )}
                    </div>
                  </div>
                  {/* <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">Gender</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                          <select
                            className={`form-control ${
                              errors.gender ? "is-invalid" : ""
                            }`}
                            {...field}
                            {...register("gender", {
                              required: "Gender is required",
                            })}
                          >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </select>
                        )}
                      />
                      {errors.gender && (
                        <p className="text-danger">{errors.gender.message}</p>
                      )}
                    </div>
                  </div> */}
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">Image</label>
                    <div className="col-lg-10 col-md-9 col-sm-8 ">
                      <div style={{ width: "400px" }}>
                        <DragsAndDrop
                          imgKey={userData?.profileImage}
                          className={"admin-profile-round"}
                          heading="Upload Image"
                          inputName="Image"
                          aspect={1 / 1}
                          uploadFile={(x) => {
                            setValue("logo", x);
                          }}
                        />
                      </div>
                      {errors.email && (
                        <p className="text-danger">{errors.email.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-2 col-md-3 col-sm-4">&nbsp;</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-rounded px-4"
                      >
                        Update
                      </button>
                      {/* <button  className="btn btn-sm btn-success btn-rounded px-4 ml-2">Change Profile</button>                    */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
              alt="Ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;
