import React, { useContext, useEffect, useState } from "react";
import "./dashboard.css";
import { Phone, UserCheck, Award } from "react-feather";
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";
import { Context } from "../../context/Context";
import DataTable from "../pagination/DataTable";
import commonHeader from "../../json/commonHeader.json";
import { RefreshCcw} from "react-feather";

const Dashboard = () => {
  document.title = "Dashboard - Digital Call Center";
  const { jsonApi } = useApis();
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [lastSeen, setLastSeen] = useState(false);
  const { userData } = useContext(Context);

  // const getAgentLastSeen = async () => {
  //   let params;
  //   //Getting data based on user if SuperAdmin then all data
  //   //and if Company id then there all employees
  //   // if (userData.type === "SuperAdmin") {
  //   //   params = { duration: 60 };
  //   // } else {
  //   params = {
  //     duration: 60,
  //     companyId: userData.company,
  //   };
  //   // }
  //   try {
  //     const agentLastSeen = await jsonApi(
  //       appConstant.getAgentLastSeen,
  //       params,
  //       null,
  //       true
  //     );

  //     if (!agentLastSeen.error) {
  //       const filteredAgents = agentLastSeen.data.users.filter(
  //         (user) => user.type !== "SuperAdmin" && user.type !== "Company"
  //       );
  //       setLastSeen(filteredAgents);
  //     } else {
  //       console.error(agentLastSeen.message);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "An error occurred while fetching agent last seen data:",
  //       error
  //     );
  //   }
  // };

  // useEffect(() => {
  //   getAgentLastSeen();
  // }, [updateFlag]);.


  const getAgentLastSeen = async () => {
    let params = {
      duration: 30,
      companyId: userData.company,
      page,
      limit,
    };
  
    try {
      const agentLastSeen = await jsonApi(
        appConstant.getAgentLastSeen,
        params,
        null,
        true
      );
  
      if (!agentLastSeen.error) {
        // const filteredAgents = agentLastSeen?.data?.data
        // ?.filter(
        //   (user) => user.type !== "SuperAdmin" && user.type !== "Company"
        // );
        setLastSeen(agentLastSeen?.data?.data);
        setTotalData(agentLastSeen?.data?.total);
      } else {
        console.error(agentLastSeen.message);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching agent last seen data:",
        error
      );
    }
  };
  
  useEffect(() => {
    getAgentLastSeen(); 
    const intervalId = setInterval(() => {
      getAgentLastSeen(); 
    }, 30000); 
    return () => clearInterval(intervalId);
  }, [page,limit]); 
  

  const handleClick=()=>{
    getAgentLastSeen();
  }
  console.log(handleClick)

  // const updateAgentLastSeen = async () => {
  //   const response = await jsonApi(
  //     appConstant.updateAgentLastSeen,
  //     null,
  //     null,
  //     true
  //   );
  //   if (!response.error) {
  //     setUpdateFlag((prev) => !prev);
  //   } else {
  //     console.error(response.message);
  //   }
  // };

  // useEffect(() => {
  //   updateAgentLastSeen();
  //   getAgentLastSeen();
  //   const interval = setInterval(async () => {
  //     await updateAgentLastSeen();
  //     await getAgentLastSeen();
  //   }, [30000]);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 text-dark">
          <div className="col-12 d-flex justify-content-between align-self-center">
            <h3 className="page-title text-dark font-weight-medium mt-2">
              Dashboard
            </h3>
          </div>

          <div className="col-6 mt-4">
            <h4>Call Summary</h4>
            <div className="d-flex justify-content-between mt-3">
              <div className="d-flex flex-fill align-items-center">
                <div className="icon-wrapper me-2">
                  <Phone />
                </div>
                <div>
                  <strong>Total Calls</strong>
                  <br />
                  <span>0</span>
                </div>
              </div>
              <div className="d-flex flex-fill align-items-center border-start border-end">
                <div className="icon-wrapper me-2">
                  <UserCheck />
                </div>
                <div>
                  <strong>Total Agents Online</strong>
                  <br />
                  <span>{lastSeen?.length || 0}</span>
                </div>
              </div>
              <div className="d-flex flex-fill align-items-center">
                <div className="icon-wrapper me-2">
                  <Award />
                </div>
                <div>
                  <strong>Best Performer</strong>
                  <br />
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 mt-4">
            <h4>Live Activity</h4>
            <div className="d-flex justify-content-between mt-3">
              <div className="d-flex flex-fill align-items-center">
                <div className="icon-wrapper me-2">
                  <Phone />
                </div>
                <div>
                  <strong>Live Call</strong>
                  <br />
                  <span>0</span>
                </div>
              </div>
              <div className="d-flex flex-fill align-items-center border-start border-end">
                <div className="icon-wrapper me-2">
                  <UserCheck />
                </div>
                <div>
                  <strong>Calls in Queue</strong>
                  <br />
                  <span>0</span>
                </div>
              </div>
              <div className="d-flex flex-fill align-items-center">
                <div className="icon-wrapper me-2">
                  <Award />
                </div>
                <div>
                  <strong>Available Agents</strong>
                  <br />
                  <span>0</span>
                </div>
              </div>
            </div>
          </div>
        </div>

          <div className="d-flex justify-content-between align-self-center">
            <h3 className="page-title text-dark font-weight-medium mt-2">
            Logged In Agents
            </h3>
            <RefreshCcw onClick={getAgentLastSeen}/>
            {/* <RefreshCcw className="refresh-icon" onClick={getAgentLastSeen} /> */}

          </div>


        <div className="table-responsive mt-5">
          <div
            id="default_order_wrapper"
            className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <DataTable
              headers={commonHeader.dashboard}
              fetchedData={lastSeen}
              page={page}
              limit={limit}
              setPage={setPage}
              totalData={totalData}
              setLimit={setLimit}
              init={getAgentLastSeen}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
