import React, { useState } from "react";
import AddCorporatePhone from "./AddCorporatePhone";
import AddEmail from "./AddEmail";
import AddNewPayment from "./AddNewPayment";
import EditPermission from "./EditPermission";
import AddStates from "./AddStates";

const Modules = () => {
  const [phoneModal, setPhoneModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [addStateModal, setAddStateModal] = useState(false);
  const [editPermissionModal, setEditPermissionModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [email, setEmail] = useState([]);
  const [addState, setAddState] = useState(false);

  const handlePhoneModal = () => {
    setPhoneModal(true);
  };

  const handleEmailShow = () => {
    setEmailModal(true);
  };
  const handleStateModal = () => {
    setAddStateModal(true);
  };

  const handlePermissionModal = () => {
    setEditPermissionModal(true);
  };

  const handlePaymentModal = () => {
    setPaymentModal(true);
  };

  const handleSavePhoneNumber = (newphoneNumber) => {
    setPhoneNumber(newphoneNumber); // Update corporatePhoneNumber state with new phone number
  };

  const handleEmailSave = (newEmail) => {
    setEmail(newEmail);
  };

  const handleStateSave = (newState) => {
    setAddState(newState);
  };

  return (
    <>
      <div className="page-breadcrumb">
        <div className="row">
          <div className="col-12 align-self-center">
            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
              Modules
            </h3>
          </div>
        </div>
      </div>

      {/* Corporate Phone */}
      <div className="row align-items-center mt-2 mb-1">
        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          Corporate Phone
        </label>
        <div className="col-xl-3 col-lg-7 col-10 col-md-7 mb-1">
          <input
            type="text"
            className="form-control"
            placeholder="555-555-5555"
            maxLength={15}
            value={phoneNumber}
          />
        </div>
        <div className="col-xl-1 col-md-1 col-2 mb-1">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={handlePhoneModal}
          >
            <i className="fa fa-plus" />
          </button>
        </div>
      </div>
      {phoneModal && (
        <AddCorporatePhone
          phoneModal={phoneModal}
          setPhoneModal={setPhoneModal}
          onSave={handleSavePhoneNumber}
          phoneNumber={phoneNumber}
        />
      )}

      {/* Email */}
      <div className="row align-items-center">
        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          Email
        </label>
        <div className="col-xl-3 col-lg-7 col-md-7 col-10 mb-1">
          <input
            type="text"
            className="form-control"
            placeholder="jane@gmail.com"
            value={email}
            maxLength={256}
          />
        </div>
        <div className="col-xl-1 col-md-1 col-2 mb-1">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            data-toggle="modal"
            data-target="#emailPopup"
            onClick={handleEmailShow}
          >
            <i className="fa fa-plus" />
          </button>
        </div>
      </div>
      {emailModal && (
        <AddEmail
          emailModal={emailModal}
          setEmailModal={setEmailModal}
          onSave={handleEmailSave}
          email={email}
        />
      )}

      {/* Payment Method */}
      <div className="my-5 w-100">
        <h4 className="font-weight-bold text-black mb-3">
          Manage Payment Method
        </h4>
        <button
          type="button"
          className="btn next-step btn_green mb-2 px-3"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={handlePaymentModal}
        >
          Add New Payment Method
        </button>
      </div>
      {paymentModal && (
        <AddNewPayment
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
        />
      )}

      {/* Edit Permission */}
      <div className="my-5 w-100">
        <label className="font-weight-bold mb-4">User Permission</label>
        <div className="table-responsive">
          <table className="table display no-wrap w-100 user_info_table">
            <thead>
              <tr>
                <th colSpan={3}>
                  <a href="#!">John Doe</a>
                  <i>(Super Administrator)</i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
                <td>
                  <a href="#!">Brown</a>,<a href="#!">David</a>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-success green_light_btn"
                    data-toggle="modal"
                    data-target="#editPermissionPopup"
                    onClick={handlePermissionModal}
                  >
                    Edit Permission
                  </button>
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <a href="#!">Anderson</a>,<a href="#!">Daniel</a>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-success green_light_btn"
                    data-toggle="modal"
                    data-target="#editPermissionPopup"
                    onClick={handlePermissionModal}
                  >
                    Edit Permission
                  </button>
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <a href="#!">Anderson</a>,<a href="#!">Daniel</a>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-success green_light_btn"
                    data-toggle="modal"
                    data-target="#editPermissionPopup"
                    onClick={handlePermissionModal}
                  >
                    Edit Permission
                  </button>
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <a href="#!">Anderson</a>,<a href="#!">Daniel</a>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-success green_light_btn"
                    data-toggle="modal"
                    data-target="#editPermissionPopup"
                    onClick={handlePermissionModal}
                  >
                    Edit Permission
                  </button>
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <a href="#!">Anderson</a>,<a href="#!">Daniel</a>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-success green_light_btn"
                    data-toggle="modal"
                    data-target="#editPermissionPopup"
                    onClick={handlePermissionModal}
                  >
                    Edit Permission
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {editPermissionModal && (
        <EditPermission
          editPermissionModal={editPermissionModal}
          setEditPermissionModal={setEditPermissionModal}
        />
      )}

      {/* Add States */}
      <div className="my-5 w-100">
        <div className="table-responsive">
          <table
            id="default_order"
            className="table table-striped display no-wrap w-100"
          >
            <thead>
              <tr>
                <th className="h5 font-weight-bold">Selected Products</th>
                <th className="h5 font-weight-bold">States</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Annuities</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={handleStateModal}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    Add States
                  </button>
                  <td className="text-primary" value={addState}></td>
                </td>
              </tr>
              <tr>
                <td>BOP (Business Owners Policy)</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={handleStateModal}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    Add States
                  </button>
                </td>
              </tr>
              <tr>
                <td>Commerical</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={handleStateModal}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    Add States
                  </button>
                </td>
              </tr>
              <tr>
                <td>Disability Individual</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={handleStateModal}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    Add States
                  </button>
                </td>
              </tr>
              <tr>
                <td>Health-ACA</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={handleStateModal}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    Add States
                  </button>
                </td>
              </tr>
              <tr>
                <td>Health-Group</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={handleStateModal}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    Add States
                  </button>
                </td>
              </tr>
              <tr>
                <td>Homeowners</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={handleStateModal}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    Add States
                  </button>
                </td>
              </tr>
              <tr>
                <td>Hospital Indemnity</td>
                <td className="text-primary">
                  GA HEIDIL, IN, IA, KS, KY LA ME, MO, MA, ML MN, MIS, MO, MT
                </td>
              </tr>
              <tr>
                <td>Life-Group</td>
                <td className="text-primary">
                  IL IN IA KS KY LA ME MO, MA, MI MN MS. MO MT
                </td>
              </tr>
              <tr>
                <td>Life-Individual</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={handleStateModal}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    Add States
                  </button>
                </td>
              </tr>
              <tr>
                <td>Long Term Care</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={handleStateModal}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    Add States
                  </button>
                </td>
              </tr>
              <tr>
                <td>Umbrella</td>
                <td className="text-primary">
                  JAKS KY LA ME MO MA, MI. MN. MS. MO MT
                </td>
              </tr>
              <tr>
                <td>Workers Comp</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={handleStateModal}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    Add States
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {addStateModal && (
        <AddStates
          addStateModal={addStateModal}
          setAddStateModal={setAddStateModal}
          addState={addState}
          onSave={handleStateSave}
        />
      )}
    </>
  );
};

export default Modules;
