import React from 'react'
import Modal from 'react-bootstrap/Modal';
const Delete = ({ show, handleClose, data, deleteFunction }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            className="modal fade"
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h4
                        className="modal-title text-dark font-weight-medium"
                        id="exampleModalLabel"
                    >
                        <p>Are you sure you want to delete?</p>
                    </h4>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => deleteFunction(data)}
                    >
                        Yes
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleClose}>
                        No
                    </button>
                </div>
            </div>

        </Modal>
    )
}

export default Delete