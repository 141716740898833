import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import commonHeader from "../../json/commonHeader.json";
import DataTable from "../pagination/DataTable";
import { useNavigate, useParams } from "react-router-dom";

const ContactHistory = ({ userDetails }) => {
  const [toggle, setToggle] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setToggle(false);
  };

  const notes = userDetails?.notes || [];
  const callHistory = userDetails?.callHistory || [];
  const fullName = userDetails?.fullName || [];

  const mergedData = [
    ...notes.map((item) => ({ ...item, typeOfInteraction: "Notes" })),
    ...callHistory.map((item) => ({
      ...item,
      typeOfInteraction: "Phone Calls",
    })),
  ];

  const updatedCallHistory = [
    ...callHistory.map((item) => ({ ...item, fullName: fullName })),
  ];

  const sortedData = mergedData.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const dataMapping = {
    All: sortedData,
    Notes: notes,
    "Phone Calls": updatedCallHistory,
  };

  const totalData = dataMapping[activeTab]?.length || 0;

  return (
    <div className="row mb-8">
      <div className="col-12 align-self-center">
        <h4 className="page-title text-truncate text-dark font-weight-medium mb-1">
          Contact History
        </h4>
        <Tab.Container activeKey={activeTab}>
          <Nav variant="tabs" className="mb-4">
            {commonHeader.businessInfoHeader.map((item, index) => (
              <Nav.Item key={index}>
                <Nav.Link eventKey={item} onClick={() => handleTabChange(item)}>
                  {item}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Tab.Container>

        <div className="tab-content" id="myTabContent">
          <div
            className={`tab-pane fade ${
              activeTab.toLowerCase() === "all" ? "show active" : ""
            }`}
            id="all"
            role="tabpanel"
            aria-labelledby="all-tab"
          >
            <div className="table-responsive">
              <div
                id="default_order_wrapper"
                className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
              >
                <DataTable
                  headers={commonHeader.allHeader}
                  fetchedData={sortedData}
                  setPage={setPage}
                  totalData={totalData}
                  limit={limit}
                  setLimit={setLimit}
                  page={page}
                  setSearch={setSearch}
                  isFrontendPagination={true}
                />
              </div>
            </div>
          </div>

          <div
            className={`tab-pane fade ${
              activeTab.toLowerCase() === "notes" ? "show active" : ""
            }`}
            id="notes"
            role="tabpanel"
            aria-labelledby="notes-tab"
          >
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="page-title text-dark font-weight-medium mt-2">
                List Of Notes
              </h3>
              <div className="d-flex justify-content-end ">
                <button
                  data-toggle="modal"
                  data-target="#personalContact"
                  className="page-link me-2 border border-primary"
                  onClick={() => navigate(`/contact/add/note/${id}`)}
                >
                  + Add Notes{" "}
                </button>
              </div>
            </div>

            <p>
              <div className="table-responsive mt-4">
                <div
                  id="default_order_wrapper"
                  className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                >
                  <DataTable
                    headers={commonHeader.notesHeader}
                    fetchedData={notes}
                    setPage={setPage}
                    totalData={totalData}
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setSearch={setSearch}
                    isFrontendPagination={true}
                  />
                </div>
              </div>
            </p>
          </div>

          {/* <div
            className={`tab-pane fade ${
              activeTab.toLowerCase() === "emails" ? "show active" : ""
            }`}
            id="emails"
            role="tabpanel"
            aria-labelledby="emails-tab"
          > */}
          {/* <EmailPage data={data}/> */}
          {/* <DataTable
                  headers={commonHeader.EmailHeader}
                  fetchedData={data}
                  setPage={setPage}
                  totalData={totalData}
                  limit={limit}
                  setLimit={setLimit}
                  page={page}
                  setSearch={setSearch}
                /> */}
          {/* <a
              href="#"
              onClick={(e) => {
                if (!data?.email || !data?.email[0]?.value) {
                  e.preventDefault();
                } else {
                  window.location.href = `mailto:${data?.email[0]?.value}`;
                }
              }}
              title={
                data?.email && data?.email[0]?.value
                  ? "Click to mail"
                  : "Email not available"
              }
            >
              Email
            </a> */}
          {/* </div> */}

          <div
            className={`tab-pane fade ${
              activeTab.toLowerCase() === "phone calls" ? "show active" : ""
            }`}
            id="phone-calls"
            role="tabpanel"
            aria-labelledby="phone-calls-tab"
          >
            <div className="table-responsive">
              <div
                id="default_order_wrapper"
                className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
              >
                <DataTable
                  headers={commonHeader.callHistoryHeader}
                  fetchedData={updatedCallHistory}
                  setPage={setPage}
                  totalData={totalData}
                  limit={limit}
                  setLimit={setLimit}
                  page={page}
                  setSearch={setSearch}
                  isFrontendPagination={true}
                />
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab.toLowerCase() === "text messages" ? "show active" : ""
            }`}
            id="text-messages"
            role="tabpanel"
            aria-labelledby="text-messages-tab"
          >
            <p>{/* <ComingSoon /> */}</p>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab.toLowerCase() === "us postal mail" ? "show active" : ""
            }`}
            id="us-postal-mail"
            role="tabpanel"
            aria-labelledby="us-postal-mail-tab"
          >
            <p>{/* <ComingSoon /> */}</p>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab.toLowerCase() === "chats" ? "show active" : ""
            }`}
            id="chats"
            role="tabpanel"
            aria-labelledby="chats-tab"
          >
            <p>{/* <ComingSoon /> */}</p>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab.toLowerCase() === "fax" ? "show active" : ""
            }`}
            id="fax"
            role="tabpanel"
            aria-labelledby="fax-tab"
          >
            <p>{/* <ComingSoon /> */}</p>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab.toLowerCase() === "video conferencing"
                ? "show active"
                : ""
            }`}
            id="video-conferencing"
            role="tabpanel"
            aria-labelledby="video-conferencing-tab"
          >
            <p>{/* <ComingSoon /> */}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHistory;
