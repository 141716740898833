import { useLocation, useNavigate } from "react-router-dom";

function HoverableName({ fullName, id, type }) {
  const navigate = useNavigate();

  // const handleClick=()=>{
  //   if(location.pathname === ("/contact/business")){
  //     navigate(`/contact/business/details/${id}`)
  //   }
  //   else if(location.pathname === ("/contact/personal")) {
  //     navigate(`/contact/personal/details/${id}`)
  //   }
  //   else if(location.pathname === ("/contact/lead")) {
  //     navigate(`/contact/lead/details/${id}`)
  //   }
  //   else if(location.pathname === ("/contact/business/owner")) {
  //     navigate(`/contact/business/details/${id}`)
  //   }
  //   else if(location.pathname === ("/contact/business/employee")) {
  //     navigate(`/contact/business/details/${id}`)
  //   }
  //   else if(location.pathname === ("/contact/business/executive")) {
  //     navigate(`/contact/business/details/${id}`)
  //   }
  //   else if(location.pathname === ("/contact/business/manager")) {
  //     navigate(`/contact/business/details/${id}`)
  //   }
  //   else if(location.pathname === ("/contact/business/human/resource")) {
  //     navigate(`/contact/business/details/${id}`)
  //   }
  //   else {
  //     console.log("Unknown path");
  //   }
  // }

  const handleClick = () => {
    if (
      type === "Business Info" ||
      type === "Human Resource" ||
      type === "Business Contact"
    ) {
      navigate(`/contact/business/details/${id}`);
    } else if (type === "Personal") {
      navigate(`/contact/personal/details/${id}`);
    } else if (type === "Lead") {
      navigate(`/contact/lead/details/${id}`);
    } else {
      console.log("Unknown path ");
    }
  };
  return (
    <div
      style={{ cursor: "pointer" }}
      title="Click to see details"
      // onClick={() =>  navigate(`/contact/personal/details/${id}`)}
      onClick={handleClick}
    >
      {fullName}
    </div>
  );
}

export default HoverableName;

// import { useState } from "react";
// import { Link } from "react-router-dom";

// function HoverableName({ fullName, id }) {
//   const [isHovered, setIsHovered] = useState(false);

//   return (
//     <td
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       style={{ cursor: "pointer", position: "relative" }}
//     >
//       {fullName}
//       {/* {fullName ? (
//         <Link to={` /spouse-profiles/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}> {fullName}</Link>
//       ):("No Data") } */}

//       <Link to={`/contact/personal/contact/data/${id}`}>
//         {isHovered && fullName && (
//           <div
//             // onClick={handleClick}
//             style={{
//               position: "absolute",
//               top: "-20px",
//               left: "100px",
//               backgroundColor: "#333",
//               color: "#fff",
//               padding: "5px",
//               borderRadius: "3px",
//               fontSize: "12px",
//               whiteSpace: "nowrap",
//               zIndex: 1,
//               cursor: "pointer",
//             }}
//           >
//             {fullName}
//           </div>
//         )}
//       </Link>
//     </td>
//   );
// }

// export default HoverableName;
