import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ImageComponent from "../../../media/ImageComponent";
import useFetchUserDetails from "../../../hooks/useFetchUserDetail";

const DependentsProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useFetchUserDetails(id);
  const dependents = data?.dependents || {};

  return (
    <div className="container-fluid">
      <div
        className="row d-flex align-items-center mx-4"
        style={{ marginTop: "4rem" }}
      >
        <h3 className="page-title text-truncate text-dark font-weight-medium">
          Dependent Profile
        </h3>
        {dependents && dependents.length > 0 && (
          <button
            type="button"
            className="btn waves-effect waves-light btn-rounded btn-sm btn-primary ml-3"
            data-toggle="modal"
            data-target="#editprofilepicModal"
            onClick={() =>
              navigate(`/contact/personal/edit/dependent?id=${id}`)
            }
          >
            Edit
          </button>
        )}
        <button
          type="button"
          className="btn waves-effect waves-light btn-rounded btn-sm btn-primary ml-3"
          data-toggle="modal"
          data-target="#editprofilepicModal"
          onClick={() => navigate(`/contact/personal/add/dependent?id=${id}`)}
        >
          Add
        </button>
      </div>

      <div className="row">
        {dependents.length > 0 ? (
          dependents.map((dependent, index) => (
            <div className="col-lg-6 mb-3" key={index}>
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-start">
                    <div
                      className="text-center mb-3"
                      style={{ marginRight: "20px", marginTop: "50px" }}
                    >
                      <ImageComponent
                        className="rounded-circle profile-image"
                        alt={`Dependent ${index + 1}`}
                        src={
                          dependent?.profileImage || "/assets/images/dummy.png"
                        }
                      />
                    </div>

                    <div style={{ flex: 1 }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                          Child #{index + 1}:
                        </h4>
                      </div>
                      {[
                        { label: "Name", value: dependent?.fullName || "" },
                        { label: "Age", value: dependent?.age || "" },
                        {
                          label: "DOB",
                          value: dependent?.dob
                            ? moment(dependent?.dob).format("YYYY/MM/DD")
                            : "",
                        },
                        { label: "Gender", value: dependent?.gender || "" },
                        {
                          label: "Social Security #",
                          value: dependent?.socialSecurity || "",
                        },
                        {
                          label: "Custody Type",
                          value: dependent?.custodyType || "",
                        },
                        {
                          label: "Relationship Type",
                          value: dependent?.relationshipType || "",
                        },
                        {
                          label: "Drivers License #",
                          value: dependent?.driversLicense || "",
                        },
                        {
                          label: "Drivers License State",
                          value: dependent?.driversLicenseState || "",
                        },
                        {
                          label: "Years of Driving",
                          value: dependent?.yearsOfDriving || "",
                        },
                        {
                          label: "Blood/Relative Info",
                          value: dependent?.bloodRelativeInfo || "",
                        },
                        {
                          label: "Biological Parent",
                          value: dependent?.biologicalParent || "",
                        },
                        {
                          label: "Current Living Profile",
                          value: dependent?.livingProfile || "",
                        },
                        {
                          label: "Grade Level",
                          value: dependent?.gradeLevel || "",
                        },
                        {
                          label: "Name of School",
                          value: dependent?.schoolName || "",
                        },
                        {
                          label: "Address of School",
                          value: dependent?.schoolAddress || "",
                        },
                        { label: "Major", value: dependent?.major || "" },
                      ].map((field, idx) => (
                        <div
                          className="form-group row"
                          style={{
                            justifyContent: "flex-end",
                            marginBottom: "0",
                          }}
                          key={idx}
                        >
                          <div className="col-12 d-flex align-items-center">
                            <label
                              className="col-form-label mr-3"
                              style={{
                                width: "150px",
                                textAlign: "left",
                                marginBottom: "0",
                              }}
                            >
                              {field.label} :
                            </label>
                            <div
                              className="font-weight-bold text-dark"
                              style={{ textAlign: "right", marginBottom: "0" }}
                            >
                              {field.value}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          // <div style={{marginLeft:"2.5rem"}}>
          //   <p
          //     style={{
          //       display: "flex",
          //       justifyContent: "center",
          //       alignItems: "center",
          //       height: "100px",
          //       fontSize: "36px",
          //       fontWeight: "bold",
          //     }}
          //   >
          //     No Dependent added!
          //   </p>
          // </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh", // Full viewport height for vertical centering
              width: "100%", // Full width for horizontal centering
            }}
          >
            <p
              style={{
                fontSize: "36px",
                fontWeight: "bold",
              }}
            >
              No Dependent added!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DependentsProfile;
