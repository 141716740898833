import React, { useState } from "react";

const SelectFelid = ({ addFelidHandler }) => {
  const [inputValue, setInputValue] = useState({});
  const [option, setOption] = useState([{ value: "", label: "" }]);

  const handleSubmit = (e) => {
    e.preventDefault();
    addFelidHandler({ ...inputValue, type: "Select", option });
    setInputValue(""); // Clear the input after submission
  };
  const addOptionHandler = () => {
    if (option === null) {
      setOption([{ value: "", label: "" }]);
    } else {
      setOption([...option, { value: "", label: "" }]);
    }
  };

  const onChangeOptionHandler = (index, value, name) => {
    const newOption = option.map((c, i) => {
      if (i === index) {
        c[name] = value;
        return c;
      } else {
        return c;
      }
    });
    setOption(newOption);
  };
  const onChange = (e) => {
    if (e.target.name === "name") {
      let value = e.target.value.replace(/\s+/g, "_");
      const isValidKey = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/.test(value);
      if (isValidKey || value.length === 0) {
        setInputValue((pre) => {
          return { ...pre, [e.target.name]: value };
        });
      }
    } else if (e.target.name === "required") {
      setInputValue((pre) => {
        return { ...pre, [e.target.name]: e.target.checked };
      });
    } else {
      setInputValue((pre) => {
        return { ...pre, [e.target.name]: e.target.value };
      });
    }
  };
  // console.log(inputValue);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div class="container">
          <div class="row mb-3 justify-content-center">
            <div class="col-12 text-center">
              <h2>Type: Select</h2>
            </div>
          </div>

          <div class="row mb-3 justify-content-center">
            <div class="col-12">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  required
                  name="name"
                  onChange={onChange}
                  value={inputValue.name}
                />
              </div>
            </div>
          </div>

          <div class="row mb-3 justify-content-center">
            <div class="col-12">
              <div class="form-group">
                <label for="label">Label</label>
                <input
                  type="text"
                  class="form-control"
                  id="label"
                  required
                  name="label"
                  onChange={onChange}
                  value={inputValue.label}
                />
              </div>
            </div>
          </div>

          <div class="row mb-3 justify-content-center">
            <div class="col-12">
              <div class="form-group">
                <label for="defaultValue">Default Value</label>
                <input
                  type="text"
                  class="form-control"
                  id="defaultValue"
                  name="defaultValue"
                  onChange={onChange}
                  value={inputValue.defaultValue}
                />
              </div>
            </div>
          </div>

          <div class="row mb-3 justify-content-center">
            <div class="col-12">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="required"
                  name="required"
                  onChange={onChange}
                  checked={inputValue.required}
                />
                <label class="form-check-label" for="required">
                  Required
                </label>
              </div>
            </div>
          </div>

          <div class="row mb-3 justify-content-center">
            <div class="col-12 text-center">
              <h2>Options</h2>
            </div>
            <div class="col-12">
              <div id="options-container">
                {option.map((item, index) => (
                  <div class="row mb-2">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="option-value-0">Value</label>
                        <input
                          type="text"
                          class="form-control"
                          id="option-value-0"
                          name="value"
                          onChange={(e) =>
                            onChangeOptionHandler(
                              index,
                              e.target.value,
                              e.target.name
                            )
                          }
                          value={item?.value || ""}
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="option-label-0">Label</label>
                        <input
                          type="text"
                          class="form-control"
                          id="option-label-0"
                          name="label"
                          onChange={(e) =>
                            onChangeOptionHandler(
                              index,
                              e.target.value,
                              e.target.name
                            )
                          }
                          value={item?.label || ""}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div class="col-12 text-center">
              <p onClick={addOptionHandler} class="btn btn-secondary">
                Add Option
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 align-self-center">
          <div className="w-100 d-flex justify-content-center align-item-center">
            <button type="submit" className="btn btn-primary">
              Add Felid
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SelectFelid;
