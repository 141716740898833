// import React, { useState } from "react";

// const CoverageInformation = ({ errors, register,  fieldName,
//   fieldShowName,
//   setValue,
//   watch, }) => {
//   const [currentCoverage, setCurrentCoverage] = useState("");
//   const [typeOfCoverage, setTypeOfCoverage] = useState("");

//   const handleCurrentCoverage = (e) => {
//     setCurrentCoverage(e.target.value);
//     if (e.target.value === "No") {
//       setTypeOfCoverage("");
//     }
//   };

//   const handleTypeOfCoverage = (e) => {
//     setTypeOfCoverage(e.target.value);
//   };

//   const handleClick = (value) => {
//     setValue(fieldName, value);
//   };
//   const value = watch(fieldName);

//   return (
//     <>
//       <div className="row my-2">
//       <div className="col-7 pt-2">
//         <label htmlFor="currentCoverage" className="">
//         </label>
//       </div>
//       <div className="col-5">
//         <div className="btn-group" role="group" aria-label="currentCoverage">
//           <button
//             type="button"
//             className={`btn ${
//               value === true ? "btn-success" : "btn-secondary"
//             } mr-2`}
//             {...register("currentCoverage")}
//             value={true}
//             onClick={handleCurrentCoverage}
//           >
//             Yes
//           </button>
//           <button
//             type="button"
//             className={`btn ${
//               value === false ? "btn-success" : "btn-secondary"
//             }`}
//             {...register("currentCoverage")}
//             value={false}
//             onClick={handleCurrentCoverage}
//           >
//             No
//           </button>
//         </div>
//       </div>
//     </div>

//       {currentCoverage === "Yes" && (
//         <div className="row my-2 pt-4">
//           <div className="col-7 pt-2">
//             <label className="typeOfCoverage">Type of Coverage:</label>
//           </div>
//           <div className="col-5">
//             <select
//               className="form-control border border-dark"
//               id="typeOfCoverage"
//               {...register("typeOfCoverage", {
//               })}
//               value={typeOfCoverage}
//               onChange={handleTypeOfCoverage}
//             >
//               <option value="Whole Life">Whole Life</option>
//               <option value="IUL">IUL</option>
//               <option value="UL">UL</option>
//               <option value="Final expense">Final expense</option>
//             </select>
//             {errors[fieldName] && (
//               <p className="text-danger">{errors[fieldName].message}</p>
//             )}
//           </div>
//         </div>
//       )}
//       {typeOfCoverage && (
//         <div className="row my-2 pt-4">
//           <div className="col-7 pt-2">
//             <label className="">{fieldShowName}</label>
//           </div>
//           <div className="col-5">
//             <select
//               className={`form-control border border-dark ${
//                 errors[fieldName] ? "border border-danger" : ""
//               }`}
//               id={fieldName}
//               {...register(fieldName, {
//                 required: false,
//               })}
//             >
//               <option value="5">5</option>
//               <option value="10">10</option>
//               <option value="15">15</option>
//               <option value="20">20</option>
//               <option value="25">25</option>
//               <option value="30">30</option>
//               <option value="35">35</option>
//               <option value="40">40</option>
//             </select>
//             {errors[fieldName] && (
//               <p className="text-danger">{errors[fieldName].message}</p>
//             )}
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default CoverageInformation;

// import React, { useState } from "react";

// const CoverageInformation = ({
//   errors,
//   register,
//   fieldShowName,
//   setValue,
// }) => {
//   const [currentCoverage, setCurrentCoverage] = useState("");
//   const [typeOfCoverage, setTypeOfCoverage] = useState("");
//   const [howLong, setHowLong] = useState("");

//   const handleClick = (value) => {
//     setValue(fieldName, value);
//     setCurrentCoverage(value ? "Yes" : "No");
//     if (value === "No") {
//       setTypeOfCoverage("");
//       setHowLong("");
//     }
//   };

//   const handleTypeOfCoverage = (e) => {
//     setTypeOfCoverage(e.target.value);
//     setHowLong("");
//   };

//   return (
//     <>
//       <div className="row my-2">
//         <div className="col-7 pt-2">
//           <label htmlFor="currentCoverage" className="">A)Current Coverge: Yes or No</label>
//         </div>
//         <div className="col-5">
//           <div className="btn-group" role="group" aria-label="currentCoverage">
//             <button
//               type="button"
//               className={`btn ${
//                 currentCoverage === "Yes" ? "btn-success" : "btn-secondary"
//               } mr-2`}
//               {...register("currentCoverage")}
//               onClick={() => handleClick("Yes")}
//             >
//               Yes
//             </button>
//             <button
//               type="button"
//               className={`btn ${
//                 currentCoverage === "No" ? "btn-success" : "btn-secondary"
//               }`}
//               {...register("currentCoverage")}
//               onClick={() => handleClick("No")}
//             >
//               No
//             </button>
//           </div>
//         </div>
//       </div>

//       {currentCoverage === "Yes" && (
//         <div className="row my-2 pt-4">
//           <div className="col-7 pt-2">
//             <label className="typeOfCoverage">Type of Coverage:</label>
//           </div>
//           <div className="col-5">
//             <select
//               className="form-control border border-dark"
//               id="typeOfCoverage"
//               {...register("typeOfCoverage")}
//               value={typeOfCoverage}
//               onChange={handleTypeOfCoverage}
//             >
//               <option value="Whole Life">Whole Life</option>
//               <option value="IUL">IUL</option>
//               <option value="UL">UL</option>
//               <option value="Final expense">Final expense</option>
//             </select>
//             {errors.typeOfCoverage && (
//               <p className="text-danger">{errors.typeOfCoverage.message}</p>
//             )}
//           </div>
//         </div>
//       )}

//       {typeOfCoverage && (
//         <div className="row my-2 pt-4">
//           <div className="col-7 pt-2">
//             <label className="">How Long is the Coverage Guaranted"</label>
//           </div>
//           <div className="col-5">
//             <select
//               className="form-control border border-dark"
//               id="howLongCoverageGuranted"
//               {...register("howLongCoverageGuranted", {
//                 required: false,
//               })}
//             >
//               <option value="">Select Duration</option>
//               <option value="5">5</option>
//               <option value="10">10</option>
//               <option value="15">15</option>
//               <option value="20">20</option>
//               <option value="25">25</option>
//               <option value="30">30</option>
//               <option value="35">35</option>
//               <option value="40">40</option>
//             </select>

//           </div>
//         </div>
//       )}

//     </>
//   );
// };

// export default CoverageInformation;

import React, { useState } from "react";
import CompanyCoverageInfo from "./CompanyCoverageInfo";
import DateLead from "../DateLead";
import { Controller } from "react-hook-form";
import DatePicker from "react-multi-date-picker";

const CoverageInformation = ({
  errors,
  register,
  fieldShowName,
  setValue,
  control,
}) => {
  const [currentCoverage, setCurrentCoverage] = useState("");
  const [typeOfCoverage, setTypeOfCoverage] = useState("");
  const [howLongCoverage, setHowLongCoverage] = useState("");
  const [coverageAmount, setCoverageAmount] = useState("");

  const handleClick = (value) => {
    setValue("currentCoverage", value);
    setCurrentCoverage(value);
    if (value === "No") {
      setTypeOfCoverage("");
      setHowLongCoverage("");
      setValue("typeOfCoverage", "");
      setValue("howLongCoverageGuaranteed", "");
    }
  };

  const handleTypeOfCoverage = (e) => {
    setTypeOfCoverage(e.target.value);
    console.log(typeOfCoverage);
    setHowLongCoverage("");
  };

  const coverageAmounts = [
    5000, 10000, 15000, 20000, 25000, 30000, 40000, 50000, 60000, 70000, 80000,
    90000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 500000,
    600000, 700000, 800000, 900000, 1000000,
  ];

  const handleCoverageAmount = (e) => {
    setCoverageAmount(e.target.value);
    setValue("coverageAmount", e.target.value);
  };

  return (
    <>
      <div className="row my-2">
        <div className="col-7 pt-2">
          <label htmlFor="currentCoverage" className="">
            A) Current Coverage: Yes or No
          </label>
        </div>
        <div className="col-5">
          <div className="btn-group" role="group" aria-label="currentCoverage">
            <button
              type="button"
              className={`btn ${
                currentCoverage === "Yes" ? "btn-success" : "btn-secondary"
              } mr-2`}
              onClick={() => handleClick("Yes")}
            >
              Yes
            </button>
            <button
              type="button"
              className={`btn ${
                currentCoverage === "No" ? "btn-success" : "btn-secondary"
              }`}
              onClick={() => handleClick("No")}
            >
              No
            </button>
          </div>
        </div>
      </div>

      {currentCoverage === "Yes" && (
        <div className="row my-2 pt-4">
          <div className="col-7 pt-2">
            <label className="typeOfCoverage">Type of Coverage:</label>
          </div>
          <div className="col-5">
            <select
              className="form-control border border-dark"
              id="typeOfCoverage"
              {...register("typeOfCoverage")}
              value={typeOfCoverage}
              onChange={handleTypeOfCoverage}
            >
              <option value="">Select Type</option>
              <option value="Whole Life">Whole Life</option>
              <option value="IUL">IUL</option>
              <option value="UL">UL</option>
              <option value="Final expense">Final expense</option>
            </select>
            {errors.typeOfCoverage && (
              <p className="text-danger">{errors.typeOfCoverage.message}</p>
            )}
          </div>
        </div>
      )}

      {typeOfCoverage && (
        <div className="row my-2 pt-4">
          <div className="col-7 pt-2">
            <label className="">How Long is the Coverage Guaranteed:</label>
          </div>
          <div className="col-5">
            <select
              className="form-control border border-dark"
              id="howLongCoverageGuaranteed"
              {...register("howLongCoverageGuaranteed")}
              value={howLongCoverage}
              onChange={(e) => setHowLongCoverage(e.target.value)}
            >
              <option value="">Select Duration</option>
              <option value="5">5 Years</option>
              <option value="10">10 Years</option>
              <option value="15">15 Years</option>
              <option value="20">20 Years</option>
              <option value="25">25 Years</option>
              <option value="30">30 Years</option>
              <option value="35">35 Years</option>
              <option value="40">40 Years</option>
            </select>
          </div>
        </div>
      )}

      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">Coverage Amount</label>
        </div>
        <div className="col-5">
          <input
            list="coverageAmounts"
            className="form-control border border-dark"
            id="coverageAmount"
            {...register("coverageAmount")}
            value={coverageAmount}
            onChange={handleCoverageAmount}
          />
          <datalist id="coverageAmounts">
            {coverageAmounts.map((amount) => (
              <option key={amount} value={amount}>
                {amount}
              </option>
            ))}
          </datalist>
        </div>
      </div>

      <div className="row my-2">
        <div className="col-7 pt-2">
          <label>Effective Date</label>
        </div>
        <div className="col-5">
          <Controller
            control={control}
            name="effectiveDate"
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onChange={field.onChange}
                format="DD/MM/YYYY"
                className="form-control custom-date-picker"
                calendarPosition="bottom-center"
                selected={field.value ? new Date(field.value) : null}
                maxDate={new Date()}
              />
            )}
          />
        </div>
      </div>
      {currentCoverage &&
      typeOfCoverage &&
      howLongCoverage &&
      coverageAmount ? (
        <CompanyCoverageInfo
          errors={errors}
          fieldShowName={fieldShowName}
          register={register}
          setValue={setValue}
          control={control}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CoverageInformation;
