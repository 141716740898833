import { useState, useEffect } from 'react';
import useApis  from '../apis/useApis'; 
import appConstant from '../json/appConstant.json'; 
import { useSearchParams } from 'react-router-dom';

const useFetchUserDetails = (id) => {
  const [spouseId, setSpouseId] = useState(null);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { jsonApi } = useApis();

  const init = async () => {
    try {
      const details = await jsonApi(
        appConstant.getSingleUserDetail,
        { id },
        true,
        false
      );
      
      if (!details.error) {
        setSpouseId(details?.data?.data?.spouse?._id)
        setData(details?.data?.data);
      } else {
        setError(details.message || "No data received");
      }
    } catch (err) {
      setError(err.message || "An error occurred");
    }
  };

  useEffect(() => {
    init();
  }, [id]);

  return { data, init, spouseId };
};

export default useFetchUserDetails;
