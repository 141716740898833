import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sidebarItems from "../../json/sideBar.json"; // Import the JSON file
import employeeSideBar from "../../json/employeeSideBar.json"; // Import the JSON file
import keyExecutiveSideBar from "../../json/keyExecutiveSideBar.json"; // Import the JSON file
import managerSideBar from "../../json/managerSideBar.json"; // Import the JSON file
import ownerSideBar from "../../json/ownerSideBar.json"; // Import the JSON file
import companySideBar from "../../json/companySideBar.json"; // Import the JSON file
import {
  LogOut,
  Home,
  Layers,
  Headphones,
  Mail,
  Activity,
  FileText,
  Calendar,
  Edit2,
  BarChart,
  Settings,
  UserCheck,
  PhoneCall,
  Phone,
  Database,
  UserPlus,
  Users,
  UserMinus,
  BookOpen,
  Key,
  Voicemail,
} from "react-feather"; // Import necessary icons
import "./sidebar.css";
import useAuthApis from "../../apis/useAuthApis";
import { Context } from "../../context/Context";

const iconComponents = {
  LogOut: <LogOut className="feather-icon" />,
  BookOpen: <BookOpen className="feather-icon" />,
  Home: <Home className="feather-icon" />,
  Layers: <Layers className="feather-icon" />,
  Headphones: <Headphones className="feather-icon" />,
  Mail: <Mail className="feather-icon" />,
  // Voicemail: <Voicemail className="feather-icon" />,
  // Activity: <Activity className="feather-icon" />,
  // FileText: <FileText className="feather-icon" />,
  // Calendar: <Calendar className="feather-icon" />,
  // Edit2: <Edit2 className="feather-icon" />,
  BarChart: <BarChart className="feather-icon" />,
  Settings: <Settings className="feather-icon" />,
  UserCheck: <UserCheck className="feather-icon" />,
  PhoneCall: <PhoneCall className="feather-icon" />,
  Phone: <Phone className="feather-icon" />,
  Database: <Database className="feather-icon" />,
  UserPlus: <UserPlus className="feather-icon" />,
  Key: <Key className="feather-icon" />,
};

const SidebarItem = ({ item, isSelected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { logOutApi } = useAuthApis();
  const { setIsLoading} = useContext(Context);

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = async() => {
    if (item.title === "Logout") {
      setIsLoading(true)
      await logOutApi();
      setIsLoading(false);
    } else {
      onSelect(item); 
    }
  };

  const sidebarItemClass = isSelected
    ? "sidebar-item selected"
    : "sidebar-item";
  const sidebarLinkClass = isSelected ? "sidebar-link active" : "sidebar-link";

  return (
    <li className={sidebarItemClass}>
      {item.subTitle && item.subTitle.length > 0 ? (
        <Link
          className={`sidebar-link has-arrow ${sidebarLinkClass}`}
          onClick={toggleDropDown}
          to={item.slug}
          aria-expanded={isOpen ? "true" : "false"}
        >
          {iconComponents[item.logo]}
          <span className="hide-menu">{item.title}</span>
        </Link>
      ) : (
        <Link
          className={`sidebar-link ${sidebarLinkClass}`}
          onClick={handleClick}
          to={item.slug}
        >
          {iconComponents[item.logo]}
          <span className="hide-menu">{item.title}</span>
        </Link>
      )}

      {item.subTitle && item.subTitle.length > 0 && (
        <ul
          className={`collapse first-level base-level-line ${
            isOpen ? "show" : ""
          }`}
        >
          {item.subTitle.map((subItem, subIndex) => (
            <li className="sidebar-item" key={subIndex}>
              <Link to={subItem.slug} className="sidebar-link">
                <span className="hide-menu">{subItem.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      )}

      {item.divider && <li className="list-divider"></li>}
    </li>
  );
};

const SideBar = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelectedItem = (item) => {
    setSelectedItem(item);
  };
  const [sideBarState, setSideBarState] = useState([]);
  const { userData } = useContext(Context);
  useEffect(() => {
    switch (userData.type) {
      case "SuperAdmin":
        setSideBarState(sidebarItems);
        break;
      case "Employee":
        setSideBarState(employeeSideBar);
        break;
      case "Executive":
        setSideBarState(keyExecutiveSideBar);
        break;
      case "Owner":
        setSideBarState(ownerSideBar);
        break;
      case "Company":
        setSideBarState(companySideBar);
        break;
      case "Manager":
        setSideBarState(managerSideBar);
        break;
      default:
        setSideBarState([]);
        break;
    }
  }, []);

  return (
    <aside className="left-sidebar" data-sidebarbg="skin6">
      <div className="scroll-sidebar" data-sidebarbg="skin6">
        <nav className="sidebar-nav">
          <ul id="sidebarnav">
            {sideBarState?.main?.map((item, index) => (
              <SidebarItem
                key={index}
                item={item}
                isSelected={selectedItem === item}
                onSelect={handleSelectedItem}
              />
            ))}
            <li className="list-divider"></li>
            <li className="nav-small-cap">
              <span className="hide-menu">Applications</span>
            </li>
            {sideBarState?.applications?.map((item, index) => (
              <SidebarItem
                key={index}
                item={item}
                isSelected={selectedItem === item}
                onSelect={handleSelectedItem}
              />
            ))}
            <li className="list-divider"></li>
            <li className="nav-small-cap">
              <span className="hide-menu">User Account</span>
            </li>
            {sideBarState?.userAccounts?.map((item, index) => (
              <SidebarItem
                key={index}
                item={item}
                isSelected={selectedItem === item}
                onSelect={handleSelectedItem}
              />
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;
