import React, { useContext, useEffect, useState } from "react";
import "../dashboard/dashboard.css";
import { Phone, UserCheck, Award } from "react-feather";
import PaginationComponent from "../pagination/PaginationComponent";
import DatePicker from "react-datepicker";
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";
import moment from "moment";
import TotalCalls from "../dashboard/TotalCalls";
import CallTypeChart from "../dashboard/CallTypeChart";
import FormatNumber from "../utils/FormatNumber";
import { Context } from "../../context/Context";
import { useNavigate } from "react-router-dom";
import CallHistory from "../callHistory/CallHistory";
import DataTable from "../pagination/DataTable";
import commonHeader from "../../json/commonHeader.json";

const CallCentre = () => {
  document.title = "Call Center - Digital Call Center";
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const { jsonApi } = useApis();
  const [fetchData, setFetchData] = useState([]);
  const [fetchData1, setFetchData1] = useState([]);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const fetchCallHistory = async () => {
    const historyData = await jsonApi(
      appConstant.getCallHistory,
      {
        page,
        limit,
        search: search,
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      },
      null,
      true
    );
    if (!historyData.error && Array.isArray(historyData.data.data)) {
      setFetchData(historyData.data.data);
      setTotalData(historyData.data.total);
    } else {
      // setInitError(historyRes.message ? historyRes.message : "Something went wrong");
      // console.error(historyData.message);
    }
  };

  const fetchAllCallHistory = async () => {
    const historyData = await jsonApi(
      appConstant.getAllCallHistory,
      {
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      },
      null
    );
    if (!historyData.error && Array.isArray(historyData.data.data)) {
      setFetchData1(historyData.data.data);
    } else {
      // setInitError(historyRes.message ? historyRes.message : "Something went wrong");
      // console.error(historyData.message);
    }
  };

  useEffect(() => {
    const today = new Date();
    const lastWeekStart = new Date(today);
    lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
    const lastWeekEnd = new Date(today);
    lastWeekEnd.setDate(today.getDate() - today.getDay() - 1);

    setDateRange([lastWeekStart, lastWeekEnd]);
  }, []);

  useEffect(() => {
    fetchCallHistory();
    fetchAllCallHistory();
  }, [page, limit, search, dateRange]);

  const formatTimestamp = (timestamp, formatStr) => {
    if (!timestamp) return "N/A";
    const date = moment(timestamp);
    return date.format(formatStr);
  };

  const today = new Date();
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const [updateFlag, setUpdateFlag] = useState(false);
  const [lastSeen, setLastSeen] = useState(false);
  const { userData } = useContext(Context);

  const getAgentLastSeen = async () => {
    let params;
    //Getting data based on user if SuperAdmin then all data
    //and if Company id then there all employees
    if (userData.type === "SuperAdmin") {
      params = { duration: 60 };
    } else {
      params = {
        duration: 60,
        companyId: userData.company,
      };
    }
    try {
      const agentLastSeen = await jsonApi(
        appConstant.getAgentLastSeen,
        params,
        null,
        true
      );

      if (!agentLastSeen.error) {
        const filteredAgents = agentLastSeen.data.users.filter(
          (user) => user.type !== "SuperAdmin" && user.type !== "Company"
        );
        setLastSeen(filteredAgents);
      } else {
        console.error(agentLastSeen.message);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching agent last seen data:",
        error
      );
    }
  };

  useEffect(() => {
    getAgentLastSeen();
  }, [updateFlag]);

  const updateAgentLastSeen = async () => {
    const response = await jsonApi(
      appConstant.updateAgentLastSeen,
      null,
      null,
      true
    );
    if (!response.error) {
      setUpdateFlag((prev) => !prev);
    } else {
      console.error(response.message);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
        <div className="col-xl-11">

          <div className="col-12 d-flex justify-content-between align-items-center">
            <h3 className="page-title text-dark font-weight-medium mt-2" style={{marginLeft:"-15px"}}>
              Call Centre
            </h3>
            <div className="col-6 mt-3 d-flex justify-content-end mb-3" style={{marginRight:"-30px"}}>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                maxDate={maxDate}
                withPortal
                dateFormat="MMM d, yyyy"
                placeholderText="Select the date range....."
              />
            </div>
          </div>
          </div>

          <div className="col-xl-11">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row mb-4 text-dark">
                  <div className="table-responsive mt-2 mb-4 col-12">
                    <div
                      id="default_order_wrapper"
                      className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                    >
                      <DataTable
                        headers={commonHeader.callHistory}
                        fetchedData={fetchData}
                        totalData={totalData}
                        setPage={setPage}
                        limit={limit}
                        page={page}
                        setLimit={setLimit}
                        setSearch={setSearch}
                        init={fetchCallHistory}
                      />
                    </div>
                  </div>

                  <div className="col-12 mt-4 text-dark">
                    <div className="row">
                      <div className="col-6">
                        <h4 className="text-dark font-weight-medium">
                          Call Type
                        </h4>
                        <CallTypeChart fetchData1={fetchData1} />
                      </div>
                      <div className="col-6">
                        <h4 className="text-dark font-weight-medium">
                          Total Calls
                        </h4>
                        <TotalCalls fetchData1={fetchData1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              title="Ads"
              alt="Ads"
            />
          </div>
          {/* <CallHistory /> */}
        </div>
      </div>
    </>
  );
};

export default CallCentre;
