import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect, useState } from "react";
import useApis from "../../../apis/useApis";
import appConstant from "./../../../json/appConstant.json";

import {
  validateEmail,
  validateName,
  validateSuiteNumber,
  validateZipCode,
} from "./../../validation/Validation";
import { PhoneInput } from "react-international-phone";
import DatePicker from "react-multi-date-picker";
import convertToRoman from "../../utils/convertToRoman";

const ViewForm = ({
  data,
  closeModal,
  formName,
  fromSkeleton,
  show,
  demo,
  customForm,
}) => {
  const { jsonApi } = useApis();
  const [heading, setHeading] = useState([]);
  const [leadCheck, setLeadCheck] = useState("prospects");
  console.log(data);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      leadType: "prospects",
    },
  });
  useEffect(() => {
    // Reset the form when the state changes
    reset();
  }, [show, reset]);
  useEffect(() => {
    const headings = fromSkeleton.filter(
      (element) => element.type === "Heading"
    );
    setHeading(headings);
  }, [fromSkeleton]);

  console.log(errors);
  const onSubmit = async (dd) => {
    if (demo) {
      console.log(dd);
      closeModal();
    } else {
      const fetchData = await jsonApi(
        appConstant.updateCustomLead,
        null,
        {
          customForm: customForm._id,
          data: { _id: data._id, dd },
        },
        null,
        null
      );
      closeModal();
      if (window.opener) {
        window.close();
      }
    }
  };

  const populateForm = (data) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(`${key}`, data[key]);
      });
    }
  };

  useEffect(() => {
    populateForm(data);
  }, []);

  return (
    <>
      <div className="w-100 my-5 ">
        <div className="row">
          <form className="col" onSubmit={handleSubmit(onSubmit)}>
            <div className="card ">
              <div className="row">
                <div className="col-11"></div>

                <div className="col-1 mt-2">
                  <div className="form-group row">
                    <button type="submit" className="next-step btn_green mx-2">
                      Save
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-body custom-field-button">
                <div className="row mt-4">
                  <div className="col-12"></div>
                  <div
                    className="col-4 sticky-col"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="row text-dark font-weight-medium"
                      style={{ position: "fixed" }}
                    >
                      <div>
                        <div className="mb-4">
                          <a
                            href={`#$"quickQuote"`}
                            className={
                              window.location.hash === `#${"quickQuote"}`
                                ? "quoteActive"
                                : ""
                            }
                          >
                            <h3>{`${convertToRoman(1)}. ${"Quick Quote"}`}</h3>
                          </a>
                        </div>
                        {heading.map((item, index) => (
                          <div className="mb-4">
                            <a
                              href={`#${item.name}`}
                              className={
                                window.location.hash === `#${item.name}`
                                  ? "quoteActive"
                                  : ""
                              }
                            >
                              <h3>{`${convertToRoman(index + 2)}. ${
                                item.heading
                              }`}</h3>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-8">
                    {/* <div className="row mb-2">
                      <div className="col-12 d-flex justify-content-center">
                        <div className="form-check form-check-inline">
                          <label
                            className="form-check-label mx-4"
                            htmlFor="leadCheck"
                          >
                            Select Lead Type
                          </label>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="leadCheck"
                            id="inlineRadio1"
                            value="prospects"
                            {...register("leadCheck", {
                              required: "This field is required",
                            })}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Prospects
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="leadCheck"
                            id="inlineRadio2"
                            value="client"
                            {...register("leadCheck", {
                              required: "This field is required",
                            })}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Client
                          </label>
                        </div>
                      </div>
                      {errors.leadCheck && (
                        <p className="text-danger">
                          {errors.leadCheck.message}
                        </p>
                      )}
                    </div> */}
                    <div className="row">
                      <div className="col-12">
                        <h3 className="page-title text-dark font-weight-medium mb-4">
                          {`${convertToRoman(1)}. ${"Quick Quote"}`}
                        </h3>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="firstName">First Name</label>
                          <input
                            type="text"
                            maxLength={256}
                            className="form-control black-border"
                            {...register("fullName", {
                              required: false,
                              validate: (value) =>
                                value ? validateName(value, "fullName") : null,
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="firstName">Last Name</label>
                          <input
                            type="text"
                            maxLength={256}
                            className="form-control black-border"
                            {...register("fullName", {
                              required: false,
                              validate: (value) =>
                                value ? validateName(value, "fullName") : null,
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="firstName">DOB</label>
                          <input
                            type="text"
                            maxLength={256}
                            className="form-control black-border"
                            {...register("fullName", {
                              required: false,
                              validate: (value) =>
                                value ? validateName(value, "fullName") : null,
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="firstName">Zip Code</label>
                          <input
                            type="text"
                            maxLength={256}
                            className="form-control black-border"
                            {...register("fullName", {
                              required: false,
                              validate: (value) =>
                                value ? validateName(value, "fullName") : null,
                            })}
                          />
                        </div>
                      </div>

                      {fromSkeleton.map((item, index) =>
                        item.type === "Heading" ? (
                          <div key={item.index} className="col-12">
                            <h3
                              className="page-title text-dark font-weight-medium mb-4"
                              id={item?.name}
                            >
                              {`${convertToRoman(
                                heading.findIndex(
                                  (element) => element.name === item.name
                                ) + 2
                              )}. ${item?.heading}`}
                            </h3>
                          </div>
                        ) : item.type === "SubHeading" ? (
                          <div key={item.index} className="col-12">
                            <h4 className="page-title text-dark font-weight-medium mb-4">
                              {`${item?.heading}`}
                            </h4>
                          </div>
                        ) : item.type === "Text" ? (
                          <div key={item.index} className="col-6">
                            <div className="form-group">
                              <label htmlFor={`${item.name}`}>
                                {item.label}
                              </label>
                              <input
                                type="text"
                                maxLength={256}
                                className="form-control black-border"
                                {...register(`${item.name}`, {
                                  required: item?.required,
                                  validate: (value) =>
                                    validateName(value, `${item.label}`),
                                })}
                              />
                              {errors[item.name] && (
                                <p className="text-danger">
                                  {errors[item.name].message}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : item.type === "Email" ? (
                          <div key={item.index} className="col-6">
                            <div className="form-group">
                              <label htmlFor={`${item.name}`}>
                                {item.label}
                              </label>
                              <input
                                type="email"
                                maxLength={256}
                                className="form-control black-border"
                                {...register(`${item.name}`, {
                                  required: item?.required,
                                  validate: (value) =>
                                    validateEmail(value, `${item.label}`),
                                })}
                              />
                              {errors[item.name] && (
                                <p className="text-danger">
                                  {errors[item.name].message}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : item.type === "Phone" ? (
                          <div key={item.index} className="col-6">
                            <div className="form-group">
                              <label htmlFor={`${item.name}`}>
                                {item.label}
                              </label>
                              <Controller
                                name={`${item.name}`}
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <PhoneInput
                                      preferredCountries={"us"}
                                      containerClass="phone-input-container"
                                      value={field.value}
                                      onChange={(phone) =>
                                        field.onChange(phone)
                                      }
                                      onBlur={field.onBlur}
                                      inputProps={{ required: item?.required }}
                                      disableDialCodeAndPrefix={true}
                                      disableFocusAfterCountrySelect={false}
                                      hideDropdown={true}
                                      disableCountryGuess={true}
                                      className="black-border"
                                    />
                                    {errors[item.name] && (
                                      <p className="text-danger">
                                        {errors[item.name].message}
                                      </p>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        ) : item.type === "Number" ? (
                          <div key={item.index} className="col-6">
                            <div className="form-group">
                              <label htmlFor={`${item.name}`}>
                                {item.label}
                              </label>
                              <input
                                type="number"
                                maxLength={256}
                                className="form-control black-border"
                                {...register(`${item.name}`, {
                                  required: item?.required,
                                })}
                              />
                              {errors[item.name] && (
                                <p className="text-danger">
                                  {errors[item.name].message}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : item.type === "Date" ? (
                          <div key={item.index} className="col-6">
                            <div className="form-group">
                              <label htmlFor={`${item.name}`}>
                                {item.label}
                              </label>
                              <input
                                type="date"
                                maxLength={256}
                                className="form-control black-border"
                                {...register(`${item.name}`, {
                                  required: item?.required,
                                })}
                              />
                              {errors[item.name] && (
                                <p className="text-danger">
                                  {errors[item.name].message}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : item.type === "TextArea" ? (
                          <div key={item.index} className="col-6">
                            <div className="form-group">
                              <label htmlFor={`${item.name}`}>
                                {item.label}
                              </label>
                              <textarea
                                type="text"
                                maxLength={256}
                                className="form-control black-border"
                                {...register(`${item.name}`, {
                                  required: item?.required,
                                  validate: (value) =>
                                    validateName(value, `${item.label}`),
                                })}
                              />
                              {errors[item.name] && (
                                <p className="text-danger">
                                  {errors[item.name].message}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : item.type === "Select" ? (
                          <div key={item.index} className="col-6">
                            <div className="form-group">
                              <label htmlFor={`${item.name}`}>
                                {item.label}
                              </label>
                              <select
                                className="form-control black-border"
                                {...register(`${item.name}`, {
                                  required: item?.required,
                                })}
                              >
                                {item.option.map((optionItem) => (
                                  <option value={optionItem.value}>
                                    {optionItem.label}
                                  </option>
                                ))}
                              </select>
                              {errors[item.name] && (
                                <p className="text-danger">
                                  {errors[item.name].message}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : item.type === "Radio" ? (
                          <div key={item.index} className="col-6">
                            <div className="form-group">
                              <label htmlFor={`${item.name}`}>
                                {item.label}
                              </label>
                              <div>
                                {item.option.map((optionItem) => (
                                  <div
                                    key={optionItem.value}
                                    className="form-check"
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id={`${item.name}-${optionItem.value}`}
                                      value={optionItem.value}
                                      {...register(`${item.name}`, {
                                        required: item?.required,
                                      })}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`${item.name}-${optionItem.value}`}
                                    >
                                      {optionItem.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                              {errors[item.name] && (
                                <p className="text-danger">
                                  {errors[item.name].message}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <div className="form-group row">
                          <button
                            type="submit"
                            className="next-step btn_green mx-2"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* multi-column ordering */}
          </form>
        </div>
      </div>
    </>
  );
};

export default ViewForm;
