import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import "../DatePickerStyle.css";

const MedicalConditionDetail = ({ control, register, errors, item }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "medicalConditionDetails",
  });

  return (
    <div>
      <h5 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
      Please describe Your Medical Condition Below
      </h5>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Date Condition Started</th>
            <th>Type Of Condition</th>
            <th>Medications You Are Taking As A Result</th>
            <th>Explanation of Condition</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((medical, index) => (
            <tr key={medical.id}>
                <td>
                <Controller
                  control={control}
                  name={`medicalConditionDetails[${index}].dateStarted`}
                  defaultValue={medical.dateStarted || ""}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value}
                      onChange={field.onChange}
                      format="DD/MM/YYYY"
                      className="form-control custom-date-picker"
                      calendarPosition="bottom-center"
                      selected={field.value ? new Date(field.value) : null}
                    />
                  )}
                />
              </td>
              <td>
                <Controller
                  control={control}
                  name={`medicalConditionDetails[${index}].conditionType`}
                  defaultValue={medical.conditionType || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>

              <td>
                <Controller
                  control={control}
                  name={`medicalConditionDetails[${index}].medications`}
                  defaultValue={medical.medications || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>

              <td>
                <Controller
                  control={control}
                  name={`medicalConditionDetails[${index}].explanationOfCondition`}
                  defaultValue={medical.explanationOfCondition || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  ×
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex">
        <button
          type="button"
          className="btn btn-success ml-auto"
          onClick={() =>
            append({
                dateStarted: "",
                conditionType: "",
                medications: "",
                explanationOfCondition: "",
            })
          }
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default MedicalConditionDetail;
