import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const StaticCallTypeChart = () => {
  const options = {
    chart: {
      type: "spline",
    },
    title: {
      text: "Call Types",
    },
    xAxis: {
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], 
      tickInterval: 1, 
    },
    yAxis: {
      title: {
        text: null, 
      },
      tickPositions: [0, 50, 100, 150, 200, 250, 300],
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function () {
        return `Total Calls: 0`; // Static message for tooltip
      },
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
        },
      },
    },
    series: [
      {
        name: 'Outbounds Calls',
        marker: {
          symbol: 'diamond',
        },
        data: [], // Empty data array for static chart
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y; 
          },
          style: {
            fontWeight: 'bold',
            color: 'black',
            textOutline: '0px', 
          },
        },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
      
    </div>
  );
};

export default StaticCallTypeChart;