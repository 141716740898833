import React, { useEffect, useState } from "react";
import { Star } from "react-feather";
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";

const FavButton = ({ item, init }) => {
  const [isFavourited, setIsFavourited] = useState(item?.isFavourited || false);
  const { jsonApi } = useApis();

  const handleClick = async () => {
    const updatedStatus = !isFavourited;

    const response = await jsonApi(
      appConstant.updatePersonalAndBusinessFavourite,
      { id: item._id },
      { isFavourited: updatedStatus },
      null
    );
    if (!response.error) {
      setIsFavourited(updatedStatus);
      init();
    } else {
      console.error(response.message || "Failed to update favorite status");
    }
  };

  useEffect(() => {
    setIsFavourited(item?.isFavourited);
  }, [item?.isFavourited]);

  return (
    <button
      style={{
        background: "none", // No background for the button
        border: "none", // No border for the button
        outline: "none", // No outline for the button
        cursor: "pointer",
        padding: "0",
        margin: "0",
        alignItems: "center", // Align items vertically centered
      }}
      aria-label="Add to favorites"
      onClick={handleClick}
    >
      <Star
        size={16}
        style={{
          fill: isFavourited === true ? "#ECC213" : "none", // Fill color for the star
          stroke: "black", // Border color of the star
          strokeWidth: 2, // Border width of the star
          strokeLinecap: "round", // Optional: smooth border ends
          strokeLinejoin: "round", // Optional: smooth border corners
        }}
      />
    </button>
  );
};

export default FavButton;
