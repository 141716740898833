import React from "react";

const ViewFelidComponent = ({ value, index, removeHandler }) => {
  return value.type === "Heading" ? (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{value?.type}</td>
      <td colspan="5">
        <h2>{value?.heading}</h2>
      </td>
      <td>
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </td>
    </tr>
  ) : value.type === "SubHeading" ? (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{value?.type}</td>
      <td colspan="5">
        <h3>{value?.heading}</h3>
      </td>

      <td>
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </td>
    </tr>
  ) : value.type === "Text" ? (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{value?.type}</td>
      <td>{value?.label}</td>
      <td>{value?.name}</td>
      <td>{value?.placeholder}</td>
      <td>{value.required ? "True" : "False"}</td>
      <td></td>
      <td>
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </td>
    </tr>
  ) : value.type === "Email" ? (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{value?.type}</td>
      <td>{value?.label}</td>
      <td>{value?.name}</td>
      <td>{value?.placeholder}</td>
      <td>{value.required ? "True" : "False"}</td>
      <td></td>

      <td>
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </td>
    </tr>
  ) : value.type === "Phone" ? (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{value?.type}</td>
      <td>{value?.label}</td>
      <td>{value?.name}</td>
      <td>{value?.placeholder}</td>
      <td>{value.required ? "True" : "False"}</td>
      <td></td>

      <td>
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </td>
    </tr>
  ) : value.type === "Number" ? (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{value?.type}</td>
      <td>{value?.label}</td>
      <td>{value?.name}</td>
      <td>{value?.placeholder}</td>
      <td>{value.required ? "True" : "False"}</td>
      <td></td>

      <td>
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </td>
    </tr>
  ) : value.type === "Date" ? (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{value?.type}</td>
      <td>{value?.label}</td>
      <td>{value?.name}</td>
      <td>{value?.placeholder}</td>
      <td>{value.required ? "True" : "False"}</td>
      <td></td>

      <td>
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </td>
    </tr>
  ) : value.type === "TextArea" ? (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{value?.type}</td>
      <td>{value?.label}</td>
      <td>{value?.name}</td>
      <td>{value?.placeholder}</td>
      <td>{value.required ? "True" : "False"}</td>
      <td></td>

      <td>
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </td>
    </tr>
  ) : value.type === "Select" ? (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{value?.type}</td>
      <td>{value?.label}</td>
      <td>{value?.name}</td>
      <td>{value?.placeholder}</td>
      <td>{value.required ? "True" : "False"}</td>
      <td>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Value</th>
              <th scope="col">Label</th>
            </tr>
          </thead>
          <tbody>
            {value.option.map((item, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{item.value}</td>
                <td>{item.label}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>

      <td>
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </td>
    </tr>
  ) : value.type === "Radio" ? (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{value?.type}</td>
      <td>{value?.label}</td>
      <td>{value?.name}</td>
      <td>{value?.placeholder}</td>
      <td>{value.required ? "True" : "False"}</td>
      <td>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Value</th>
              <th scope="col">Label</th>
            </tr>
          </thead>
          <tbody>
            {value.option.map((item, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{item.value}</td>
                <td>{item.label}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
      <td>
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </td>
    </tr>
  ) : (
    <></>
  );
};

export default ViewFelidComponent;
