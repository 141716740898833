import React, { useEffect, useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { useParams, useNavigate } from "react-router-dom";
import { validateName, validateDescription } from "../validation/Validation";

const EditNotes = () => {
  document.title = "Notes - Digital Call Center";
  const { id } = useParams();
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [data, setData] = useState("");
  const navigate = useNavigate();

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const fetchNote = useCallback(async () => {
    const response = await jsonApi(appConstant.getNoteById, { id });
    console.log(response);
    if (response.error) {
      setError(response.error.message || "An error occurred");
    } else {
      reset({
        noteName: response.data.noteName,
        noteDescription: response.data.noteDescription,
      });
      setError("");
      setData(response);
    }
  }, [id, reset]);

  useEffect(() => {
    fetchNote();
  }, [fetchNote]);

  const onSubmit = async (value) => {
    const contactId = data?.data?.contact;
    const response = await jsonApi(
      appConstant.editNote,
      { id },
      value,
      false,
      true
    );
    if (!response.error) {
      setError("");
      fetchNote();
      reset();
      setTimeout(() => {
        navigate(`/contact/list/notes/${contactId}`);
      }, 1000);
    } else {
      setError(response.error.message || "An error occurred");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <h3 className="page-title text-dark font-weight-medium mt-2 ml-2">
                Edit New Note
              </h3>

              <div className="col-6 d-flex justify-content-end ">
                <button
                  data-toggle="modal"
                  data-target="#personalContact"
                  className="page-link me-2 border border-primary"
                  onClick={() => navigate(`/contact/list/notes/${id}`)}
                >
                  Show List Of Notes
                </button>
              </div>
            </div>
          </div>

          <div className="col-xl-11">
            <div className="card">
              <div className="card-body custom-field-button">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}

                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Note Name
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        id="noteName"
                        name="noteName"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            placeholder="Note Name"
                            maxLength={256}
                            {...field}
                            className={`form-control black-border${
                              errors.noteName ? "is-invalid" : ""
                            }`}
                            {...register("noteName", {
                              required: "Note Name is required",
                              validate: (value) =>
                                validateName(value, "note name"),
                            })}
                          />
                        )}
                      />
                      {errors.noteName && (
                        <p className="text-danger">{errors.noteName.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Note Description
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        id="noteDescription"
                        name="noteDescription"
                        control={control}
                        render={({ field }) => (
                          <>
                            <textarea
                              rows="4"
                              maxLength={1024}
                              placeholder="Note Description"
                              className={`form-control black-border${
                                errors.noteDescription ? "is-invalid" : ""
                              }`}
                              {...register("noteDescription", {
                                required: "Note Description is required",
                                validate: (value) =>
                                  validateDescription(value) ||
                                  "Invalid description",
                              })}
                            />
                            <div className="text-muted mt-1">
                              {field.value ? field.value.length : 0} / 1024
                            </div>
                          </>
                        )}
                      />
                      {errors.noteDescription && (
                        <p className="text-danger">
                          {errors.noteDescription.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-lg-2 col-md-3 col-sm-4">&nbsp;</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-rounded px-4"
                      >
                        Update Note
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
              alt="Ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNotes;
