import React, { useContext, useEffect, useState } from "react";
import commonHeader from "../../json/commonHeader.json";
import appConstant from "../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { CallCenterContext } from "../../context/CallCenterContext";
import ModelUi from "../model/ModelUi";
import { useSearchParams } from "react-router-dom";
import DataTable from "../pagination/DataTable";

const ClientLead = () => {
  document.title = "Client - Digital Call Center";
  const [searchParams] = useSearchParams();
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [clientLeadDetails, setClientLeadDetails] = useState([]);
  const { makeCall } = useContext(CallCenterContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [sortField, setSortField] = useState("fullName");
  const [sortOrder, setSortOrder] = useState("asc");

  const [openModelForCall, setOpenModelForCall] = useState({
    model: false,
    array: [],
  });

  const setOpenModelForCallHandler = (array) => {
    setOpenModelForCall({ model: true, array });
  };
  const makeCallModelHandleClose = () => {
    setOpenModelForCall({ model: false, array: [] });
  };

  const fetchClientLeadData = async () => {
    const hasSearchQuery = searchParams.get("query");
    setClientLeadDetails([]);
    setTotalData(0);

    try {
      const clientLeadData = await jsonApi(
        // appConstant.getClientLead,
        hasSearchQuery ? appConstant.getContactData : appConstant.getClientLead,
        {
          page,
          limit,
          // search: searchParams.get("query") ? searchParams.get("query") : "",
          search: hasSearchQuery || "",
          sortField,
          sortOrder,
        },
        null,
        false
      );

      if (!clientLeadData.error) {
        setClientLeadDetails(clientLeadData.data.data || []);
        setTotalData(clientLeadData.data.total || 0);
      } else {
        setError(
          clientLeadData.message ? clientLeadData.message : "No data received"
        );
      }
    } catch (error) {
      setError("An error occurred while fetching data");
    }
  };

  useEffect(() => {
    fetchClientLeadData();
  }, [page, limit, sortField, sortOrder, searchParams.get("query")]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <>
              {error ? (
                <div className="alert alert-danger">{error}</div>
              ) : (
                <div className="card-body custom-field-button">
                  <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                    Client Lead data
                  </h4>

                  <DataTable
                    headers={commonHeader.clientLead}
                    fetchedData={clientLeadDetails}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    totalData={totalData}
                    setLimit={setLimit}
                    init={fetchClientLeadData}
                    setSortField={setSortField}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                    isFav={true}
                  />
                </div>
              )}
            </>
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto d-block img-fluid"
            title="Ads"
            alt="ads"
          />
        </div>
      </div>
      <ModelUi
        show={openModelForCall.model}
        handleClose={makeCallModelHandleClose}
        title="Select number to call"
      >
        <table style={{ width: "100%" }}>
          <thead>
            <th style={{ textAlign: "justify" }}>Number</th>
            <th style={{ textAlign: "justify" }}>Call</th>
          </thead>
          <tbody>
            {openModelForCall.array.map((item) => (
              <tr style={{ textAlign: "justify" }}>
                <td style={{ textAlign: "justify" }}>
                  <p>{item.value}</p>
                </td>
                <td style={{ textAlign: "justify", cursor: "pointer" }}>
                  <span
                    className="right_icon"
                    onClick={() => {
                      makeCall(item.value);
                      makeCallModelHandleClose();
                    }}
                  >
                    <i className="material-symbols-outlined">call</i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModelUi>
    </div>
  );
};

export default ClientLead;
