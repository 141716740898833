import React from "react";
import { Search } from "react-feather";

const SearchForm = ({ query, setQuery, handleEmptySearch }) => {
  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="db-search d-none d-lg-inline-block">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="row customize-input">
          <div>
            <input
              className="form-control custom-shadow custom-radius-left bg-light border border-dark border-1 "
              type="search"
              placeholder="Database Search"
              aria-label="Search"
              maxLength={256}
              value={query}
              onChange={handleSearchChange}
            />
          </div>
          <div className="input-prepend">
            <select className="form-control custom-shadow custom-radius-right bg-light border border-dark border-1">
              <option selected="">Search Criteria</option>
              <option>All</option>
              <option>Last Name</option>
              <option>First Name</option>
              <option>Business Name</option>
              <option>Phone Number</option>
              <option>Email</option>
              <option>Business Type</option>
            </select>
            <i
              className="form-control-icon-arrow"
              data-feather="chevron-down"
            />
          </div>

          <div className="form-control-icon">
            <Search />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
