import React, { useContext, useEffect, useState } from "react";
import commonHeader from "../../json/commonHeader.json";
import appConstant from "../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { CallCenterContext } from "../../context/CallCenterContext";
import ModelUi from "../model/ModelUi";

import { useSearchParams } from "react-router-dom";
import DataTable from "../pagination/DataTable";

const CampaignsList = () => {
  document.title="Campaign List - Digital Call Center";
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [campaignsListDetails, setCampaignsListDetails] = useState([]);
  const [search, setSearch] = useState("");
  const { makeCall } = useContext(CallCenterContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [sortField, setSortField] = useState("fullName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchParams, setSearchParams] = useSearchParams();

  const [openModelForCall, setOpenModelForCall] = useState({
    model: false,
    array: [],
  });

  const setOpenModelForCallHandler = (array) => {
    setOpenModelForCall({ model: true, array });
  };
  const makeCallModelHandleClose = () => {
    setOpenModelForCall({ model: false, array: [] });
  };


  const fetchCampaignsListData = async () => {
    const campaignsListData = await jsonApi(
      appConstant.getCampaignsList,
      {  },
      null,
      null
    );
    if (!campaignsListData.error) {
      setCampaignsListDetails(campaignsListData?.data?.data);
      console.warn(campaignsListDetails)
      setTotalData(campaignsListData.data.total);
    } else {
      setError(
        campaignsListData.message
          ? campaignsListData.message
          : "No data received"
      );
    }
  };

  useEffect(() => {
    fetchCampaignsListData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <>
              {error ? (
                <div className="alert alert-danger">{error}</div>
              ) : (
                <div className="card-body custom-field-button">
                  <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                    Campaigns List data
                  </h4>
                   <DataTable
                    headers={commonHeader.campaignsList}
                    fetchedData={campaignsListDetails}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    totalData={totalData}
                    setLimit={setLimit}
                    setSearch={setSearch}
                    init={fetchCampaignsListData}
                    setSortField={setSortField}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                  />
                </div>
              )}
            </>
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto d-block img-fluid"
            title="Ads"
            alt="ads"
          />
        </div>
      </div>
      <ModelUi
        show={openModelForCall.model}
        handleClose={makeCallModelHandleClose}
        title="Select number to call"
      >
        <table style={{ width: "100%" }}>
          <thead>
            <th style={{ textAlign: "justify" }}>Number</th>
            <th style={{ textAlign: "justify" }}>Call</th>
          </thead>
          <tbody>
            {openModelForCall.array.map((item) => (
              <tr style={{ textAlign: "justify" }}>
                <td style={{ textAlign: "justify" }}>
                  <p>{item.value}</p>
                </td>
                <td style={{ textAlign: "justify", cursor: "pointer" }}>
                  <span
                    className="right_icon"
                    onClick={() => {
                      makeCall(item.value);
                      makeCallModelHandleClose();
                    }}
                  >
                    <i className="material-symbols-outlined">call</i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModelUi>
    </div>
  );
};

export default CampaignsList;
