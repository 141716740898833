import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { useNavigate } from "react-router-dom";
import useAuthApis from "../../apis/useAuthApis";
import { Context } from "../../context/Context";
import { Eye, EyeOff } from "react-feather";

const ProfilePasswordUpdate = () => {
  document.title = "Password Update - Digital Call Center";
  const { jsonApi } = useApis();
  const { homeApi } = useAuthApis();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { userData } = useContext(Context);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  // Regular expression for strong password
  const strongPasswordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const toggleOldPasswordVisibility = () =>
    setShowOldPassword(!showOldPassword);
  const toggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = async (data) => {
    if (data.oldPassword === data.newPassword) {
      setError("You can't use old password");
      return;
    }
    const response = await jsonApi(
      appConstant.updatePassword,
      null,
      data,
      false,
      true
    );
    if (!response.error) {
      reset({
        oldPassword: "",
        newPassword: "",
        passwordConfirm: "",
      });
      setError("");
      await delay(3000);
      if (!userData.defaultPasswordReset) {
        await homeApi();
        navigate("/");
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <h3 className="page-title text-dark font-weight-medium mt-2 ml-3">
              Update Password
            </h3>
            <div className="card">
              <div className="card-body custom-field-button">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}

                  {/* <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Old Password
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="oldPassword"
                        control={control}
                        render={({ field }) => (
                          <div className="input-group mb-3">
                            <input
                              type={showOldPassword ? "text" : "password"}
                              placeholder="Old Password"
                              className={`form-control ${
                                errors.oldPassword ? "is-invalid" : ""
                              }`}
                              {...register("oldPassword", {
                                required: "Old Password is required",
                              })}
                            />
                            <span
                              onClick={toggleOldPasswordVisibility}
                              style={{ cursor: "pointer" }}
                              className="input-group-text"
                            >
                              {showOldPassword ? (
                                <Eye className="feather-icon" />
                              ) : (
                                <EyeOff className="feather-icon" />
                              )}
                            </span>
                          </div>
                        )}
                      />
                      {errors.oldPassword && (
                        <p className="text-danger">
                          {errors.oldPassword.message}
                        </p>
                      )}
                    </div>
                  </div>
                   */}
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      New Password
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="newPassword"
                        control={control}
                        render={({ field }) => (
                          <div className="input-group mb-3">
                            <input
                              type={showNewPassword ? "text" : "password"}
                              placeholder="New Password"
                              className={`form-control ${
                                errors.newPassword ? "is-invalid" : ""
                              }`}
                              {...register("newPassword", {
                                required: "New Password is required",
                                pattern: {
                                  value: strongPasswordRegex,
                                  message:
                                    "Please choose a stronger password. Try a mix of letters, numbers and symbols",
                                },
                              })}
                            />
                            <span
                              onClick={toggleNewPasswordVisibility}
                              style={{ cursor: "pointer" }}
                              className="input-group-text"
                            >
                              {showNewPassword ? (
                                <Eye className="feather-icon" />
                              ) : (
                                <EyeOff className="feather-icon" />
                              )}
                            </span>
                          </div>
                        )}
                      />
                      {errors.newPassword && (
                        <p className="text-danger">
                          {errors.newPassword.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Confirm Password
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="passwordConfirm"
                        control={control}
                        render={({ field }) => (
                          <div className="input-group mb-3">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="Confirm Password"
                              className={`form-control ${
                                errors.passwordConfirm ? "is-invalid" : ""
                              }`}
                              {...register("passwordConfirm", {
                                required: "Confirm Password is required",
                                validate: (value) =>
                                  value === getValues("newPassword") ||
                                  "Passwords do not match",
                              })}
                            />
                            <span
                              onClick={toggleConfirmPasswordVisibility}
                              style={{ cursor: "pointer" }}
                              className="input-group-text"
                            >
                              {showConfirmPassword ? (
                                <Eye className="feather-icon" />
                              ) : (
                                <EyeOff className="feather-icon" />
                              )}
                            </span>
                          </div>
                        )}
                      />
                      {errors.passwordConfirm && (
                        <p className="text-danger">
                          {errors.passwordConfirm.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-2 col-md-3 col-sm-4">&nbsp;</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-rounded px-4"
                      >
                        Update Password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
              alt="Ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePasswordUpdate;
