import React from "react";

const YearsField = ({ errors, fieldName, fieldShowName, register,yrsBox }) => {
  return (
    <div className="row my-2">
    <div className="col-7 pt-2">
      <label htmlFor={fieldName} className="">
        {fieldShowName}
      </label>
    </div>
    <div className="col-5 d-flex align-items-center">
      <input
        type="text"
        maxLength={256}
        // required
        className={`form-control black-border ${
          errors[fieldName] ? "border border-danger" : ""
        }`}
        max={new Date().toISOString().split("T")[0]}
        id={fieldName}
        {...register(fieldName)}
      />
      {errors[fieldName] && (
        <p className="text-danger">{errors[fieldName].message}</p>
      )}
      {yrsBox && (      <div className="input-group-text">yrs.</div>
)}
    </div>

  </div>
  );
};

export default YearsField;



