import React, { useState } from "react";

const DateFelid = ({ addFelidHandler }) => {
  const [inputValue, setInputValue] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    addFelidHandler({ ...inputValue, type: "Date" });
    setInputValue(""); // Clear the input after submission
  };
  const onChange = (e) => {
    if (e.target.name === "name") {
      let value = e.target.value.replace(/\s+/g, "_");
      const isValidKey = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/.test(value);
      if (isValidKey || value.length === 0) {
        setInputValue((pre) => {
          return { ...pre, [e.target.name]: value };
        });
      }
    } else if (e.target.name === "required") {
      setInputValue((pre) => {
        return { ...pre, [e.target.name]: e.target.checked };
      });
    } else {
      setInputValue((pre) => {
        return { ...pre, [e.target.name]: e.target.value };
      });
    }
  };
  // console.log(inputValue);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div class="container">
          <div class="row mb-3 justify-content-center">
            <div class="col-12 text-center">
              <h2>Type: Date</h2>
            </div>
          </div>

          <div class="row mb-3 justify-content-center">
            <div class="col-12">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  name="name"
                  required
                  onChange={onChange}
                  value={inputValue.name}
                />
              </div>
            </div>
          </div>

          <div class="row mb-3 justify-content-center">
            <div class="col-12">
              <div class="form-group">
                <label for="label">Label</label>
                <input
                  type="text"
                  class="form-control"
                  id="label"
                  name="label"
                  required
                  onChange={onChange}
                  value={inputValue.label}
                />
              </div>
            </div>
          </div>

          <div class="row mb-3 justify-content-center">
            <div class="col-12">
              <div class="form-group">
                <label for="defaultValue">Default Value</label>
                <input
                  type="date"
                  class="form-control"
                  id="defaultValue"
                  name="defaultValue"
                  onChange={onChange}
                  value={inputValue.defaultValue}
                />
              </div>
            </div>
          </div>

          <div class="row mb-3 justify-content-center">
            <div class="col-12">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="required"
                  name="required"
                  onChange={onChange}
                  checked={inputValue.required}
                />
                <label class="form-check-label" for="required">
                  Required
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 align-self-center">
          <div className="w-100 d-flex justify-content-center align-item-center">
            <button type="submit" className="btn btn-primary">
              Add Felid
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DateFelid;
