import React from "react";
import InputMask from "react-input-mask";

const MBIField = ({ errors, fieldName, fieldShowName, register }) => {
  return (
    <div className="row my-2">
      <div className="col-7 pt-2">
        <label htmlFor={fieldName} className="">
          {fieldShowName}
        </label>
      </div>
      <div className="col-5">
        <InputMask
          mask="a9a-a9-a9a9a"
          placeholder="1EG4-TE5-MK73"
          id={fieldName}
          name={fieldName}
          {...register(fieldName)}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              type="text"
              className={`form-control black-border ${
                errors[fieldName] ? "border border-danger" : ""
              }`}
            />
          )}
        </InputMask>

        {errors[fieldName] && (
          <p className="text-danger">{errors[fieldName].message}</p>
        )}
      </div>
    </div>
  );
};

export default MBIField;
