import React, { forwardRef, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import useApis from "../../../apis/useApis";
import appConstant from "./../../../json/appConstant.json";
import { Modal, Button, Form } from "react-bootstrap";
const HierarchyBankingInfo = forwardRef(({ submitForm, data }, ref) => {
  const [modelIndex, setModelIndex] = useState(0);
  const { jsonApi } = useApis();
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const onSubmit = async (formData) => {
    const res = await jsonApi(
      formData?.routingNumber ? appConstant.createBank : appConstant.createCard,
      { id: data._id },
      {
        ...formData,
      },
      false
    );

    submitForm();
    handleClose();
  };

  return (
    <div className="my-5 w-100">
      <h4 className="font-weight-bold text-black mb-3">
        Manage Payment Method
      </h4>
      <button
        type="button"
        className="btn next-step btn_green mb-2 px-3"
        onClick={handleShow}
      >
        Add New Payment Method
      </button>
      <div className="step9 mt-4">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item mr-2 text-nowrap">
            <a
              className="nav-link px-5 active"
              id="creditcard-tab"
              data-toggle="tab"
              href="#creditcard"
              role="tab"
              aria-controls="creditcard"
              aria-selected="false"
            >
              Credit Cards
            </a>
          </li>
          <li className="nav-item text-nowrap">
            <a
              className="nav-link px-5"
              id="bankaccount-tab"
              data-toggle="tab"
              href="#bankaccount"
              role="tab"
              aria-controls="bankaccount"
              aria-selected="true"
            >
              Bank Accounts
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active pt-3"
            id="creditcard"
            role="tabpanel"
            aria-labelledby="creditcard-tab"
          >
            <div className="table-responsive">
              <table
                id="bank_order"
                className="text-dark table display no-wrap text-center mb-0 w-100"
              >
                <thead>
                  <tr>
                    <th>Card Number</th>
                    <th>Exp. Date</th>
                    <th>CVV</th>
                    <th>ZIP</th>
                    <th>State</th>
                  </tr>
                </thead>
                <tbody>
                  {data.bankCard.map((item) => (
                    <tr>
                      <td>{item.cardNumber}</td>
                      <td>{item.expiryDate}</td>
                      <td>{item.cvv}</td>
                      <td>{item.zip}</td>
                      <td>{item.state}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="tab-pane fade pt-3"
            id="bankaccount"
            role="tabpanel"
            aria-labelledby="bankaccount-tab"
          >
            <div className="table-responsive border-bottom">
              <table
                id="bank_order"
                className="text-dark table display no-wrap text-center mb-0"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th className="text-left">Account Name</th>
                    <th className="text-left">Account Number</th>
                    <th className="text-left">Account Type</th>
                    <th>Bank Name</th>
                    <th>Routing Number</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {data.bankAccount.map((item) => (
                    <tr>
                      <td>{item.fullName}</td>
                      <td>{item.accountNumber}</td>
                      <td>{item.accountType}</td>
                      <td>{item.bankName}</td>
                      <td>{item.routingNumber}</td>
                      <td>{item.state}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        backdrop="static" // This prevents closing the modal by clicking outside of it
        keyboard={false} // This prevents closing the modal with the keyboard (e.g., pressing ESC)
        id="AddNewPaymentMethodModal"
        tabIndex={-1}
        aria-labelledby="exampleModaldropLabel"
        aria-hidden="true"
      >
        <div className="">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title text-dark" id="exampleModalLabel">
                Add New Payment Method
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={handleClose}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {modelIndex === 0 ? (
                <AccountPayment
                  modelIndex={modelIndex}
                  setModelIndex={setModelIndex}
                  onSubmit={onSubmit}
                />
              ) : (
                <AddCards
                  modelIndex={modelIndex}
                  setModelIndex={setModelIndex}
                  onSubmit={onSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

const AccountPayment = ({ modelIndex, setModelIndex, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-xl-9 col-lg-10 m-auto">
          <div
            className="btn-group m-auto d-table w-100"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              className={`btn btn-outline-primary w-100 w-sm-50 ${
                modelIndex === 0 ? "active" : ""
              }`}
              onClick={() => setModelIndex(0)}
            >
              Bank Account
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary w-100 w-sm-50 ${
                modelIndex === 1 ? "active" : ""
              }`}
              onClick={() => setModelIndex(1)}
            >
              Credit or Debit Card Account
            </button>
          </div>

          <div className="row">
            <div className="col-12 mb-3">
              <label>Enter Full Name</label>
              <input
                type="text"
                maxLength={256}
                className="form-control"
                defaultValue="Daniel Williams"
                {...register("fullName", { required: "Full name is required" })}
              />
              {errors.fullName && (
                <p className="text-danger">{errors.fullName.message}</p>
              )}
            </div>

            <div className="col-12 mb-3">
              <label>Enter Bank Name</label>
              <input
                type="text"
                maxLength={256}
                className="form-control"
                defaultValue="Citybank"
                {...register("bankName", { required: "Bank name is required" })}
              />
              {errors.bankName && (
                <p className="text-danger">{errors.bankName.message}</p>
              )}
            </div>

            <div className="col-sm-6 mb-3 position-relative">
              <label>Routing Number</label>
              <span className="digits_number">(9 digits)</span>
              <input
                type="text"
                className="form-control"
                maxLength={9}
                placeholder="123456789"
                {...register("routingNumber", {
                  required: "Routing number is required",
                  pattern: {
                    value: /^\d{9}$/,
                    message: "Routing number must be 9 digits",
                  },
                })}
              />
              {errors.routingNumber && (
                <p className="text-danger">{errors.routingNumber.message}</p>
              )}
            </div>

            <div
              className="col-sm-6 mb-3 position-relative"
              id="show_hide_password"
            >
              <label>Bank Account Number</label>
              {/* <span className="digits_number">
                    <Link to="" className="text-muted">
                      <i className="fa fa-eye-slash show_pass" />
                    </Link>
                  </span> */}
              <span
                onClick={togglePasswordVisibility}
                className="digits_number"
              >
                {showPassword ? (
                  <i className="fa fa-eye show_pass"></i>
                ) : (
                  <i className="fa fa-eye-slash show_pass"></i>
                )}
              </span>
              <input
                className="form-control ps-5"
                id="password"
                maxLength={256}
                name="password"
                placeholder="Password"
                required="required"
                type={showPassword ? "text" : "password"}
                {...register("accountNumber", {
                  required: "Bank account number is required",
                })}
              />
              {errors.accountNumber && (
                <p className="text-danger">{errors.accountNumber.message}</p>
              )}
            </div>

            <div className="col-sm-6 mb-3 position-relative">
              <label>Confirm Account</label>
              <input
                type="text"
                maxLength={256}
                className="form-control"
                {...register("confirmAccount", {
                  required: "Confirming the account is required",
                  validate: (value) =>
                    value === watch("accountNumber") ||
                    "Account numbers do not match",
                })}
              />
              {errors.confirmAccount && (
                <p className="text-danger">{errors.confirmAccount.message}</p>
              )}
            </div>

            <div className="col-sm-6 mb-3">
              <label>Account Type</label>
              <select
                className="form-control"
                {...register("accountType", {
                  required: "Account type is required",
                })}
              >
                <option value="">Select Account Type</option>
                <option value="saving">Saving</option>
                <option value="checking">Checking</option>
              </select>
              {errors.accountType && (
                <p className="text-danger">{errors.accountType.message}</p>
              )}
            </div>

            <div className="col-12 mt-4 mb-5">
              <button
                type="submit"
                data-dismiss="modal"
                aria-label="Close"
                className="w-100 btn_green"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
const AddCards = ({ modelIndex, setModelIndex, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const validateExpiryDate = (value) => {
    // const value = "12/26";
    if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(value)) {
      return "Invalid expiry date format";
    }
    const month = value.slice(0, 2);
    const year = `20${value.slice(2, 4)}`;
    const expiry = new Date(year, month - 1); // Month is zero-indexed
    const now = new Date();
    if (expiry < now) {
      return "Expiry date must be in the future";
    }
    return true;
  };
  const validateCVV = (value) => {
    if (!/^\d{3,4}$/.test(value)) {
      return "CVV must be 3 or 4 digits";
    }
    return true;
  };

  const handleCardNumberChange = (e) => {
    const cardNumber = e.target.value.replace(/ /g, "");
    setValue("cardNumber", cardNumber, { shouldValidate: true });
  };
  const handleExpiryDateChange = (e) => {
    // const expiryDate = e.target.value.replace(/\D/g, "");
    setValue("expiryDate", e.target.value, { shouldValidate: true });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-xl-9 col-lg-10 m-auto">
          <div
            className="btn-group m-auto d-table w-100"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              className={`btn btn-outline-primary w-100 w-sm-50  ${
                modelIndex === 0 ? "active" : ""
              }`}
              onClick={() => setModelIndex(0)}
            >
              Bank Account
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary w-100 w-sm-50  ${
                modelIndex === 1 ? "active" : ""
              }`}
              onClick={() => setModelIndex(1)}
            >
              Credit or Debit Card Account
            </button>
          </div>
          <div className="card my-4"></div>
          <div className="row">
            <div className=" col-6 mb-3">
              <label>Card Number</label>
              <InputMask
                mask="9999 9999 9999 9999"
                maskChar=""
                className="form-control"
                {...register("cardNumber", {
                  required: "Card number is required",
                  validate: {
                    length: (value) => {
                      return (
                        value.replace(/ /g, "").length === 16 ||
                        "Card number must be 16 digits"
                      );
                    },
                  },
                })}
                onChange={handleCardNumberChange}
              />
              {errors.cardNumber && (
                <p className="text-danger">{errors.cardNumber.message}</p>
              )}
            </div>
            <div className="col-3 mb-3">
              <label>Expiry Date</label>
              <InputMask
                mask="99/99"
                maskChar=""
                className="form-control"
                {...register("expiryDate", {
                  required: "Expiry date is required",
                  validate: validateExpiryDate,
                })}
                onChange={handleExpiryDateChange}
              />
              {errors.expiryDate && (
                <p className="text-danger">{errors.expiryDate.message}</p>
              )}
            </div>

            <div className="col-3 mb-3">
              <label>CVV</label>
              <input
                type="text"
                className="form-control"
                maxLength={256}
                {...register("cvv", {
                  required: "CVV is required",
                  validate: validateCVV,
                })}
              />
              {errors.cvv && (
                <p className="text-danger">{errors.cvv.message}</p>
              )}
            </div>

            <div className="col-12 mb-3">
              <label>Country</label>
              <input
                type="text"
                className="form-control"
                maxLength={256}
                {...register("country", {
                  required: "Country is required",
                })}
              />
              {errors.country && (
                <p className="text-danger">{errors.country.message}</p>
              )}
            </div>

            <div className="col-12 mb-3">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                maxLength={256}
                {...register("address", {
                  required: "Address is required",
                })}
              />
              {errors.address && (
                <p className="text-danger">{errors.address.message}</p>
              )}
            </div>

            <div className="col-12 mb-3">
              <label>City</label>
              <input
                type="text"
                className="form-control"
                maxLength={256}
                {...register("city", {
                  required: "City is required",
                })}
              />
              {errors.city && (
                <p className="text-danger">{errors.city.message}</p>
              )}
            </div>

            <div className="col-6 mb-3">
              <label>State</label>
              <input
                type="text"
                className="form-control"
                maxLength={256}
                {...register("state", {
                  required: "State is required",
                })}
              />
              {errors.state && (
                <p className="text-danger">{errors.state.message}</p>
              )}
            </div>

            <div className="col-6 mb-3">
              <label>Zip</label>
              <input
                type="text"
                maxLength={5}
                className="form-control"
                {...register("zip", {
                  required: "zip is required",
                  pattern: {
                    value: /^[1-9][0-9]{4}$/,
                    message: "Invalid zip format",
                  },
                })}
              />
              {errors.zip && (
                <p className="text-danger">{errors.zip.message}</p>
              )}
            </div>

            <div className="col-12 mt-4 mb-5">
              <button type="submit" className="w-100 btn_green">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default HierarchyBankingInfo;
