import React, { useEffect, useState } from "react";
import commonHeader from "../../json/commonHeader.json";
import appConstant from "../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { useSearchParams } from "react-router-dom";
import DataTable from "../pagination/DataTable";

const ProspectsLead = () => {
  document.title = "Prospects - Digital Call Center";
  const { jsonApi } = useApis();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [prospectsDetails, setProspectsDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [sortField, setSortField] = useState("fullName");
  const [sortOrder, setSortOrder] = useState("asc");

  const fetchProspectsLeadData = async () => {
    const hasSearchQuery = searchParams.get("query");
    setProspectsDetails([]);
    setTotalData(0);
    try {
      const prospectLeadData = await jsonApi(
        // appConstant.getProspectLead,
        hasSearchQuery
          ? appConstant.getContactData
          : appConstant.getProspectLead,
        {
          page,
          limit,
          search: hasSearchQuery || "",
          sortField,
          sortOrder,
        },
        null,
        false
      );

      if (!prospectLeadData.error) {
        setProspectsDetails(prospectLeadData.data.data || []);
        setTotalData(prospectLeadData.data.total || 0);
      } else {
        setError(
          prospectLeadData.message
            ? prospectLeadData.message
            : "No data received"
        );
      }
    } catch (error) {
      setError("An error occurred while fetching data");
    }
  };

  useEffect(() => {
    fetchProspectsLeadData();
  }, [page, limit, sortField, sortOrder, searchParams.get("query")]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <>
              {error ? (
                <div className="alert alert-danger">{error}</div>
              ) : (
                <div className="card-body custom-field-button">
                  <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                    Prospects Lead Data
                  </h4>

                  <DataTable
                    headers={commonHeader.prospectLead}
                    fetchedData={prospectsDetails}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    totalData={totalData}
                    setLimit={setLimit}
                    init={fetchProspectsLeadData}
                    setSortField={setSortField}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                    isFav={true}
                  />
                </div>
              )}
            </>
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto d-block img-fluid"
            title="Ads"
            alt="ads"
          />
        </div>
      </div>
    </div>
  );
};

export default ProspectsLead;
