import React, { useState } from "react";

const PaginationComponent = ({
  totalData,
  page,
  limit,
  setPage,
  setLimit,
  children,
  setSearch,
  setSortField, // new prop to set the sortField in parent component
  setSortOrder, // new prop to set the sortOrder in parent component
  sortOrder,
}) => {
  const totalPages = Math.ceil(totalData / limit);

  const [pageRangeStart, setPageRangeStart] = useState(1);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      updatePageRange(newPage);
    }
  };

  const updatePageRange = (currentPage) => {
    if (currentPage >= pageRangeStart + 5) {
      setPageRangeStart(pageRangeStart + 5);
    } else if (currentPage < pageRangeStart) {
      setPageRangeStart(pageRangeStart - 5 > 0 ? pageRangeStart - 5 : 1);
    }
  };

  const getVisiblePages = () => {
    const pages = [];
    const end = Math.min(pageRangeStart + 4, totalPages);
    for (let i = pageRangeStart; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  };

  const handleSort = (order) => {
    setSortOrder(order); // Set the sort order in parent
    setSortField("fullName"); // Assuming we're sorting by fullName, you can make it dynamic
  };
  
  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-6 col-md-6">
          <div className="dataTables_length">
            <label className="form-label">
              Show
              <select
                className="form-select form-select-sm ms-2"
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              entries
            </label>
          </div>
        </div>
        {sortOrder ? (
          <div className="col-sm-12 col-md-6">
            <div className="dataTables_filter">
              <span>Order: </span>
              {"  "}{" "}
              <button
                onClick={() => handleSort("RU")}
                className={`btn btn-primary me-2 ${
                  sortOrder === "RU" ? "active" : ""
                }`}
                style={{padding:"0.175rem 0.25rem" }}
              >
                Recent Updated
              </button>
              <button
                onClick={() => handleSort("RC")}
                className={`btn btn-primary me-2 ${
                  sortOrder === "RC" ? "active" : ""
                }`}
                style={{padding:"0.175rem 0.25rem" }}
              >
                Recent Created
              </button>
              <button
                onClick={() => handleSort("asc")}
                className={`btn btn-primary me-2 ${
                  sortOrder === "asc" ? "active" : ""
                }`}
                style={{padding:"0.175rem 0.25rem" }}
              >
                A-Z
              </button>
              <button
                onClick={() => handleSort("desc")}
                className={`btn btn-primary me-2 ${
                  sortOrder === "desc" ? "active" : ""
                }`}
                style={{padding:"0.175rem 0.25rem" }}
              >
                Z-A
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
        {setSearch ? (
          <div className="col-sm-12 col-md-6">
            <div className="dataTables_filter">
              {/* <label className="form-label">
                Search:
                <input
                  type="search"
                  maxLength={256}
                  className="form-control form-control-sm ms-2"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label> */}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {children}

      <div className="row">
        <div className="col-sm-12 col-md-5">
          <div className="dataTables_info" role="status" aria-live="polite">
            Showing {(page - 1) * limit + 1} to{" "}
            {Math.min(page * limit, totalData)} of {totalData} entries
          </div>
        </div>
        <div className="col-sm-12 col-md-7">
          <div className="dataTables_paginate">
            <ul className="pagination justify-content-end">
              <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                >
                  Previous
                </button>
              </li>
              {/* Render page numbers dynamically */}
              {/* {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${page === index + 1 ? "active" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))} */}
              {getVisiblePages().map((p) => (
                <li
                  key={p}
                  className={`page-item ${page === p ? "active" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(p)}
                  >
                    {p}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${page === totalPages ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaginationComponent;
