import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import { Context } from "../../../context/Context";
import "./ProgessButton.css";
import { useFormContext } from "./FormContext";

const AddPersonalContact = () => {
  document.title = "Contact - Digital Call Center";
  let location = useLocation();
  const { jsonApi } = useApis();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditing, setIsEditing] = useState(false);
  const { userData } = useContext(Context);
  const { setFormContextData, formContextData } = useFormContext();
  console.log(formContextData);

  const createUser = async () => {
    if (
      location.pathname === "/contact/personal/add/detail" ||
      location.pathname === "/contact/personal/add" ||
      location.pathname === "/contact/personal/add/dependent" ||
      location.pathname === "/contact/personal/add/spouse"
    ) {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }

    if (!searchParams.get("id")) {
      const res = await jsonApi(appConstant.createUserDetail, null, {}, false);
      if (!res.error) {
        setSearchParams({
          id: res.data.data._id,
        });
      }
    } else {
      const res = await jsonApi(
        appConstant.createUserDetail,
        { id: searchParams.get("id") },
        {},
        false
      );
    }
  };

  useEffect(() => {
    createUser();
  }, [searchParams]);

  const handleChange = (e) => {
    setFormContextData({ ...formContextData, [e.target.name]: e.target.value });
  };

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: searchParams.get("id") },
      {},
      false
    );
    if (!res?.error) {
      setSearchParams({
        id: res.data.data._id,
      });
    }
  };
  useEffect(() => {
    init();
  }, [searchParams.get("id")]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="row">
            <div className="col-12">
              <div className="mb-4 ml-4">
                <div className="row">
                  {/* First column: Dropdowns */}
                  <div className="col-md-4">
                    <div className="d-flex align-items-center mb-1">
                      <label
                        htmlFor="leadSource"
                        className="mb-0 mr-3"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Lead Source -
                      </label>
                      <select
                        id="leadSource"
                        className="form-control border-0 rounded-0 p-1"
                        style={{
                          width: "200px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        name="leadSource"
                        value={formContextData.leadSource}
                        onChange={handleChange}
                      >
                        <option value="">Select Lead Source</option>
                        <option value="Vici Dial">Vici Dial</option>
                        <option value="Quote Wizard">Quote Wizard</option>
                      </select>
                    </div>

                    <div className="d-flex align-items-center mb-1">
                      <label
                        htmlFor="typeOfLead"
                        className="mb-0 mr-3"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Type of Lead -
                      </label>
                      <select
                        id="typeOfLead"
                        className="form-control border-0 rounded-0 p-1"
                        style={{
                          width: "200px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        name="typeOfLead"
                        value={formContextData.typeOfLead}
                        onChange={handleChange}
                      >
                        <option value="">Select Type of Lead</option>
                        <option value="medicare">Medicare</option>
                        <option value="life">Life</option>
                        <option value="auto">Auto</option>
                        <option value="homeowners">Homeowners</option>
                        <option value="solarEnergy">Solar Energy</option>
                      </select>
                    </div>

                    <div className="d-flex align-items-center mb-1">
                      <label
                        htmlFor="saleStage"
                        className="mb-0 mr-3"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Sale Stage -
                      </label>
                      <select
                        id="saleStage"
                        className="form-control border-0 rounded-0 p-1"
                        style={{
                          width: "200px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        name="saleStage"
                        value={formContextData.saleStage}
                        onChange={handleChange}
                      >
                        <option value="">Select Sale Stage</option>
                        <option value="nurturing">Nurturing</option>
                        <option value="stage2">Stage 2</option>
                      </select>
                    </div>
                  </div>

                  {/* Textarea for Notes */}
                  <div className="col-md-8 col-sm-8">
                    <textarea
                      className="form-control black-border"
                      style={{ fontWeight: "bold", color: "black" }}
                      id="notes"
                      rows={4}
                      maxLength={1024}
                      name="notes"
                      value={formContextData.notes}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <h3 className="page-title text-truncate text-dark font-weight-medium mx-3">
                {isEditing ? "Edit Consumer Contact" : "Add Consumer Contact"}
              </h3>

              <div className="mb-4 mt-4 ml-4 gap-3">
                <button className="btn-primarys mr-2">
                  <Link
                    to={`detail?id=${searchParams.get("id")}`}
                    className="text-white"
                  >
                    {isEditing ? "Edit Contact" : "Save Contact"}
                  </Link>
                </button>

                <button className="btn-primarys mr-2">
                  <Link
                    to={`spouse?id=${searchParams.get("id")}`}
                    className="text-white"
                  >
                    {isEditing ? "Edit Spouse" : "Add Spouse"}
                  </Link>
                </button>

                <button className="btn-primarys mr-2">
                  <Link
                    to={`dependent?id=${searchParams.get("id")}`}
                    className="text-white"
                  >
                    {isEditing ? "Edit Dependent" : "Add Dependent"}
                  </Link>
                </button>
              </div>

              <Outlet />
            </div>
          </div>
        </div>

        {/* Ads Sidebar */}
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto img-fluid"
            title="Ads"
            alt="Ads"
          />
        </div>
      </div>
    </div>
  );
};

export default AddPersonalContact;
