import React, { useContext, useEffect, useState } from "react";
import appConstant from "../../../json/appConstant.json";
import businessDetails from "../../../json/businessDetails.json";
import useApis from "../../../apis/useApis";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import moment from "moment";
import { CallCenterContext } from "../../../context/CallCenterContext";

const BusinessContactDetails = () => {
  document.title="Business Contact - Digital Call Center";
  const { jsonApi } = useApis();
  const { id } = useParams();
  const [businessDetailData, setBusinessDetailData] = useState({});
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { makeCall } = useContext(CallCenterContext);
  const [customForm, setCustomForm] = useState(null);
  const [customData, setCustomData] = useState(false);

  const init = async () => {
    const businessContactDetail = await jsonApi(
      appConstant.getSingleUserDetail,
      { id },
      null,
      true
    );

    if (!businessContactDetail.error) {
      if (businessContactDetail?.data?.data?.customForm) {
        setCustomForm(businessContactDetail.data.data.customForm);
        setCustomData(businessContactDetail?.data?.data?.customData);
      } else {
        setCustomForm(null);
        setCustomData(false);
        setBusinessDetailData(businessContactDetail?.data?.data || {});
      }
    } else {
      setError(businessContactDetail.message || "No data received");
    }
  };

  useEffect(() => {
    init();
  }, [id]);

  const formatTimestamp = (timestamp, formatStr) => {
    if (!timestamp) return "N/A";
    const date = moment(timestamp);
    return date.format(formatStr);
  };

  const renderField = (fieldKey, label) => {
    let fieldValue = businessDetailData[fieldKey] || customData[fieldKey];

    if (fieldKey === "leadCheck" && fieldValue) {
      fieldValue = fieldValue?.charAt(0)?.toUpperCase() + fieldValue?.slice(1);
    }
    if (fieldKey === "dob" && fieldValue) {
      fieldValue = formatTimestamp(fieldValue, "DD/MM/YYYY");
    }

    if (fieldValue) {
      return (
        <div className="col-md-4 mb-3 d-flex align-items-center" key={fieldKey}>
          <label
            className="col-form-label me-2"
            style={{ color: "black", fontWeight: "bold" }}
          >
            {label}:
          </label>
          <div>
            {Array.isArray(fieldValue) ? (
              fieldValue.length > 0 ? (
                fieldValue.map((item) => (
                  <div key={item._id} className="user-info-value">
                    {item.value}
                  </div>
                ))
              ) : (
                <span className="text-muted">No Data</span>
              )
            ) : fieldKey === "phone" ? (
              <span
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  makeCall(fieldValue);
                  navigate("/call/centre");
                }}
              >
                {fieldValue}
              </span>
            ) : (
              fieldValue || <span className="text-muted">No Data</span>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body">
              <>
                {error ? (
                  <div className="alert alert-danger">{error}</div>
                ) : (
                  <div className="row">
                    <div className="col-12 align-self-center">
                      <div className="row">
                        <div className="col-sm">
                          <span
                            style={{
                              color: "blue",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            onMouseEnter={(e) =>
                              (e.target.style.textDecoration = "underline")
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.textDecoration = "none")
                            }
                            onClick={() => {
                              if (
                                location.pathname ===
                                `/contact/business/details/${id}`
                              ) {
                                navigate(`/contact/business`);
                              } else if (
                                businessDetailData?.leadCheck === "client" ||
                                customData?.leadType === "client"
                              ) {
                                navigate(`/contact/lead/client`);
                              } else if (
                                businessDetailData?.leadCheck === "prospects" ||
                                customData?.leadType === "prospects"
                              ) {
                                navigate(`/contact/lead/prospects`);
                              }
                            }}
                          >
                            <ChevronLeft />
                            Back to previous page
                          </span>
                          <h2 className="page-title text-truncate text-dark font-weight-medium mb-0 pt-4">
                            {location.pathname ===
                            `/contact/business/details/${id}`
                              ? `${businessDetailData.type} Detail`
                              : `${
                                  (
                                    businessDetailData.leadCheck ||
                                    customData?.leadType
                                  )
                                    ?.charAt(0)
                                    .toUpperCase() +
                                  (
                                    businessDetailData.leadCheck ||
                                    customData?.leadType
                                  )?.slice(1)
                                } Lead Detail`}
                          </h2>
                        </div>
                      </div>

                      {customForm ? (
                        <div className="row ml-2">
                          <h4 className="page-title text-dark font-weight-medium my-4 border-bottom pb-2">
                            Custom Form Data
                          </h4>
                          <div className="custom-data-container">
                            {customData && (
                              <div className="row">
                                {businessDetails.customDetailHeader.map(
                                  ({ key, label }) => renderField(key, label)
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="row ml-2">
                          <h4 className="page-title text-dark font-weight-medium my-4 border-bottom pb-2">
                            Information
                          </h4>
                          <div className="row">
                            {businessDetails.businessDetailHeader.map(
                              ({ key, label }) => renderField(key, label)
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>

        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto img-fluid"
            title="Ads"
            alt="ads"
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessContactDetails;
