import React, { useEffect, useState } from "react";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";

const StatictTotalCallChart = () => {
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Calls Received per Month",
    },
    xAxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      title: {
        text: "Month",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of Calls",
      },
    },
    series: [
      {
        name: "Total Received Calls",
        data: [],
      },
    ],
  };
  

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default StatictTotalCallChart;
