import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import commonHeader from "../../../../json/commonHeader.json";
import appConstant from "../../../../json/appConstant.json";
import useApis from "../../../../apis/useApis";
import {
  validateName,
  validateEmail,
  validateZipCode,
  validateSuiteNumber,
  validatePercentOwn,
} from "../../../validation/Validation";
import { PhoneInput } from "react-international-phone";
import { useNavigate, useSearchParams } from "react-router-dom";

const BusinessExecutive = () => {
  document.title="Business Executive - Digital Call Center";
  const formRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [executiveContactDetails, setExecutiveContactDetails] = useState([]);
  const [successMessage, setSuccessMessage] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const handleMessage = () => {
    setInterval(() => {
      setSuccessMessage("");
    }, 5000);
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      type: "Human Resource",
    };

    const formatContact = (contact, type = "Personal") =>
      contact && typeof contact === "string"
        ? [{ value: contact, default: false, type }]
        : [];
    formData.phone = formatContact(formData.phone);
    formData.email = formatContact(formData.email);

    try {
      let response;
      if (isEditing && editData) {
        response = await jsonApi(
          appConstant.editBusinessContactHuman,
          { id: editData._id },
          formData,
          null
        );
      } else {
        response = await jsonApi(
          appConstant.createBusinessContactHumanResource,
          null,
          formData,
          null
        );
      }
      if (!response.error) {
        setError("");
        setSuccessMessage(response.data.message);
        reset();
        setIsEditing(false);
        handleMessage();
        setSearchParams({ id: "" });
        navigate("/contact/business/executive");
      } else {
        if (
          response.status === 400 &&
          response.message === "Email is already in use."
        ) {
          setError("This email is already in use. Please use a different one.");
        } else {
          setError(response.message);
        }
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  const handleAddExecutiveClick = () => {
    reset();
    setIsEditing(false);
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: searchParams.get("id") },
      {},
      false
    );
    if (!res?.error) {
      setSearchParams({
        id: res.data.data._id,
      });
      setData(res.data.data);
      populateForm(res.data.data);
    }
  };
  useEffect(() => {
    init();
  }, [searchParams.get("id")]);

  const populateForm = (data) => {
    if (!data) return;

    setIsEditing(true);
    setEditData(data);

    const getValue = (key) =>
      Array.isArray(data[key]) ? data[key][0]?.value : data[key];

    const fields = [
      "companyName",
      "firstName",
      "lastName",
      "companyStreetAddress",
      "primaryResponsibility",
      "companySuiteNo",
      "companyZip",
      "companyState",
      "companyCity",
      "companyOwner",
      "companyPercentageOwn",
    ];

    fields.forEach((field) => setValue(field, data[field]));
    setValue("phone", getValue("phone"));
    setValue("email", getValue("email"));

    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const firstName = watch("firstName");
  const lastName = watch("lastName");

  useEffect(() => {
    if (firstName && lastName) {
      setValue("fullName", `${firstName} ${lastName}`);
    }
  }, [firstName, lastName, setValue]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <div className="row mb-5">
                <div className="col-lg-4">
                  {commonHeader.fields.map((field, index) => (
                    <div
                      className="row align-items-center mb-3 mb-sm-0"
                      key={index}
                    >
                      <label className="col-md-5 col-sm-4 mb-0 font-weight-bold">
                        {field.label}
                      </label>
                      <div className="col-md-7 col-sm-8">
                        <input
                          type={field.type}
                          className={field.class}
                          defaultValue={field.value}
                          readOnly={true}
                          maxLength={256}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-lg-8">
                  <textarea
                    className="form-control black-border"
                    //placeholder="Notes: 01/01/22, 6:00 AM John Doe"
                    id="notes"
                    rows={8}
                    {...register("notes")}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-4">
                  <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                    Executive
                    <button
                      type="button"
                      className="btn btn-sm btn-primary btn-rounded px-4 ml-4"
                      data-toggle="modal"
                      data-target="#addOwnerModal"
                      onClick={handleAddExecutiveClick}
                    >
                      Add Executive
                    </button>
                  </h4>
                </div>
              </div>

              <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
                {isEditing ? "Edit Executive" : "Add New Executive"}
                <h4 className="text-success text-center pt-4">
                  {" "}
                  {successMessage}
                </h4>
              </h4>

              <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <label
                        htmlFor="companyName"
                        className="col-sm-5 col-form-label"
                      >
                        Company Name
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          id="companyName"
                          maxLength={256}
                          //placeholder="ABC Company"
                          {...register("companyName", {
                            required: "Company Name is required",
                            validate: (value) => validateName(value, "name"),
                          })}
                        />

                        {errors.companyName && (
                          <p className="text-danger">
                            {errors.companyName.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="firstName"
                        className="col-sm-5 col-form-label"
                      >
                        First Name
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          //placeholder="John"
                          className="form-control black-border"
                          id="fullName"
                          maxLength={256}
                          {...register("firstName", {
                            required: "Primary name is required",
                            validate: (value) => validateName(value, "name"),
                          })}
                        />
                        {errors.firstName && (
                          <p className="text-danger">
                            {errors.firstName.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="lastName"
                        className="col-sm-5 col-form-label"
                      >
                        Last Name
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          //placeholder="John"
                          className="form-control black-border"
                          id="lastName"
                          maxLength={256}
                          {...register("lastName", {
                            required: "Primary name is required",
                            validate: (value) => validateName(value, "name"),
                          })}
                        />
                        {errors.lastName && (
                          <p className="text-danger">
                            {errors.lastName.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <input type="hidden" {...register("fullName")} />

                    <div className="form-group row">
                      <label
                        htmlFor="employmentLevel"
                        className="col-sm-5 col-form-label"
                      >
                        Employment Level
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          id="employmentLevel"
                          value="Executive"
                          {...register("employmentLevel", { required: true })}
                          readOnly
                        />
                        {errors.employmentLevel && (
                          <p className="text-danger">
                            Employment Level is required
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="phone"
                        className="col-sm-5 col-form-label"
                      >
                        Contact Number
                      </label>
                      <div className="col-sm-7">
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field }) => (
                            <>
                              <PhoneInput
                                preferredCountries={"us"}
                                // className={`w-100 ${
                                //   errors.phone
                                //     ? "is-invalid"
                                //     : ""
                                // }`}
                                value={field.value}
                                onChange={(phone) => field.onChange(phone)}
                                onBlur={field.onBlur}
                                inputProps={{ required: true }}
                                disableDialCodeAndPrefix={true}
                                disableFocusAfterCountrySelect={false}
                                hideDropdown={true}
                                disableCountryGuess={true}
                                className="black-border"
                              />
                              {errors.phone && (
                                <p className="text-danger">
                                  {errors.phone.message}
                                </p>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="email"
                        className="col-sm-5 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="email"
                          className="form-control black-border"
                          id="email"
                          maxLength={256}
                          //placeholder="abc@gmail.com"
                          {...register("email", {
                            required: "Email is required",
                            validate: validateEmail,
                          })}
                        />
                        {errors.email && (
                          <p className="text-danger">{errors.email.message}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group row">
                      <label
                        htmlFor="companyStreetAddress"
                        className="col-sm-4 col-form-label"
                      >
                        Street Address
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          id="companyStreetAddress"
                          maxLength={256}
                          //placeholder="143, 7th Street, Cannes Road"
                          {...register("companyStreetAddress", {
                            required: true,
                          })}
                        />
                        {errors.companyStreetAddress && (
                          <p className="text-danger">
                            Street Address is required
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="companySuiteNo"
                        className="col-sm-4 col-form-label"
                      >
                        Suite Number
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          id="companySuiteNo"
                          maxLength={128}
                          //placeholder="STE 200"
                          {...register("companySuiteNo", {
                            required: "Please Enter suite number",
                            validate: validateSuiteNumber,
                          })}
                        />
                        {errors.companySuiteNo && (
                          <p className="text-danger">
                            {errors.companySuiteNo.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="companyCity"
                        className="col-sm-4 col-form-label"
                      >
                        City
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          //placeholder="Los Angeles"
                          id="companyCity"
                          maxLength={128}
                          {...register("companyCity", {
                            required: "Please enter city",
                            validate: (value) => validateName(value, "city"),
                          })}
                        />
                        {errors.companyCity && (
                          <p className="text-danger">
                            {errors.companyCity.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="companyState"
                        className="col-sm-4 col-form-label"
                      >
                        State
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          //placeholder="California"
                          id="companyState"
                          maxLength={128}
                          {...register("companyState", {
                            required: "Please enter state",
                            validate: (value) => validateName(value, "state"),
                          })}
                        />
                        {errors.companyState && (
                          <p className="text-danger">
                            {errors.companyState.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="companyZip"
                        className="col-sm-4 col-form-label"
                      >
                        Zip Code
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          id="companyZip"
                          maxLength={5}
                          //placeholder="25145"
                          {...register("companyZip", {
                            required: "Please enter zipcode",
                            validate: validateZipCode,
                          })}
                        />
                        {errors.companyZip && (
                          <p className="text-danger">
                            {errors.companyZip.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-2 col-md-3 col-sm-4">
                    Primary Responsibility
                  </label>
                  <div className="col-lg-10 col-md-9 col-sm-8">
                    <textarea
                      className="form-control black-border"
                      id="primaryResponsibility"
                      rows={4}
                      maxLength={256}
                      //placeholder="Enter primary responsibility"
                      {...register("primaryResponsibility", {
                        required: "Primary Responsibility is required",
                      })}
                    />
                    {errors.primaryResponsibility && (
                      <p className="text-danger">
                        {errors.primaryResponsibility.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-10 offset-sm-2">
                    <button type="submit" className="btn btn-primary">
                      {isEditing ? "Save Executive" : "Add New Executive"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto d-block img-fluid"
            title="Ads"
            alt="ads"
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessExecutive;
