import { Link, Outlet } from "react-router-dom";
import Header from "../header/Header";
import SideBar from "../sideBar/SideBar";
import { useEffect, useState } from "react";
import Contact from "../contact/Contact";
import { useLocation, useSearchParams } from "react-router-dom";
import ContactHeader from "../contact/contactHeader/ContactHeader";
import SearchForm from "../contact/SearchForm";

const Aside = () => {
  const [sideBarEnable, setSideBarEnable] = useState(false);

  const toggleSideBar = () => {
    setSideBarEnable(!sideBarEnable);
  };

  const [query, setQuery] = useState("");
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    handleEmptySearch();
  }, [location.pathname]);
  useEffect(() => {
    if (!query) {
      searchParams.delete("query");
      setSearchParams(searchParams);
    } else {
      setSearchParams({ query });
    }
  }, [query]);

  const handleEmptySearch = () => {
    console.log("firsta");
    searchParams.delete("query");
    setSearchParams(searchParams);
    setQuery("");
  };
  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
        className={`${sideBarEnable ? "show-sidebar" : "hide-sidebar"}`}
      >
        <Header toggleSideBar={toggleSideBar} sideBarEnable={sideBarEnable} />

        <SideBar />

        <div className="page-wrapper">
          <div className="page-breadcrumb">
            <div className="row">
              <SearchForm
                query={query}
                setQuery={setQuery}
                handleEmptySearch={handleEmptySearch}
              />
              <ContactHeader />
            </div>
          </div>
          <Outlet />

          <footer className="footer text-center text-muted">
            All Rights Reserved by Digital Company. Designed and Developed by{" "}
            <Link to="/">Digital Company</Link>.
          </footer>
        </div>
      </div>
    </>
  );
};

export default Aside;
