import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import {
  validateName,
  validateEmail,
  validateZipCode,
  validateSuiteNumber,
  validatePercentOwn,
} from "../../validation/Validation";
import { PhoneInput } from "react-international-phone";

const AddBusinessContact = () => {
  document.title="Add Business Contact - Digital Call Center";
  const formRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const { jsonApi } = useApis();
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const employmentLevel = watch("employmentLevel");
  // const type = ["Business Contact"];

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      type: "Business Contact",
    };
    const formatContact = (contact, type = "Personal") =>
      contact && typeof contact === "string"
        ? [{ value: contact, default: false, type }]
        : [];
    formData.phone = formatContact(formData.phone);
    formData.email = formatContact(formData.email);
    try {
      let response;
      if (isEditing && editData) {
        response = await jsonApi(
          appConstant.editBusinessContactHuman,
          { id: editData._id },
          formData,
          null
        );
        if (!response.error) {
          setSuccessMessage("Business Contact Updated Successfully");
        }
      } else {
        response = await jsonApi(
          appConstant.createBusinessContactHumanResource,
          null,
          formData,
          null
        );
        if (!response.error) {
          setSuccessMessage("Business Contact Added Successfully");
        }
      }
      if (!response.error) {
        setError("");
        reset();
        setIsEditing(false);
        handleMessage();
        navigate("/contact/business/add", { replace: true });
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  const handleMessage = () => {
    setInterval(() => {
      setSuccessMessage("");
    }, 5000);
  };

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: searchParams.get("id") },
      {},
      false
    );
    if (!res?.error) {
      setSearchParams({
        id: res.data.data._id,
      });
      console.log("first", res);
      setData(res.data.data);
      populateForm(res.data.data);
    }
  };
  useEffect(() => {
    init();
  }, [searchParams.get("id")]);

  const populateForm = (data) => {
    if (!data) return;

    setIsEditing(true);
    setEditData(data);

    const getValue = (key) =>
      Array.isArray(data[key]) ? data[key][0]?.value : data[key];

    const fields = [
      "companyName",
      "firstName",
      "lastName",
      "employmentLevel",
      "primaryResponsibility",
      "companyStreetAddress",
      "companySuiteNo",
      "companyZip",
      "companyState",
      "companyCity",
      "companyPercentageOwn",
      "notes",
    ];

    fields.forEach((field) => setValue(field, data[field]));
    setValue("phone", getValue("phone"));
    setValue("email", getValue("email"));

    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const firstName = watch("firstName");
  const lastName = watch("lastName");

  useEffect(() => {
    if (firstName && lastName) {
      setValue("fullName", `${firstName} ${lastName}`);
    }
  }, [firstName, lastName, setValue]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                {isEditing
                  ? "Edit Business Contact"
                  : "Add New Business Contact"}
                <h4 className="text-success text-center pt-4">
                  {" "}
                  {successMessage}
                </h4>
              </h4>

              <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <label
                        htmlFor="companyName"
                        className="col-sm-5 col-form-label"
                      >
                        Company Name
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          id="companyName"
                          maxLength={256}
                          //placeholder="ABC Company"
                          {...register("companyName", {
                            required: "Company Name is required",
                            validate: (value) => validateName(value, "name"),
                          })}
                        />

                        {errors.companyName && (
                          <p className="text-danger">
                            {errors.companyName.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="firstName"
                        className="col-sm-5 col-form-label"
                      >
                        First Name
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          //placeholder="John"
                          className="form-control black-border"
                          id="firstName"
                          maxLength={256}
                          {...register("firstName", {
                            required: "First name is required",
                            validate: (value) => validateName(value, "name"),
                          })}
                        />
                        {errors.firstName && (
                          <p className="text-danger">
                            {errors.firstName.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="lastName"
                        className="col-sm-5 col-form-label"
                      >
                        Title/Surname
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          id="lastName"
                          maxLength={256}
                          //placeholder="Luther"
                          {...register("lastName", {
                            required: "Title is required",
                            validate: (value) => validateName(value, "Title"),
                          })}
                        />
                        {errors.lastName && (
                          <p className="text-danger">
                            {" "}
                            {errors.lastName.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <input type="hidden" {...register("fullName")} />

                    <div className="form-group row">
                      <label
                        htmlFor="employmentLevel"
                        className="col-sm-5 col-form-label"
                      >
                        Employment Level
                      </label>
                      <div className="col-sm-7">
                        <select
                          className="form-control black-border"
                          id="employmentLevel"
                          {...register("employmentLevel", { required: true })}
                        >
                          <option value="" disabled>
                            Select employment level
                          </option>
                          <option value="Owner">Owner</option>
                          <option value="Manager">Manager</option>
                          <option value="Executive">Executive</option>
                          <option value="Employee">Employee</option>
                        </select>
                        {errors.employmentLevel && (
                          <p className="text-danger">
                            Employment Level is required
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="phone"
                        className="col-sm-5 col-form-label"
                      >
                        Phone
                      </label>
                      <div className="col-sm-7">
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field }) => (
                            <>
                              <PhoneInput
                                preferredCountries={"us"}
                                className={`black-border w-100 ${
                                  errors.phone ? "is-invalid" : ""
                                }`}
                                value={field.value}
                                onChange={(phone) => field.onChange(phone)}
                                onBlur={field.onBlur}
                                inputProps={{ required: true }}
                                disableDialCodeAndPrefix={true}
                                disableFocusAfterCountrySelect={false}
                                hideDropdown={true}
                                disableCountryGuess={true}
                              />
                              {errors.phone && (
                                <p className="text-danger">
                                  {errors.phone.message}
                                </p>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="email"
                        className="col-sm-5 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="email"
                          className="form-control black-border"
                          id="email"
                          maxLength={256}
                          //placeholder="abc@gmail.com"
                          {...register("email", {
                            required: "Email is required",
                            validate: validateEmail,
                          })}
                        />
                        {errors.email && (
                          <p className="text-danger">{errors.email.message}</p>
                        )}
                      </div>
                      {/* <button
                        type="button"
                        class="btn btn-outline-primary btn-circle"
                        data-toggle="modal"
                        data-target="#primaryContactModal"
                        onClick={handleEmailModelOpen}
                      >
                        <i class="fa fa-plus"></i>
                      </button> */}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group row">
                      <label
                        htmlFor="companyStreetAddress"
                        className="col-sm-4 col-form-label"
                      >
                        Street Address
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          id="companyStreetAddress"
                          maxLength={256}
                          //placeholder="143, 7th Street, Cannes Road"
                          {...register("companyStreetAddress", {
                            required: true,
                          })}
                        />
                        {errors.companyStreetAddress && (
                          <p className="text-danger">
                            Street Address is required
                          </p>
                        )}
                      </div>
                      {/* <button
                        type="button"
                        class="btn btn-outline-primary btn-circle"
                        data-toggle="modal"
                        data-target="#primaryContactModal"
                        onClick={handleAddressModelOpen}
                      >
                        <i class="fa fa-plus"></i>
                      </button> */}
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="companySuiteNo"
                        className="col-sm-4 col-form-label"
                      >
                        Suite Number
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          id="companySuiteNo"
                          maxLength={128}
                          //placeholder="STE 200"
                          {...register("companySuiteNo", {
                            required: "Please Enter suite number",
                            validate: validateSuiteNumber,
                          })}
                        />
                        {errors.companySuiteNo && (
                          <p className="text-danger">
                            {errors.companySuiteNo.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="companyCity"
                        className="col-sm-4 col-form-label"
                      >
                        City
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          //placeholder="Los Angeles"
                          id="companyCity"
                          maxLength={128}
                          {...register("companyCity", {
                            required: "Please enter city",
                            validate: (value) => validateName(value, "city"),
                          })}
                        />
                        {errors.companyCity && (
                          <p className="text-danger">
                            {errors.companyCity.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="companyState"
                        className="col-sm-4 col-form-label"
                      >
                        State
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          //placeholder="California"
                          id="companyState"
                          maxLength={128}
                          {...register("companyState", {
                            required: "Please enter state",
                            validate: (value) => validateName(value, "state"),
                          })}
                        />
                        {errors.companyState && (
                          <p className="text-danger">
                            {errors.companyState.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="companyZip"
                        className="col-sm-4 col-form-label"
                      >
                        Zip Code
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control black-border"
                          id="companyZip"
                          maxLength={5}
                          //placeholder="25145"
                          {...register("companyZip", {
                            required: "Please enter zipcode",
                            validate: validateZipCode,
                          })}
                        />
                        {errors.companyZip && (
                          <p className="text-danger">
                            {errors.companyZip.message}
                          </p>
                        )}
                      </div>
                    </div>

                    {employmentLevel === "Owner" && (
                      <>
                        <div className="form-group row">
                          <label
                            htmlFor="companyPercentageOwn"
                            className="col-sm-4 col-form-label"
                          >
                            Percent Own
                          </label>
                          <div className="col-sm-7">
                            <div className="row align-items-center">
                              <div className="col-7 input-group">
                                <input
                                  type="number"
                                  className="form-control black-border"
                                  id="companyPercentageOwn"
                                  //placeholder="75"
                                  {...register("companyPercentageOwn", {
                                    required: "Percentage Own is required",
                                    validate: validatePercentOwn,
                                    valueAsNumber: true,
                                  })}
                                  max="100"
                                  min="1"
                                  step="1" // Ensures only whole numbers are accepted
                                  onInput={(e) => {
                                    let value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    if (Number(value) > 100) {
                                      value = "100";
                                    }
                                    e.target.value = value;
                                  }}
                                />

                                <div className="input-group-prepend">
                                  <div className="input-group-text">%</div>
                                </div>
                              </div>
                              {errors.companyPercentageOwn && (
                                <p className="text-danger">
                                  {errors.companyPercentageOwn.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="row mb-4">
                  <label className="col-lg-2 col-md-3 col-sm-4">
                    Primary Responsibility
                  </label>
                  <div className="col-lg-10 col-md-9 col-sm-8">
                    <textarea
                      className="form-control black-border"
                      id="primaryResponsibility"
                      rows={4}
                      maxLength={256}
                      //placeholder="Enter primary responsibility"
                      {...register("primaryResponsibility", {
                        required: "Primary Responsibility is required",
                      })}
                    />
                    {errors.primaryResponsibility && (
                      <p className="text-danger">
                        {errors.primaryResponsibility.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="row mb-4">
                  <label className="col-lg-2 col-md-3 col-sm-4">Note</label>
                  <div className="col-lg-10 col-md-9 col-sm-8">
                    <textarea
                      className="form-control black-border"
                      id="notes"
                      rows={4}
                      maxLength={256}
                      //placeholder="Enter primary responsibility"
                      {...register("notes", {})}
                    />
                    {errors.notes && (
                      <p className="text-danger">{errors.notes.message}</p>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-10 offset-sm-2">
                    <button type="submit" className="btn btn-primary">
                      {isEditing
                        ? "Save Business Contact"
                        : "Add New Business Contact"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBusinessContact;
