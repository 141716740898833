import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Mail, Menu, X } from "react-feather";
import {
  MessageCircle,
  Voicemail,
  MessageSquare,
  Airplay,
  Box,
  Settings,
  Calendar,
  Search,
  Phone,
} from "react-feather";
import { Context } from "../../context/Context";
import moment from "moment";
import ImageComponent from "../../media/ImageComponent";
import useApis from "../../apis/useApis";
import { VICIDialContext } from "../../context/VICIDialContext";

const Header = ({ toggleSideBar, sideBarEnable }) => {
  const { userData, selectedStatus, setSelectedStatus } = useContext(Context);
  const { currentLeadStatus, getLoginUrlViCiDial, setViciDialStatus } =
    useContext(VICIDialContext);
  const { jsonApi } = useApis();
  const formatTimestamp = (timestamp, formatStr) => {
    if (!timestamp) return "N/A";
    const date = moment(timestamp);
    return date.format(formatStr);
  };
  const [date, setDate] = useState(new Date());
  const [query, setQuery] = useState("");

  useEffect(() => {
    const interval = setInterval(async () => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleSearch = () => {
    if (query.trim()) {
      const searchURL = `https://www.google.com/search?q=${encodeURIComponent(
        query
      )}`;
      window.open(searchURL, "_blank");
      setQuery("");
    }
  };

  return (
    <>
      <header className="topbar" data-navbarbg="skin6">
        <nav className="navbar top-navbar navbar-expand-md">
          <div className="navbar-header" data-logobg="skin6">
            <a
              className="nav-toggler waves-effect waves-light d-md-none"
              onClick={toggleSideBar}
            >
              <i className={sideBarEnable ? "ti-close" : "ti-menu"}></i>
            </a>

            <div className="navbar-brand">
              <Link to="#">
                <span className="logo-text">Digital Company</span>
              </Link>
            </div>
            {/* End Logo */}
            {/* Toggle which is visible on mobile only */}
            <Link
              className="topbartoggler d-block d-md-none waves-effect waves-light"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="ti-more" />
            </Link>
          </div>

          <div className="navbar-collapse collapse" id="navbarSupportedContent">
            <ul className="navbar-nav float-left mr-auto ml-3 pl-1">
              {/* User profile and search */}
              <li className="nav-item mr-3">
                <div className="row">
                  <div className="ml-4 mt-2">
                    <ImageComponent
                      className="rounded-circle width-40"
                      src={userData?.profileImage}
                    />
                    {/* <span className="badge badge-warning m-2">Clock Out</span>{" "} */}
                  </div>

                  <div className="ml-2 d-none d-xl-block">
                    <span className="text-dark medium">
                      {/* <b>{userData?.name}</b> */}
                    </span>
                    <br />
                    <div>
                      {/* <span className="badge badge-warning">Clock Out</span>{" "} */}
                      {/* <small>Clocked In at 12:33pm</small> */}
                      {/* <small>
                        Clocked In at{" "}
                        {formatTimestamp(userData?.loginInAt, "h:mm a")}
                      </small> */}
                    </div>
                  </div>
                </div>
              </li>
              {/* User profile and search */}
              <li className="nav-item dropdown ml-1 d-none d-xl-block">
                <span>{formatTimestamp(date, "dddd, MMM D, yyyy h:mma")}</span>

                <br />

                <div className="input-group user-status">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Status</span>
                  </div>
                  <select
                    class="form-select border-0 input-group-text z-3 text-left"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    value={selectedStatus}
                  >
                    <option value="Available" selected>
                      <span>🟢 Available</span>
                    </option>
                    <option value="Away">🟡 Away</option>
                    {/* <option value="Busy">🔴 Busy</option> */}
                  </select>
                </div>
              </li>
              {selectedStatus === "Available" ? (
                <li className="nav-item dropdown ml-3">
                  <br />
                  <span>Lead Status </span>{" "}
                  {currentLeadStatus === "Not Login" ? (
                    <button
                      onClick={getLoginUrlViCiDial}
                      className="btn btn-danger btn-sm "
                    >
                      Click to Connect
                    </button>
                  ) : currentLeadStatus === "PAUSED" ? (
                    <button
                      onClick={() => setViciDialStatus("RESUME")}
                      className="btn btn-warning btn-sm "
                    >
                      Click to Active
                    </button>
                  ) : (
                    <button
                      onClick={() => setViciDialStatus("PAUSE")}
                      className="btn btn-success btn-sm "
                    >
                      Click to PAUSE
                    </button>
                  )}
                </li>
              ) : (
                <>
                  <li className="nav-item dropdown ml-3">
                    <br />
                    <span>Lead Status </span>{" "}
                    <button className="btn btn-secondary btn-sm" disabled>
                      Away
                    </button>
                  </li>
                </>
              )}
            </ul>

            <ul className="navbar-nav float-right border-topnotify">
              {/* Email */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle pl-md-3 position-relative extra-margin navHoverPointer"
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="notify-lebel">
                    <span>
                      <Mail className="svg-icon" />
                    </span>
                    <span className="badge badge-primary notify-no rounded-circle">
                      2
                    </span>
                  </div>
                  <div className="notify-lebelbtm">
                    <span className="small">
                      Unanswered
                      <br />
                      Email
                    </span>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <div className="btn btn-danger rounded-circle btn-circle">
                            <Airplay className="text-white" />
                          </div>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Luanch Admin
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my new admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:30 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-success text-white rounded-circle btn-circle">
                            <Calendar className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Event today
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              Just a reminder that you have event
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:10 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-info rounded-circle btn-circle">
                            <Settings className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Settings
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              You can customize this template as you want
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:08 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle">
                            <Box className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="nav-link pt-3 text-center text-dark"
                        to="#javascript:void(0);"
                      >
                        <strong>Check all mails</strong>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {/* End Email */}
              {/* Chat */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle pl-md-3 position-relative extra-margin navHoverPointer"
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="notify-lebel">
                    <span>
                      <MessageCircle className="svg-icon" />
                    </span>
                    <span className="badge badge-success notify-no rounded-circle">
                      7
                    </span>
                  </div>
                  <div className="notify-lebelbtm">
                    <span className="small">
                      Unanswered
                      <br />
                      Chats
                    </span>
                  </div>
                </Link>

                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <div className="btn btn-danger rounded-circle btn-circle">
                            <Airplay className="text-white" />
                          </div>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Luanch Admin
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my new admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:30 AM
                            </span>
                          </div>
                        </Link>

                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-success text-white rounded-circle btn-circle">
                            <Calendar className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Event today
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              Just a reminder that you have event
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:10 AM
                            </span>
                          </div>
                        </Link>

                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-info rounded-circle btn-circle">
                            <Settings className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Settings
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              You can customize this template as you want
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:08 AM
                            </span>
                          </div>
                        </Link>

                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle">
                            <Box className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="nav-link pt-3 text-center text-dark"
                        to="#javascript:void(0);"
                      >
                        <strong>Check all mails</strong>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle pl-md-3 position-relative extra-margin navHoverPointer"
                  to="#"
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="notify-lebel">
                    <span>
                      <Voicemail className="svg-icon" />
                    </span>
                    <span className="badge badge-danger notify-no rounded-circle">
                      5
                    </span>
                  </div>
                  <div className="notify-lebelbtm">
                    <span className="small">
                      Unanswered
                      <br />
                      Voicemails
                    </span>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <div className="btn btn-danger rounded-circle btn-circle">
                            <Airplay className="text-white" />
                          </div>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Luanch Admin
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my new admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:30 AM
                            </span>
                          </div>
                        </Link>

                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-success text-white rounded-circle btn-circle">
                            <Calendar className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Event today
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              Just a reminder that you have event
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:10 AM
                            </span>
                          </div>
                        </Link>

                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-info rounded-circle btn-circle">
                            <Settings className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Settings
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              You can customize this template as you want
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:08 AM
                            </span>
                          </div>
                        </Link>

                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle">
                            <Box className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="nav-link pt-3 text-center text-dark"
                        to="#javascript:void(0);"
                      >
                        <strong>Check all mails</strong>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle pl-md-3 position-relative extra-margin navHoverPointer"
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="notify-lebel">
                    <span>
                      <MessageSquare className="svg-icon" />
                    </span>
                    <span className="badge badge-warning notify-no rounded-circle">
                      7
                    </span>
                  </div>
                  <div className="notify-lebelbtm">
                    <span className="small">
                      Unanswered
                      <br />
                      Text
                    </span>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <div className="btn btn-danger rounded-circle btn-circle">
                            <Airplay className="text-white" />
                          </div>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Luanch Admin
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my new admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:30 AM
                            </span>
                          </div>
                        </Link>

                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-success text-white rounded-circle btn-circle">
                            <Calendar className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Event today
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              Just a reminder that you have event
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:10 AM
                            </span>
                          </div>
                        </Link>

                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-info rounded-circle btn-circle">
                            <Settings className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Settings
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              You can customize this template as you want
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:08 AM
                            </span>
                          </div>
                        </Link>

                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle">
                            <Box className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="nav-link pt-3 text-center text-dark"
                        to="#javascript:void(0);"
                      >
                        <strong>Check all mails</strong>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item dropdown navHoverPointer">
                <Link
                  className="nav-link dropdown-toggle pl-md-3 position-relative extra-margin "
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="notify-lebel">
                    <span>
                      <Phone class="svg-icon" />
                    </span>
                    <span className="badge badge-secondary rounded-circle">
                      7
                    </span>
                  </div>
                  <div className="notify-lebelbtm">
                    <span className="small">
                      Missed
                      <br />
                      Calls
                    </span>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <div className="btn btn-danger rounded-circle btn-circle">
                            <Airplay className="text-white" />
                          </div>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Luanch Admin
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my new admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:30 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-success text-white rounded-circle btn-circle">
                            <Calendar className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Event today
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              Just a reminder that you have event
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:10 AM
                            </span>
                          </div>
                        </Link>

                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-info rounded-circle btn-circle">
                            <i data-feather="settings" />
                            <Settings className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Settings
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              You can customize this template as you want
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:08 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle">
                            <Box className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="nav-link pt-3 text-center text-dark"
                        to="#javascript:void(0);"
                      >
                        <strong>Check all calls</strong>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item d-none d-xl-block">
                <div className="nav-link">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearch();
                    }}
                  >
                    <div className="customize-input" style={{ width: "200px" }}>
                      <input
                        className="form-control custom-shadow custom-radius bg-white black-border"
                        type="search"
                        maxLength={256}
                        placeholder="Google Search"
                        aria-label="Search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        style={{ color: "black" }}
                        // onKeyDown={(e) => {
                        //   if (e.key === "Enter") {
                        //     handleSearch();
                        //   }
                        // }}
                      />
                      <Search
                        onClick={handleSearch}
                        className="form-control-icon"
                      />
                    </div>
                  </form>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;

{
  /* {isIframeVisible && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: semi-transparent background
            zIndex: 9999,
          }}
        >
          <iframe
            src="http://3.238.21.230/agc/vicidial.php"
            title="VICI DIAL"
            width="100%"
            height="100%"
            style={{
              border: "none",
              height: "100vh", // Full viewport height
              width: "100%", // Full width
            }}
          ></iframe>
        </div>
      )} */
}

// {isModalOpen && (
//   <div
//     style={{
//       position: "fixed",
//       top: "0",
//       left: "0",
//       right: "0",
//       bottom: "0",
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       zIndex: 9999,
//     }}
//   >
//     <div
//       style={{
//         backgroundColor: "white",
//         padding: "20px",
//         width: "80%",
//         height: "80%",
//         borderRadius: "8px",
//         position: "relative",
//         overflow: "hidden",
//       }}
//     >
//       {/* Close Button */}
//       <button
//         onClick={closeModal}
//         style={{
//           position: "absolute",
//           top: "10px",
//           right: "10px",
//           backgroundColor: "transparent",
//           border: "none",
//           fontSize: "18px",
//           cursor: "pointer",
//         }}
//       >
//         ×
//       </button>

//       {/* Iframe to load content */}
//       <iframe
//         src="http://3.238.21.230/agc/vicidial.php"
//         title="VICI DIAL"
//         width="100%"
//         height="100%"
//         style={{
//           border: "none",
//           borderRadius: "8px",
//           overflow: "hidden",
//         }}
//       ></iframe>
//     </div>
//   </div>
// )}

// <div className="form-group d-flex align-items-center">
// <label
//   htmlFor="title"
//   className="col-md-2 col-form-label"
// >
//   Title
// </label>
// <div className="col-md-4">
//   <input
//     type="text"
//     maxLength={256}
//     required
//     className={`form-control black-border ${
//       errors.title ? "border border-danger" : ""
//     }`}
//     id="title"
//     {...register("title")}
//   />
//   {errors.title && (
//     <p className="text-danger">
//       {errors.title.message}
//     </p>
//   )}
// </div>
// </div>
