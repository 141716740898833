import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import { useForm } from "react-hook-form";
import AddStatesModal from "./AddStatesModal";

const HierarchyProductsStates = forwardRef((props, ref) => {
  const [currentSelectedState, setCurrentSelectedState] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      productsOffered: {},
    },
  });

  useImperativeHandle(ref, () => ({
    handleSubmit: (param) =>
      handleSubmit((formData) => {
        props.submitForm({ productsOffered: formData.productsOffered }, param);
      })(),
  }));

  useEffect(() => {
    if (props.data) {
      const updatedValues = props.data.productsOffered.reduce(
        (acc, product) => {
          acc.productsOffered[product.name] = product.states;
          return acc;
        },
        { productsOffered: {} }
      );

      reset(updatedValues); // Reset the form with new values
    }
  }, [props.data, reset]);
  const submmit = (selectedValues) => {
    const productsOffered = [];
    for (let index = 0; index < props.data?.productsOffered.length; index++) {
      const element = props.data?.productsOffered[index];
      if (element.name === currentProduct) {
        productsOffered.push({
          ...element,
          states: Object.keys(selectedValues).filter(
            (state) => selectedValues[state]
          ),
        });
      } else {
        productsOffered.push(element);
      }
    }
    setCurrentProduct(null);
    setCurrentSelectedState(null);
    props.setData({ ...props.data, productsOffered: productsOffered });
  };

  return (
    <div className="my-5 w-100">
      <div className="table-responsive">
        <table
          id="default_order"
          className="table table-striped display no-wrap w-100"
        >
          <thead>
            <tr>
              <th className="h5 font-weight-bold">Selected Products</th>
              <th className="h5 font-weight-bold">States</th>
            </tr>
          </thead>
          <tbody>
            {props.data?.productsOffered.map((product) => (
              <tr key={product?.name}>
                <td>{product?.name}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target="#addStates"
                    onClick={() => {
                      setCurrentSelectedState(product?.states);
                      setCurrentProduct(product?.name);
                    }}
                  >
                    <i data-feather="plus" className="feather-icon mr-1" />
                    {product?.states.length > 0 ? "View States" : "Add States"}
                  </button>
                  <input
                    type="hidden"
                    {...register(`productsOffered.${product.name}`)}
                    value={product.states}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AddStatesModal
        submit={submmit}
        currentSelectedState={currentSelectedState}
        currentProduct={currentProduct}
      />
    </div>
  );
});

export default HierarchyProductsStates;
