import React, { useContext, useEffect, useState } from "react";
import DialPad from "./DialPad";
import CallHistory from "./CallHistory";
import Contact from "./Contact";
import { Plus } from "react-feather";
import useApis from "../../apis/useApis";
import appConstant from "./../../json/appConstant.json";
import { Controller, useForm } from "react-hook-form";
import ModelUi from "../model/ModelUi";
import {
  validateName,
  validatePhoneNumberWithCountryCode,
} from "../validation/Validation";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import DragsAndDrop from "../../image/DragsAndDrop";
import { CallCenterContext } from "../../context/CallCenterContext";
import { useNavigate } from "react-router-dom";

const PhoneCall = ({ selectedValue, handleSelectChange }) => {
  document.title = "Phone Call - Digital Call Center";
  const { jsonApi, mediaApi } = useApis();
  const [contact, setContact] = useState([]);
  const [contactTotal, setContactTotal] = useState(0);
  const [contactPage, setContactPage] = useState(1);
  const [contactLimit, setContactLimit] = useState(10);
  const [history, setHistory] = useState([]);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [historyPage, setHistoryPage] = useState(1);
  const [historyLimit, setHistoryLimit] = useState(10);
  const [searchHistory, setSearchHistory] = useState("");
  const [searchContact, setSearchContact] = useState("");

  const [logoPreview, setLogoPreview] = useState(null);
  const navigate = useNavigate();

  const {
    callCenterContextCount,
    makeCall,
    picked,
    setPicked,
    handleEndCall,
    inCall,
    callDuration,
    dddd,
    twilioCallSid,
    toggleMute,
    toggleHold,
    error,
  } = useContext(CallCenterContext);

  const fetchContact = async () => {
    try {
      const contactRes = await jsonApi(
        appConstant.getContact,
        {
          page: contactPage,
          limit: contactLimit,
          search: searchContact,
          type: "Call",
        },
        null,
        true
      );
      if (!contactRes.error) {
        setContact(contactRes.data.data);
        setContactTotal(contactRes.data.total);
      } else {
        console.error(contactRes.message);
      }
    } catch (err) {
      console.error("Error fetching contacts:", err);
    }
  };

  const fetchHistory = async () => {
    const historyRes = await jsonApi(
      appConstant.getCallHistory,
      { page: historyPage, limit: historyLimit, search: searchHistory },
      null,
      false
    );
    if (!historyRes.error) {
      setHistory(historyRes.data.data);
      setHistoryTotal(historyRes.data.total);
    } else {
      console.error(historyRes.message);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, [searchHistory, historyPage, historyLimit, callCenterContextCount]);

  useEffect(() => {
    fetchContact();
  }, [searchContact, contactPage, contactLimit, callCenterContextCount]);

  const {
    control,
    setValue,
    clearErrors,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("type", "Call");
    if (data.logo) {
      formData.append("logo", data.logo); // data.logo is a FileList, get the first file
    }
    const response = await mediaApi(
      appConstant.createContact,
      null,
      formData,
      null
    ); // Pass token to API call
    if (!response.error) {
      reset();
      createContactModelHandleClose();
      await fetchContact();
    } else {
    }
  };

  // const handleEndCall = async () => {
  //   try {
  //     const seId = resetCallSessionId();
  //     addDeviceListeners(seId);
  //     call.disconnect();
  //     await init();
  //   } catch (e) {
  //     console.error("Error ending call:", e);
  //   }
  // };

  const [createContactModel, setCreateContactModel] = useState(false);

  const createContactModelHandleClose = async () => {
    reset();
    setCreateContactModel(false);
  };

  const createContactModelHandleOpen = async () => {
    setCreateContactModel(true);
  };

  const getMoreHistory = () => {
    setHistoryLimit((pre) => pre + 5);
  };

  const getMoreContact = () => {
    setContactLimit((pre) => pre + 5);
  };

  const [isDisabled, setIsDisabled] = useState(false);

  // Watch the phone input field
  const phoneValue = watch("phone", ""); // Provide default empty string to avoid undefined issues

  useEffect(() => {
    // Disable the input when the phone number is exactly 13 digits long and starts with +
    if (phoneValue && /^\+\d{13}$/.test(phoneValue)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [phoneValue]);

  const convertToConference = async () => {
    // convertToConference
    const contactRes = await jsonApi(
      appConstant.convertToConference,
      {},
      { twilioCallSid },
      true
    );
  };

  return (
    <>
      <>
        <div className="container-fluid">
          <div className="row">
            <h3 className="page-title text-dark font-weight-medium mt-2 ml-3">
              Phone Call
            </h3>
              <div className="row ml-4">
                {error ? (
                  <div className="alert alert-danger">{error}</div>
                ) : (
                  <></>
                )}
              </div>
            <div className="col-xl-11">
              {/* <div className="card mb-3">
              </div> */}
             
              <div className="row">
                <DialPad
                  dddd={dddd}
                  makeCall={makeCall}
                  picked={picked}
                  setPicked={setPicked}
                  handleEndCall={handleEndCall}
                  inCall={inCall}
                  callDuration={callDuration}
                  history={history}
                  convertToConference={convertToConference}
                  toggleMute={toggleMute}
                  toggleHold={toggleHold}
                />
                <CallHistory
                  makeCall={makeCall}
                  picked={picked}
                  setPicked={setPicked}
                  history={history}
                  setSearchHistory={setSearchHistory}
                  total={historyTotal}
                  getMoreHistory={getMoreHistory}
                  fetchHistory={fetchHistory}
                  limit={historyLimit}
                />
                {/* <Contact
                    contact={contact}
                    makeCall={makeCall}
                    picked={picked}
                    setPicked={setPicked}
                    setSearchContact={setSearchContact}
                    total={contactTotal}
                    getMoreContact={getMoreContact}
                    fetchContact={fetchContact}
                    limit={contactLimit}
                  /> */}
              </div>
            </div>
            <div className="col-xl-1 d-none d-xl-block">
              <img
                src="/assets/images/banner/ads.jpg"
                className="mx-auto d-block img-fluid"
                title="Ads"
                alt=""
              />
            </div>
          </div>
        </div>
      </>
      {/* <a
          onClick={createContactModelHandleOpen}
          className="add_floating_btn badge-primary"
        >
          <Plus className="feather-icon" />
        </a> */}
      <ModelUi
        show={createContactModel}
        handleClose={createContactModelHandleClose}
        title="Create Contact"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="row">
            <div className="col-12 mb-3">
              <label>
                Name <span className="text-danger">*</span>
              </label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Name"
                    maxLength={256}
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    {...register("name", {
                      required: "Name is required",
                      validate: (value) => validateName(value, "name"),
                    })}
                  />
                )}
              />
              {errors.name && (
                <p className="text-danger">{errors.name.message}</p>
              )}
            </div>

            <div>
              <div className="col-12 mb-3">
                <label>
                  Phone <span className="text-danger">*</span>
                </label>

                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    validate: validatePhoneNumberWithCountryCode,
                  }}
                  render={({ field }) => (
                    <>
                      <PhoneInput
                        preferredCountries={"us"}
                        className={`w-100 black-border${
                          errors.phone ? "is-invalid" : ""
                        }`}
                        value={field.value}
                        onChange={(phone) => field.onChange(phone)}
                        onBlur={field.onBlur}
                        disableDialCodeAndPrefix={true}
                        disableFocusAfterCountrySelect={false}
                        hideDropdown={true}
                        disableCountryGuess={true}
                      />
                    </>
                  )}
                />
                {errors.phone && (
                  <p className="text-danger">{errors.phone.message}</p>
                )}
              </div>
            </div>

            <div className="col-12 mb-3">
              <label>
                Logo <span className="text-danger"></span>
              </label>
              <div className="d-flex justify-content-center">
                <DragsAndDrop
                  heading="Upload Image"
                  inputName="Image"
                  aspect={1 / 1}
                  uploadFile={(x) => {
                    setValue("logo", x);
                    clearErrors("logo");
                  }}
                />
              </div>

              {errors.logo && (
                <p className="text-danger">{errors.logo.message}</p>
              )}
              {logoPreview && (
                <div className="mt-2">
                  <img
                    src={logoPreview}
                    alt="Logo Preview"
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Create
            </button>
          </div>
        </form>
      </ModelUi>
    </>
  );
};

export default PhoneCall;
