import React, { useState, useEffect } from "react";

const MedicaidEligibilityLevel = ({
  fieldName,
  fieldShowName,
  register,
  errors,
}) => {

  return (
    <div>
      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <h5 className="text-dark font-weight-medium">{fieldShowName}</h5>
        </div>
        <div className="col-5">
          <select
            className={`form-control black-border ${
              errors[fieldName] ? "border border-danger" : ""
            }`}
            id={fieldName}
            {...register(fieldName, {
              required: false,
            })}
            defaultValue="Does Not Qualify for Medicaid"
          >
            <option value="Does Not Qualify for Medicaid">
              Does Not Qualify for Medicaid
            </option>{" "}
            <option value="QMB - Qualified Medicare Beneficiaries “partial-benefit”">
              QMB - Qualified Medicare Beneficiaries “partial-benefit”
            </option>{" "}
            <option value="QMB+ - Qualified Medicare Beneficiaries “full-benefit”">
              QMB+ - Qualified Medicare Beneficiaries “full-benefit”
            </option>{" "}
            <option value="QI - Qualifying Individuals">QI - Qualifying Individuals</option>{" "}
            <option value="SLMB - Specified Low-Income Medicare Beneficiaries
              “partial-benefit”">
              SLMB - Specified Low-Income Medicare Beneficiaries
              “partial-benefit”
            </option>{" "}
            <option value="SLMB+ - Specified Low-Income Medicare Beneficiaries “full-benefit">
              SLMB+ - Specified Low-Income Medicare Beneficiaries “full-benefit”
            </option>{" "}
            <option value="QWDI - Qualified Disabled and Working Individuals">
              QWDI - Qualified Disabled and Working Individuals
            </option>
          </select>
          {errors[fieldName] && (
            <p className="text-danger">{errors[fieldName].message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MedicaidEligibilityLevel;
