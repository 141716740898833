import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CallCenterContext } from "../../context/CallCenterContext";

function HoverableCorporateNumber({ phone}) {

  const navigate = useNavigate();
  const { makeCall, inCall } = useContext(CallCenterContext);

  const convertNumber = (dd) => {
    // if (!dd || typeof dd !== 'string') {
    //   return dd; // Return as is if undefined or not a string
    // }
  
    if (dd.startsWith("+") && !dd.startsWith("+1")) {
      return dd; 
    }
  
    let number = dd.includes("+1") ? dd.split("+1")[1] : dd;
  
    number = number.replace(/\D/g, "");
  
    if (number.length === 10) {
      return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
    } else {
      return dd;
    }
  };
  
  const displayNumber = phone ? convertNumber(phone) : "No data";

return (
    inCall ? (
      <div title="Another call in progress">{displayNumber}</div>
    ) : (
      <div
        onClick={() => {
          makeCall(phone);
          navigate("/phone/call");
        }}
        style={{ cursor: "pointer" }}
        title="Click to call"
        className="hoverable-number"
      >
       {displayNumber}
      </div>
    )
)
}


export default HoverableCorporateNumber;
