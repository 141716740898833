import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect } from "react";
import useApis from "../../apis/useApis";

import {
  validateEmail,
  validateName,
  validateSuiteNumber,
  validateZipCode,
} from "./../validation/Validation";
import { PhoneInput } from "react-international-phone";
import DatePicker from "react-multi-date-picker";

const ViciDialLeadForm = ({ data, setShowModal }) => {
  const { jsonApi } = useApis();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (dd) => {
    console.log(dd);
    setShowModal(false);
  };

  const populateForm = (data) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(`${key}`, data[key]);
      });
    }
  };
  useEffect(() => {
    populateForm(data);
  }, []);

  return (
    <>
      <div className="container">
        <div className="w-100">
          <div className="row">
            <div className="col-12 mb-3"></div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex">
                    <div className="col-md-2">
                      <div className="form-group row">
                        <label htmlFor="title" className="col-form-label">
                          Title
                        </label>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control black-border"
                            id="title"
                            maxLength={4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group row">
                        <label htmlFor="firstName" className="col-form-label">
                          First
                        </label>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control black-border w-100"
                            id="first_name"
                            maxLength={30}
                            {...register("first_name", {
                              validate: (value) =>
                                validateName(value, "First Name"),
                            })}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="form-group row">
                        <label
                          htmlFor="middle_initial"
                          className="col-form-label"
                        >
                          Middle Initial
                        </label>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control black-border"
                            id="middle_initial"
                            maxLength={1}
                            {...register("middle_initial", {})}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group row">
                        <label htmlFor="last_name" className="col-form-label">
                          Last
                        </label>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control black-border  w-100"
                            id="last_name"
                            maxLength={30}
                            {...register("last_name", {
                              validate: (value) => validateName(value, "Title"),
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="address1"
                      className="col-sm-3 col-form-label"
                    >
                      Address1
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control black-border"
                        id="address1"
                        maxLength={100}
                        {...register("address1")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="address2"
                      className="col-sm-3 col-form-label"
                    >
                      Address2
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control black-border"
                        id="address2"
                        maxLength={100}
                        {...register("address2")}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="address3"
                      className="col-sm-3 col-form-label"
                    >
                      Address3
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control black-border"
                        id="address3"
                        maxLength={100}
                        {...register("address3")}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label htmlFor="city" className="col-sm-3 col-form-label">
                      City
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control black-border"
                        id="city"
                        maxLength={50}
                        {...register("city", {
                          validate: (value) => validateName(value, "city"),
                        })}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label htmlFor="state" className="col-sm-3 col-form-label">
                      State
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control black-border"
                        id="state"
                        maxLength={2}
                        {...register("state", {
                          validate: (value) =>
                            value.length === 2 ||
                            "State must be exactly 2 characters",
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="postal_code"
                      className="col-sm-3 col-form-label"
                    >
                      Postal Code
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control black-border"
                        id="postal_code"
                        maxLength={10}
                        {...register("postal_code", {
                          validate: (value) =>
                            /^[0-9]{1,10}$/.test(value) ||
                            "Postal code must be a number",
                        })}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="country_code"
                      className="col-sm-3 col-form-label"
                    >
                      Country Code
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control black-border"
                        id="country_code"
                        maxLength={3}
                        {...register("country_code", {})}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row ">
                    <label
                      htmlFor="date_of_birth"
                      className="col-sm-3 col-form-label"
                    >
                      Date of Birth
                    </label>
                    <div className="col-sm-8">
                      <Controller
                        control={control}
                        name="date_of_birth"
                        defaultValue={""}
                        render={({ field }) => (
                          <DatePicker
                            value={field.value}
                            onChange={field.onChange}
                            format="YYYY/MM/DD"
                            className="form-control custom-date-picker"
                            calendarPosition="bottom-center"
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            maxDate={new Date()}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label htmlFor="phone" className="col-sm-3 col-form-label">
                      Phone
                    </label>
                    <div className="col-sm-8">
                      <Controller
                        name="phone_number"
                        control={control}
                        render={({ field }) => (
                          <>
                            <PhoneInput
                              preferredCountries={"us"}
                              className={`black-border w-100 ${
                                errors.phone_number ? "is-invalid" : ""
                              }`}
                              value={field.value}
                              onChange={(phone_number) =>
                                field.onChange(phone_number)
                              }
                              onBlur={field.onBlur}
                              inputProps={{ required: true }}
                              disableDialCodeAndPrefix={true}
                              disableFocusAfterCountrySelect={false}
                              hideDropdown={true}
                            />
                            {errors.phone_number && (
                              <p className="text-danger">
                                {errors.phone_number.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label htmlFor="phone" className="col-sm-3 col-form-label">
                      Alt. Phone
                    </label>
                    <div className="col-sm-8">
                      <Controller
                        name="multi_alt_phones"
                        control={control}
                        render={({ field }) => (
                          <>
                            <PhoneInput
                              preferredCountries={"us"}
                              className={`black-border w-100 ${
                                errors.multi_alt_phones ? "is-invalid" : ""
                              }`}
                              value={field.value}
                              onChange={(multi_alt_phones) =>
                                field.onChange(multi_alt_phones)
                              }
                              onBlur={field.onBlur}
                              disableDialCodeAndPrefix={true}
                              disableFocusAfterCountrySelect={false}
                              hideDropdown={true}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label htmlFor="email" className="col-sm-3 col-form-label">
                      Email
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="email"
                        className="form-control black-border"
                        id="email"
                        maxLength={70}
                        {...register("email", { validate: validateEmail })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-3 offset-sm-5">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViciDialLeadForm;
