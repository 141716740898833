import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { validateName, validateDescription } from "../validation/Validation";
import { useNavigate, useParams } from "react-router-dom";

const AddTask = () => {
  document.title = "Task - Digital Call Center";
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  console.log(id);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const dataWithType = {
      id,
      ...data,
    };
    const response = await jsonApi(
      appConstant.createTask,
      null,
      dataWithType,
      false,
      true
    );
    if (!response.error) {
      reset({
        taskName: "",
        taskDescription: "",
      });
      setError("");
      navigate(`/contact/list/tasks/${id}`);
    }
    setTimeout(() => setIsSubmitting(false), 1000);
  };

  return (
    <>

      <div className="container-fluid">
        <div className="row">
        <div className="col-xl-11">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <h3 className="page-title text-dark font-weight-medium mt-2 ml-2">
              Add New Task
            </h3>
            <div className="col-6 d-flex justify-content-end ">
              <button
                data-toggle="modal"
                data-target="#personalContact"
                className="page-link me-2 border border-primary"
                onClick={() => navigate(`/contact/list/tasks/${id}`)}
              >
                Show List Of Tasks
              </button>
            </div>
          </div>
          </div>

          <div className="col-xl-11">
            <div className="card">
              <div className="card-body custom-field-button">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}

                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Task Name
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="taskName"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            maxLength={256}
                            placeholder="Task Name"
                            className={`form-control black-border ${
                              errors.taskName ? "is-invalid" : ""
                            }`}
                            {...register("taskName", {
                              required: "Task Name is required",
                              validate: (value) =>
                                validateName(value, "task name"),
                            })}
                          />
                        )}
                      />
                      {errors.taskName && (
                        <p className="text-danger">{errors.taskName.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Task Description
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="taskDescription"
                        control={control}
                        render={({ field }) => (
                          <>
                            <textarea
                              rows="4"
                              maxLength={1024}
                              placeholder="Task Description"
                              className={`form-control black-border ${
                                errors.taskDescription ? "is-invalid" : ""
                              }`}
                              {...register("taskDescription", {
                                required: "Task Description is required",
                                validate: (value) =>
                                  validateDescription(value) ||
                                  "Invalid description",
                              })}
                            />
                            <div className="text-muted mt-1">
                              {field.value ? field.value.length : 0} / 1024
                            </div>
                          </>
                        )}
                      />
                      {errors.taskDescription && (
                        <p className="text-danger">
                          {errors.taskDescription.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-2 col-md-3 col-sm-4">&nbsp;</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-rounded px-4"
                      >
                        Create Task
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
              alt="ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTask;
