import React, { useState, useContext } from "react";
import useApis from "../../../apis/useApis";
import appConstant from "../../../json/appConstant.json";
import { AlertCircle, ChevronLeft, Edit2, Upload } from "react-feather";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/Context";

const AddLeadsCall = () => {
  document.title="Leads Call - Digital Call Center";
  const { jsonApi } = useApis();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [leadCount, setLeadCount] = useState(0);
  const [finalData, setFinalData] = useState([]);
  const { openErrorSnackbar } = useContext(Context);
  const [headers, setHeaders] = useState([]);
  const [mapping, setMapping] = useState({});
  const [rawData, setRawData] = useState([]);
  const [step, setStep] = useState(1);
  const [list, setList] = useState("");
  const [isMappingComplete, setIsMappingComplete] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [uniqueLeads, setUniqueLeads] = useState([]);
  const [duplicateLeads, setDuplicateLeads] = useState([]);
  const [listName, setListName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [sourceId, setSourceId] = useState();
  const [vendorLeadCode, setVendorLeadCode] = useState();
  const [source, setSource] = useState();

  const expectedTitles = [
    { key: "first_name", title: "First Name" },
    { key: "middle_initial", title: "Middle Initial" },
    { key: "last_name", title: "Last Name" },
    { key: "phone_number", title: "Phone Number" },
    { key: "email", title: "Email" },
    { key: "date_of_birth", title: "Date Of Birth" },
    { key: "address1", title: "Address1" },
    { key: "address2", title: "Address2" },
    { key: "address3", title: "Address3" },
    { key: "city", title: "City" },
    { key: "state", title: "State" },
    { key: "province", title: "Province" },
    { key: "postal_code", title: "Postal Code" },
    { key: "country_code", title: "Country Code" },
    { key: "gender", title: "Gender" },
    { key: "alt_phone", title: "Alt Phone" },
    // { key: "source", title: "Source" },
    // { key: "vendor_lead_code", title: "Vendor Lead Code" },
    // { key: "source_id", title: "Source Id" },
    // { key: "phone_code", title: "Dial Code" },
  ];

  const navigate = useNavigate();
  const convertCSVtoObjectHandler = (e) => {
    const files = e.target.files;
    console.log(files[0].name);
    setError(null);
    setLeadCount(0);

    if (files) {
      const file = files[0];
      setSelectedFileName(file.name);
      setListName(file.name);
      const fileName = file.name.toLowerCase();
      const fileType = file.type;

      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".csv")) {
        setError("Please upload only .xlsx or .csv files");
        return;
      }

      if (fileName.endsWith(".csv")) {
        Papa.parse(file, {
          complete: function (results) {
            setHeaders(results.data[0]);
            const data = results.data.slice(1);
            const data2 = [];
            data.map((item) => (item.length > 1 ? data2.push(item) : null));

            setRawData(data2);
            console.log("CSV data2:", data2[2]);
            setLeadCount(data2.length);
          },
        });
      } else if (fileName.endsWith(".xlsx")) {
        const reader = new FileReader();
        reader.onload = (event) => {
          try {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            setHeaders(jsonData[0]);
            const data = jsonData.slice(1);
            console.log("Excel Data:", data);
            setLeadCount(data.length);
            setRawData(data);
          } catch (error) {
            setError("Error processing Excel file");
          }
        };
        reader.readAsBinaryString(file);
      }
    }
    e.target.value = null; // Reset the file input field to avoid selecting the same file again
  };

  const handleMappingChange = (header, value) => {
    setMapping({ ...mapping, [header]: value });
  };

  const handleNext1 = () => {
    setStep(2);
  };

  const handleBack = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
    setIsSaveEnabled(true);
    setIsNextEnabled(false);
  };

  const handleMappingComplete = async () => {
    setIsMappingComplete(false);

    if (!mapping["phone_number"]) {
      openErrorSnackbar("Map Phone Number field!");
      setError(true);
      return;
    }
    if (!mapping["first_name"]) {
      openErrorSnackbar("Map Name field!");
      setError(true);
      return;
    }

    const finalDatas = rawData.map((element) => {
      const result = {};
      for (const key in mapping) {
        // Directly access the mapping without hasOwnProperty
        console.log(key, mapping[key]);
        // phone_number 2
        result[key] = element[mapping[key]];
      }
      return result;
    });

    setFinalData(finalDatas);

    if (!finalDatas.length) {
      setError("No valid data to upload");
      return;
    }

    setError(null);

    const response = await jsonApi(
      appConstant.previewLeads,
      {},
      { leads: finalDatas },
      null,
      null
    );
    if (response.error) {
      openErrorSnackbar(response.message || "Error checking leads preview");
      return;
    }
    setUniqueLeads(response.data?.uniqueLeads || []);
    setDuplicateLeads(response.data?.duplicateLeads || []);

    // Check for duplicate numbers
    if (response.data?.duplicateLeads?.length > 0) {
      openErrorSnackbar(
        `Duplicate numbers found: ${response.data?.duplicateLeads
          .map((lead) => lead.phone_number)
          .join(", ")}. These numbers cannot be added.`
      );
    }
    setIsMappingComplete(true);
    setIsSaveEnabled(false);
    setIsNextEnabled(true);
    setStep(3);
  };

  const submitHandler = async () => {
    const updatedLeads = uniqueLeads.map((lead) => ({
      ...lead,
      vendor_lead_code: vendorLeadCode,
      source_id: sourceId,
      source: source,
    }));

    const response = await jsonApi(
      appConstant.addMultipleLeads,
      {},
      { uniqueLeads: updatedLeads, listName },
      false,
      true
    );
    setLeadCount({});
    if (!response.error) {
      setFile(null);
      setSelectedFileName("");
      setList("");
      setFinalData([]);
      setMapping({});
      setRawData([]);
      setLoading(false);
      navigate("/contact/lead/campaigns/list");
      setTimeout(() => {
        setIsSaveEnabled(true);
        setIsNextEnabled(false);
      }, 3000);
    } else {
      setError(response.message || "Failed to upload leads");
      setLoading(false);
    }
  };

  const handlelistNameChange = (e) => {
    setListName(e.target.value);
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card shadow-sm">
            <div className="card-body">
              <h4 className="card-title mb-4">Upload Multiple Leads</h4>
              {step !== 1 ? (
                <div className="d-flex align-items-center ">
                  <button
                    onClick={handleBack}
                    style={{
                      border: "none",
                      background: "transparent",
                      cursor: "pointer",
                    }}
                    className="p-2"
                  >
                    <ChevronLeft size={24} /> Back
                  </button>
                </div>
              ) : (
                <></>
              )}
              {step === 1 && (
                <div className="p-4 border rounded-3 bg-light">
                  <div
                    className="text-center mb-4"
                    onClick={() =>
                      document.getElementById("file-input").click()
                    }
                  >
                    <Upload size={48} className="text-primary mb-3" />
                    <h5>Upload CSV File</h5>
                    <p className="text-muted">
                      Upload your Excel (.xlsx) or CSV file containing multiple
                      leads
                    </p>
                  </div>

                  <div className="mb-4">
                    <div
                      className="input-group mb-3 "
                      style={{ display: "none" }}
                    >
                      <input
                        type="file"
                        id="file-input"
                        className="form-control"
                        accept=".xlsx,.csv"
                        onChange={convertCSVtoObjectHandler}
                        style={{ display: "none" }}
                        disabled={loading}
                      />
                      <input
                        type="text"
                        className="form-control black-border bg-white"
                        placeholder="No file chosen"
                        value={selectedFileName}
                        readOnly
                        onClick={() =>
                          document.getElementById("file-input").click()
                        }
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={() =>
                          document.getElementById("file-input").click()
                        }
                      >
                        Choose File
                      </button>
                    </div>
                    {/* {error && (
                      <div className="alert alert-danger d-flex align-items-center mt-3">
                        <AlertCircle size={18} className="me-2" />
                        {error}
                      </div>
                    )} */}
                  </div>
                  {selectedFileName ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <span
                        style={{ color: "#fff", fontSize: "1.5rem" }}
                        className="badge rounded-pill bg-primary mb-2"
                      >
                        {selectedFileName}
                        <button
                          type="button"
                          style={{ color: "#fff" }}
                          class="btn-close bg-danger m-2"
                          aria-label="Close"
                          onClick={() => {
                            setSelectedFileName("");
                            setHeaders([]);
                            setRawData([]);
                            setLeadCount(0);
                          }}
                        >
                          X
                        </button>
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}

                  {leadCount > 0 && (
                    <div className="alert alert-success mb-4">
                      {leadCount} leads found in the file
                    </div>
                  )}
                  <div className="d-flex justify-content-around">
                    <button
                      className="btn btn-success mt-3"
                      onClick={handleNext1}
                      disabled={leadCount === 0}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div className="p-4 border rounded-3 bg-light">
                  {/* <h5 className="mb-4 d-flex justify-content-between">
                    Map File Headers
                  </h5> */}
                  <h5> Map File Headers</h5>
                  <div className="d-flex justify-content-center align-items-center ">
                    {/* <h5 className="m-0" style={{fontSize: '2rem', fontWeight: 'bold', color:"black"}}>{selectedFileName}</h5>
                    <Edit2 style={{ marginLeft: '10px' }}/> */}

                    {isEditing ? (
                      <input
                        type="text"
                        className="form-control"
                        value={listName}
                        onChange={handlelistNameChange}
                        style={{
                          font: "2rem",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      />
                    ) : (
                      <h5
                        className="m-0"
                        style={{
                          fontSize: "2rem",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {selectedFileName}
                      </h5>
                    )}
                    <Edit2
                      style={{ marginLeft: "10px" }}
                      onClick={handleEditClick}
                    />
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      {expectedTitles
                        .slice(0, expectedTitles.length / 2)
                        .map((header, index) => (
                          <div key={index} className="mb-3">
                            <label>{header.title}</label>
                            <select
                              // className="form-control"
                              className={`form-control ${
                                mapping[header.key] ? "font-weight-bold" : ""
                              }`}
                              value={mapping[header.key] || ""}
                              onChange={(e) => {
                                // console.log(header.key, e.target.value);
                                handleMappingChange(header.key, e.target.value);
                              }}
                            >
                              <option value="">Select a field</option>
                              {headers.map((title, idx) => (
                                <option key={idx} value={idx}>
                                  {title}
                                </option>
                              ))}
                            </select>
                          </div>
                        ))}
                    </div>

                    <div className="col-md-6">
                      {expectedTitles
                        .slice(expectedTitles.length / 2)
                        .map((header, index) => (
                          <div key={index} className="mb-3">
                            <label>{header.title}</label>
                            <select
                              className={`form-control ${mapping[header.key] ? 'font-weight-bold' : ""}`}
                              value={mapping[header.key] || ""}
                              onChange={(e) => {
                                // console.log(header.key, e.target.value);
                                handleMappingChange(header.key, e.target.value);
                              }}
                            >
                              <option value="">Select a field</option>
                              {headers.map((title, idx) => (
                                <option key={idx} value={idx}>
                                  {title}
                                </option>
                              ))}
                            </select>
                          </div>
                        ))}

                      <div key="source" className="mb-3">
                        <label>Source</label>
                        <input
                          type="text"
                          className={`form-control ${
                            source ? "font-weight-bold" : ""
                          }`}
                          value={source || ""}
                          onChange={(e) => {
                            setSource(e.target.value);
                          }}
                        />
                      </div>

                      <div key="sourceId" className="mb-3">
                        <label>Source ID</label>
                        <input
                          type="text"
                          className={`form-control ${
                            sourceId ? "font-weight-bold" : ""
                          }`}
                          value={sourceId || ""}
                          onChange={(e) => {
                            setSourceId(e.target.value);
                          }}
                        />
                      </div>

                      <div key="vendorLeadCode" className="mb-3">
                        <label>Vendor Lead Code</label>
                        <input
                          type="text"
                          className={`form-control ${
                            vendorLeadCode ? "font-weight-bold" : ""
                          }`}
                          value={vendorLeadCode || ""}
                          onChange={(e) => {
                            setVendorLeadCode(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-around">
                    <button
                      type="button"
                      className="btn btn-secondary mx-2"
                      onClick={handleBack}
                      disabled={loading}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary mx-2"
                      // onClick={handleNext2}
                      onClick={handleMappingComplete}
                      disabled={loading || !isSaveEnabled}
                    >
                      View
                    </button>
                  </div>
                </div>
              )}

              {step === 3 && !loading && (
                <div className="p-4 border rounded-3 bg-light">
                  <h5 class="text-dark">Leads Data to be uploaded</h5>

                  <div className="table-responsive">
                    <div className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                      <table
                        id="default_order"
                        className="table table-striped table-bordered display no-wrap text-center w-100 table-bolder"
                        role="grid"
                        aria-describedby="default_order_info"
                      >
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            {expectedTitles
                              .filter(
                                (header) => mapping[header.key] !== undefined
                              )
                              .map((header, index) => (
                                <th scope="col" key={index}>
                                  {header.title}
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {finalData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              {expectedTitles
                                .filter(
                                  (header) => mapping[header.key] !== undefined
                                )
                                .map((title) => (
                                  <td key={title.key}>{item[title.key]}</td>
                                ))}
                            </tr>
                          ))} */}
                          {uniqueLeads.map((item, index) => (
                            <tr
                              key={index}
                              style={{ backgroundColor: "lightgreen" }}
                            >
                              <td>{index + 1}</td>
                              {expectedTitles
                                .filter(
                                  (header) => mapping[header.key] !== undefined
                                )
                                .map((title) => (
                                  <td key={title.key}>{item[title.key]}</td>
                                ))}
                            </tr>
                          ))}
                          {duplicateLeads.map((item, index) => (
                            <tr
                              key={index}
                              style={{ backgroundColor: "lightcoral" }}
                            >
                              {/* <td>{index + 1}</td> */}
                              <td>{uniqueLeads.length + index + 1}</td>
                              {expectedTitles
                                .filter(
                                  (header) => mapping[header.key] !== undefined
                                )
                                .map((title) => (
                                  <td key={title.key}>{item[title.key]}</td>
                                ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-flex justify-content-around">
                    <button
                      type="button"
                      className="btn btn-secondary  mt-3"
                      onClick={handleBack}
                      disabled={loading}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary  mt-3"
                      onClick={submitHandler}
                      disabled={loading}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto img-fluid"
            title="Ads"
            alt="ads"
          />
        </div>
      </div>
    </div>
  );
};

export default AddLeadsCall;
