import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import StaticCallTypeChart from "./StaticCallTypeChart";

const CallTypeChart = ({ fetchData1 }) => {
  const [chartData, setChartData] = useState({
    categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    callsPerDay: [0, 0, 0, 0, 0, 0, 0], //counts for each weekday
    tooltips: [[], [], [], [], [], [], []], // Store tooltips (dates) for each weekday
  });

  useEffect(() => {
    if (fetchData1 && fetchData1.length > 0) {
      const groupedData = {
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        callsPerDay: [0, 0, 0, 0, 0, 0, 0], // Reset 
        tooltips: [[], [], [], [], [], [], []], 
      };

      fetchData1.forEach(item => {
        const createdAt = new Date(item.createdAt); // Parse the date
        const dayOfWeek = createdAt.getDay(); // Get the day of the week (0 - Sunday, 6 - Saturday)
        const dateStr = `${createdAt.getDate().toString().padStart(2, '0')}/${(createdAt.getMonth() + 1).toString().padStart(2, '0')}/${createdAt.getFullYear()}`;

        // Increment the call count for the corresponding day of the week
        groupedData.callsPerDay[dayOfWeek] += 1;
        groupedData.tooltips[dayOfWeek].push(dateStr); // Add the formatted date for the tooltip
      });

      setChartData(groupedData); 
    }
  }, [fetchData1]);


  // Calculate dynamic tick positions based on the total number of calls
  // const totalCalls = chartData.callsPerDay.reduce((sum, count) => sum + count, 0);
  // const maxTicks = 7; 
  // const step = totalCalls > 0 ? Math.ceil(totalCalls / maxTicks) : 1; // Calculate the step for ticks based on total calls

  // const tickPositions = [];
  // for (let i = 0; i <= maxTicks; i++) {
  //   tickPositions.push(i * step);
  // }

  if (!chartData || chartData.callsPerDay.length === 0) {
    return <div>No data available</div>;
  }

  const options = {
    chart: {
      type: "spline",
    },
    title: {
      text: "Call Types",
    },
    xAxis: {
      categories: chartData.categories, 
      tickInterval: 1, 
    },
    yAxis: {
      title: {
        text: null, 
      },
      // tickPositions: tickPositions, 
      tickPositions:  [0, 50, 100, 150, 200, 250, 300],

    },
    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function () {
        const dates = chartData.tooltips[this.point.x];
        const totalCallsForDay = chartData.callsPerDay[this.point.x];
        let tooltipText = `Total Calls: ${totalCallsForDay}`;
        if (dates.length > 0) {
          tooltipText = `Dates: <br/>${dates.join('<br/>')}<br/>${tooltipText}`;
        }
        return tooltipText;
        // return `Dates: <br/>${dates.join('<br/>')}<br/>Total Calls: ${chartData.callsPerDay[this.point.x]}`;
      },
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
        },
      },
    },
    series: [
      {
        name: 'Outbound Calls',
        marker: {
          symbol: 'diamond',
        },
        data: chartData.callsPerDay, 
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y; 
          },
          style: {
            fontWeight: 'bold',
            color: 'black',
            textOutline: '0px', 
          },
        },
      },
    ],
  };

  return (
      <div>
          {fetchData1.length > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
          ) : (
           <StaticCallTypeChart/>
          )}
        </div>
  );
};

export default CallTypeChart;

