import React, { useEffect, useState } from "react";
import useApis from "../../../apis/useApis";
import appConstant from "./../../../json/appConstant.json";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import employeeTem from "../../../AddEmployee.xlsx";
import PaginationComponent from "../../../components/pagination/PaginationComponent";
import FormatNumber from "../../../components/utils/FormatNumber";

const AddEmployee = () => {
  const { jsonApi } = useApis();
  const [excelError, setExcelError] = useState("");
  const [UserInfo, setUserInfo] = useState("");
  const expectedTitles = ["name", "email", "phone", "gender", "type"];
  const [selectedFileName, setSelectedFileName] = useState("");
  const [fetchData, setFetchData] = useState([]);
  const [limit, setLimit] = useState(10); // Default limit
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [successMessage, setSuccessMessage] = useState();
  const [permissions, setPermissions] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAllCheckBoxes, setSelectedAllCheckBoxes] = useState(false);

  const [checkedPermissions, setCheckedPermissions] = useState(
    currentUser?.permission ? currentUser?.permission : []
  );
  useEffect(() => {
    if (currentUser?.permission) {
      setCheckedPermissions(currentUser?.permission);
    }
  }, [currentUser?.permission]);

  const handleCheckboxPermissionChange = (permission) => {
    setCheckedPermissions((prev) =>
      prev.includes(permission)
        ? prev.filter((item) => item !== permission)
        : [...prev, permission]
    );
  };

  const convertCSVtoObjectHandler = (e) => {
    const files = e.target.files;
    const data = [];
    setExcelError("");

    if (files) {
      const file = files[0];
      setSelectedFileName(file.name);
      const fileType = file.type;

      if (fileType === "text/csv") {
        Papa.parse(file, {
          complete: function (results) {
            processData(results.data);
            setSelectedFileName("");
          },
        });
      } else if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel"
      ) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          processData(jsonData);
        };
        reader.readAsBinaryString(file);
      } else {
        // openErrorSnackbar("Unsupported file type");
      }
    }
    e.target.value = null;
  };

  const processData = (data) => {
    const result = [];
    for (let index = 1; index < data.length; index++) {
      const element = data[index];
      const title = data[0];

      if (element.length === expectedTitles.length) {
        const gender = element[3];
        if (gender === "Female" || gender === "Male" || gender === "Other") {
          if (!element.includes("")) {
            result.push({
              [title[0]]: element[0],
              [title[1]]: element[1],
              [title[2]]: element[2],
              [title[3]]: element[3],
              [title[4]]: element[4],
            });
          } else {
            setExcelError("Data is missing in file");
            break;
          }
        } else {
          setExcelError("Data is missing in file");

          break;
        }
      } else {
        break;
      }
    }
    setUserInfo(result);
  };

  const createUsersInCompany = async () => {
    if (UserInfo.length > 0) {
      try {
        const res = await jsonApi(
          appConstant.addMultipleEmployeeInCompany,
          {},
          UserInfo,
          true
        );
        setUserInfo("");
        setSelectedFileName("");
        setSuccessMessage(res.data.message);
        handleMessage();
        fetchEmployeDetails();
      } catch (error) {
        console.error("Error creating users:", error);
        setExcelError("Failed to create employees. Please try again.");
      }
    } else {
      setExcelError("No user information to submit.");
    }
  };

  const fetchEmployeDetails = async () => {
    const employeeDetail = await jsonApi(
      appConstant.getEmployees,
      {
        page,
        limit,
        search: search,
        type: ["Employee", "Executive", "Manager"],
      },
      null,
      true
    );
    if (!employeeDetail.error) {
      setFetchData(employeeDetail.data.data);
      setTotalData(employeeDetail.data.total);
    } else {
      console.error(employeeDetail.message);
    }
  };

  useEffect(() => {
    fetchEmployeDetails();
  }, [page, limit, search]);

  const handleMessage = () => {
    setInterval(() => {
      setSuccessMessage("");
    }, 5000);
  };

  const getCompany = async () => {
    const res = await jsonApi(appConstant.getCompany, {}, null, true);
    if (!res.error) {
      setPermissions(res.data.data);
    } else {
      console.error(res.message);
    }
  };
  useEffect(() => {
    getCompany();
  }, []);

  const handleCheckboxChange = (item, isSelectedAll = false) => {
    if (isSelectedAll) {
      const newSelectAll = !selectedAllCheckBoxes;
      setSelectedAllCheckBoxes(newSelectAll);

      if (newSelectAll) {
        const allIds = fetchData.map((item) => item._id);
        setSelectedItems(allIds);
      } else {
        setSelectedItems([]);
      }
    } else {
      setSelectedItems((prevSelected) => {
        if (prevSelected.includes(item._id)) {
          return prevSelected.filter((id) => id !== item._id);
        } else {
          return [...prevSelected, item._id];
        }
      });
    }
  };

  const handleSubmit = async () => {
    if (selectedItems.length > 0) {
      const res = await jsonApi(
        appConstant.updateBulkEmployeePermission,
        null,
        {
          permissions: checkedPermissions,
          userIds: selectedItems,
        },
        false
      );

      if (!res.error) {
        setSelectedItems([]);
        setSelectedAllCheckBoxes(false);
        setSuccessMessage("Permissions updated successfully!");
        handleMessage();
        setCheckedPermissions([]);
        fetchEmployeDetails();
      } else {
        console.error(res.message);
      }
    } else if (currentUser) {
      const res = await jsonApi(
        appConstant.updateEmployeePermission,
        null,
        {
          permission: checkedPermissions,
          _id: currentUser._id,
        },
        false
      );
      setCurrentUser({});
      if (!res.error) {
        // setCurrentUser(null);
        setSuccessMessage("Permission updated successfully for the user!");
      } else {
        console.error(res.message);
      }
    }
  };

  const handleEditPermissionClick = () => {
    if (selectedItems.length > 1) {
      setCurrentUser(null);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
        <div className="col-xl-11">

          <div className="col-12 d-flex justify-content-between align-items-center">
            <h3 className="page-title text-dark font-weight-medium mt-2" style={{marginLeft:"-15px"}}>
              Employee List
            </h3>

            <div className="col-4 d-flex justify-content-end" style={{marginRight:"-30px"}}>
              <a
                href="#!"
                className="btn btn-success px-4"
                data-toggle="modal"
                data-target="#personalContact"
              >
                Bulk Upload
              </a>
              <div
                className="modal fade"
                id="personalContact"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4
                        className="modal-title text-dark font-weight-medium"
                        id="exampleModalLabel"
                      >
                        Personal Contact
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setSelectedFileName("")}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <h5 className="mb-3 text-black font-weight-bold">
                            Add Members to Contacts Via CSV
                          </h5>
                          <p className="mb-3 text-black">
                            To add members to Contacts via CSV, upload a CSV
                            file containing the user’s First Name, Last Name,
                            Email can be included.
                          </p>
                          <p className="mb-3 text-black">
                            Click the “Download CSV Template” button to download
                            a template containing the correct column headers.
                          </p>
                          <h6 className="mb-3 text-black font-weight-bold">
                            File Upload (.csv)
                          </h6>
                          <div className="row">
                            <div className="col-sm-5 mb-3">
                              <span className="btn btn-secondary btn-file">
                                Choose a File{" "}
                                <input
                                  type="file"
                                  onChange={convertCSVtoObjectHandler}
                                />
                              </span>
                            </div>
                            <div className="col-sm-7 mb-3">
                              <a href={employeeTem} download="AddEmployee.xlsx">
                                <i
                                  data-feather="download"
                                  className="feather-icon font-12 w-16 mr-2"
                                />
                                Download CSV Template
                              </a>
                            </div>
                            <div className="col-sm-7 mb-3 d-flex align-items-center">
                              {selectedFileName && (
                                <div className="file-name ml-3">
                                  {selectedFileName}
                                </div>
                              )}
                            </div>
                            {excelError ? (
                              <div className="col-12 my-4">
                                <div class="alert alert-danger" role="alert">
                                  {excelError}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="col-12 my-4">
                              <button
                                type="button"
                                onClick={() => createUsersInCompany()}
                                className="btn btn-primary"
                                data-dismiss="modal"
                                aria-label="Close"
                                disabled={!selectedFileName}
                              >
                                Bulk Add Members
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>


          <div className="col-xl-11">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="table-responsive">
                      <div
                        id="default_order_wrapper"
                        className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                      >
                        <PaginationComponent
                          totalData={totalData}
                          setPage={setPage}
                          limit={limit}
                          page={page}
                          setLimit={setLimit}
                          setSearch={setSearch}
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="row">
                                {/* <div className="col-12 align-self-center"> */}
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                  <td className="mb-1 col-6">
                                    <input
                                      type="checkbox"
                                      checked={selectedAllCheckBoxes}
                                      onChange={() =>
                                        handleCheckboxChange(null, true)
                                      }
                                    />
                                    <span className="p-2">Select All</span>
                                  </td>
                                  {selectedItems.length > 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-success green_light_btn"
                                      data-toggle="modal"
                                      data-target="#editPermissionPopup"
                                      onClick={handleEditPermissionClick} // Updated here
                                      disabled={selectedItems.length === 0}
                                    >
                                      Edit Permissions
                                    </button>
                                  )}
                                </div>
                              </div>
                              <table
                                id="default_order"
                                className="table table-striped table-bordered display no-wrap text-center w-100 call_history_table dataTable no-footer"
                                role="grid"
                                aria-describedby="default_order_info"
                              >
                                <thead>
                                  <tr role="row">
                                    <th>S. No.</th>
                                    <th className="text-left">Name</th>
                                    <th>Phone Number</th>
                                    <th>Email</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Gender</th>
                                    <th>Edit Permission</th>
                                  </tr>
                                </thead>

                                {/* Adjust colspan as necessary */}
                                <tbody>
                                  {fetchData.length > 0 ? (
                                    fetchData.map((item, index) => {
                                      const isChecked = selectedItems.includes(
                                        item._id
                                      );

                                      return (
                                        <tr
                                          key={index}
                                          role="row"
                                          className={
                                            index % 2 === 0 ? "even" : "odd"
                                          }
                                        >
                                          <td style={{ textAlign: "center" }}>
                                            <input
                                              type="checkbox"
                                              checked={isChecked}
                                              onChange={() =>
                                                handleCheckboxChange(item)
                                              }
                                            />
                                            <span className="p-2">
                                              {(page - 1) * limit + index + 1}
                                            </span>
                                          </td>
                                          <td className="text-left">
                                            {item?.name || "Unknown User"}
                                          </td>
                                          <td>
                                            <i className="material-symbols-outlined mr-1">
                                              phone_callback
                                            </i>
                                            {FormatNumber(item?.phone)}
                                          </td>
                                          <td>{item?.email || "N/A"}</td>

                                          <td> {item.type || "N/A"}</td>
                                          <td>{item?.status || "N/A"}</td>
                                          <td>{item?.gender || "N/A"}</td>

                                          <td>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-success green_light_btn"
                                              data-toggle="modal"
                                              data-target="#editPermissionPopup"
                                              onClick={() =>
                                                setCurrentUser(item)
                                              }
                                              disabled={
                                                selectedItems.length > 0
                                              }
                                            >
                                              Edit Permission
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="5">No data available</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </PaginationComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="editPermissionPopup"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4
                    className="modal-title text-dark font-weight-medium"
                    id="exampleModalLabel"
                  >
                    Permissions
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="table-responsive mb-4">
                    <table className="table table-striped display no-wrap w-100">
                      {selectedItems.length === 0 && currentUser && (
                        <thead>
                          <tr>
                            <th
                              style={{ width: "60%" }}
                              className="font-weight-bold text-black"
                            >
                              User Last Name, First Name
                            </th>
                            <th
                              style={{ width: "60%" }}
                              className="font-weight-bold text-black"
                            ></th>
                            <th style={{ width: "40%" }}>
                              {currentUser?.name}
                            </th>
                          </tr>
                        </thead>
                      )}
                    </table>
                  </div>
                  <div className="row text-black">
                    <div className="col-lg-8 mb-4">
                      <div className="row">
                        <div className="col-sm-6">
                          {permissions?.corporatePermissions?.map(
                            (permission) => (
                              <div className="row" key={permission}>
                                <div className="col-9">
                                  <label className="font-weight-bold h4">
                                    {permission
                                      .replace(/([A-Z])/g, " $1")
                                      .trim()}
                                  </label>
                                </div>
                                <div className="col-3">
                                  <div className="toggle_small_switch">
                                    <label htmlFor={`${permission}`}>
                                      <input
                                        type="checkbox"
                                        id={`${permission}`}
                                        checked={checkedPermissions.includes(
                                          permission
                                        )}
                                        onChange={() =>
                                          handleCheckboxPermissionChange(
                                            permission
                                          )
                                        }
                                      />
                                      <span>
                                        <small />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="next-step btn_green "
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              title="Ads"
              alt="Ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
