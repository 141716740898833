import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContactHistory from "../ContactHistory";
import moment from "moment";
import { CallCenterContext } from "../../../context/CallCenterContext";
import ImageComponent from "../../../media/ImageComponent";
import useFetchdata from "../../../hooks/useFetchUserDetail";

const SpouseProfileData = () => {
  const { id } = useParams();
  const { makeCall } = useContext(CallCenterContext);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");
  const { data } = useFetchdata(id);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 align-self-center pt-4">
                    <h2 className="page-title text-truncate text-dark font-weight-medium">
                      Spouse Profile Data
                    </h2>

                    <div className="row">
                      <div className="col-sm d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <h2 className="page-title text-truncate text-dark font-weight-medium mb-0">
                            {/* {data?.spouse?.fullName || "No name"} */}
                            {data?.spouse?.fullName
                              ? data?.spouse?.fullName
                                  .split(" ")
                                  .reverse()
                                  .join(",")
                              : ""}
                          </h2>
                          <button
                            type="button"
                            className="btn waves-effect waves-light btn-rounded btn-sm btn-primary ml-3"
                            data-toggle="modal"
                            data-target="#editprofilepicModal"
                            onClick={() =>
                              navigate(`/contact/personal/edit/spouse?id=${id}`)
                            }
                          >
                            Edit
                          </button>
                        </div>
                      </div>

                      <div className="col-sm text-right d-inline-block">
                        <ImageComponent
                          className="rounded-circle profile-image"
                          alt={data?.spouse?.fullName}
                          src={
                            data?.spouse?.profileImage ||
                            "/assets/images/dummy.png"
                          }
                        />
                      </div>
                    </div>

                    <p
                      className="text-truncate text-dark font-weight-medium"
                      style={{ fontSize: "20px" }}
                    >
                      {data?.spouse?.fullName || "No name"}
                    </p>

                    <div className="row mt-2">
                      <div className="col-lg-4 ">
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label d-flex align-items-center"
                          >
                            Position :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center d-flex align-items-center">
                            {data?.spouse?.position || " gfhgf"}
                          </div>
                        </div>

                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Company Name :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center d-flex align-items-center">
                            {data?.spouse?.companyName || " "}
                          </div>
                        </div>

                        <div className="form-group row mb-0">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Cell No :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center d-flex align-items-center">
                            {data?.spouse?.phone &&
                            data?.spouse?.phone.length > 0
                              ? data?.spouse?.phone
                                  .filter(
                                    (phone) => !phone.value.startsWith("+1")
                                  )
                                  .map((phone, index) => (
                                    <div
                                      key="{phone._id}"
                                      onClick={() => {
                                        makeCall(
                                          phone.value,
                                          data?.spouse?._id
                                        );
                                        navigate("/phone/call");
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {phone.value}
                                    </div>
                                  ))
                              : ""}
                          </div>
                        </div>

                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Corporate # :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.workPhone &&
                            data?.spouse?.workPhone.length > 0
                              ? data?.spouse?.workPhone
                                  .filter(
                                    (workPhone) =>
                                      !workPhone.value.startsWith("+1")
                                  )
                                  .map((workPhone, index) => (
                                    <div
                                      key="{workPhone._id}"
                                      // onClick={() => {
                                      //   makeCall(phone.value);
                                      //   navigate("/phone/call");
                                      // }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {workPhone.value}
                                    </div>
                                  ))
                              : ""}
                          </div>
                        </div>

                        <div className="form-group row mb-0">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Email :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.email &&
                            data?.spouse?.email.length > 0
                              ? data?.spouse?.email.map((email, index) => (
                                  <div
                                    key="{email._id}"
                                    onClick={() =>
                                      (window.location.href = `mailto:${email.value}`)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {email.value}
                                  </div>
                                ))
                              : ""}
                          </div>
                        </div>

                        <div className="form-group row mb-0">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          ></label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.workEmail &&
                            data?.spouse?.workEmail.length > 0
                              ? data?.spouse?.workEmail.map(
                                  (workEmail, index) => (
                                    <div
                                      key="{workEmail._id}"
                                      onClick={() =>
                                        (window.location.href = `mailto:${workEmail.value}`)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {workEmail.value}
                                    </div>
                                  )
                                )
                              : ""}
                          </div>
                        </div>

                        <div className="form-group mb-0 row mt-2">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Personal Address :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            <div>
                              {[
                                data?.spouse?.primaryStreetAddress,
                                data?.spouse?.primaryCity,
                                data?.spouse?.primaryState,
                                data?.spouse?.primaryZip,
                                data?.spouse?.primarySuiteNo,
                              ]
                                .filter(Boolean)
                                .join(", ")}
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-0 row mt-2">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Office Address :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {[
                              data?.spouse?.companyStreetAddress,
                              data?.spouse?.companyCity,
                              data?.spouse?.companyState,
                              data?.spouse?.companyZip,
                              data?.spouse?.companySuiteNo,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </div>
                        </div>

                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Anniversary :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.weddingAnniv || " "}
                          </div>
                        </div>

                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            DOB :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.dob
                              ? moment(data?.spouse?.dob).format("YYYY/MM/DD")
                              : " "}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Age :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.age || " "}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        {/* <h4 className="page-title text-truncate text-dark font-weight-medium my-4">
                                Business Information
                              </h4> */}

                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Social Security # :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.socialSecurity || " "}
                          </div>
                        </div>

                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Drivers License # :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.driversLicense || " "}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Drivers License State :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.driversLicenseState || " "}
                          </div>
                        </div>

                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Medicare # :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.medicareNumber || " "}
                          </div>
                        </div>
                        <h5 className="pt-2">Medicare Enrollment Dates :</h5>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Part A :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.partA || " "}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Part B :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.partB || " "}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Part C :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.partC || " "}
                          </div>
                        </div>

                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Medicare Plan Type :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.medicarePlanType || " "}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-6 col-form-label"
                          >
                            Medicare Company :
                          </label>
                          <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                            {data?.spouse?.medicareCompany || " "}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <h4 className="page-title text-truncate text-dark font-weight-medium">
                          Dependents :
                        </h4>
                        {data?.dependents?.length > 0 ? (
                          data?.dependents.map((dependent, index) => (
                            <div key={index}>
                              <h5 className="page-title text-truncate text-dark font-weight-medium my-4">
                                Child # {index + 1}: {dependent?.name || " "}
                              </h5>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Age :
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center font-weight-bold text-dark">
                                  {dependent?.age || " "}
                                </div>
                              </div>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  DOB :
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {dependent?.dob
                                    ? moment(dependent?.dob).format(
                                        "YYYY/MM/DD"
                                      )
                                    : " "}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No dependents</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
              alt="ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SpouseProfileData;
