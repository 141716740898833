import React from "react";

const POARelationship = ({
  fieldName,
  fieldShowName,
  register,
  errors,
}) => {

  return (
    <div>
      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">{fieldShowName}</label>
        </div>
        <div className="col-5">
          <select
            className={`form-control black-border ${
              errors[fieldName] ? "border border-danger" : ""
            }`}
            id={fieldName}
            {...register(fieldName, {
              required: false,
            })}
          >
            <option value="Care Giver">
              Does Not Qualify for Medicaid
            </option>{" "}
            <option value="Daughter">
              Daughter
            </option>{" "}
            <option value="Power of Attorney">
              Power of Attorney
            </option>{" "}
            <option value="Son">Son</option>{" "}
            <option value="Spouse">
             Spouse
            </option>{" "}
            <option value="Other">
              Other
            </option>{" "}
            
          </select>
          {errors[fieldName] && (
            <p className="text-danger">{errors[fieldName].message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default POARelationship;
