import React from "react";
import { Controller } from "react-hook-form";
import { PhoneInput } from "react-international-phone";

const ArrayDoubleFieldPhone = ({
  errors,
  fieldName,
  fieldShowName,
  register,
  useFieldArray,
  control,
  showButton,
}) => {
  const {
    fields: personalFieldsPhone,
    append: appendPersonalPhone,
    remove: removePersonalPhone,
  } = useFieldArray({
    control,
    name: fieldName,
  });
  return (
    <div className="row my-2">
      <div className="col-7 pt-2">
        <label htmlFor={fieldName} className="">
          {fieldShowName}
        </label>
      </div>
      <div className="col-5">
        <div className="form-group d-flex align-items-center">
          <div className="form-group" style={{ width: "100%" }}>
            {personalFieldsPhone.map((item, index) => (
              <Controller
                key={index}
                name={`${fieldName}.${index}.value`}
                control={control}
                render={({ field }) => (
                  <>
                    <div className="d-flex">
                      <PhoneInput
                        preferredCountries={"us"}
                        value={field.value}
                        inputProps={{ required: true }}
                        onChange={(value) => field.onChange(value)}
                        style={{ width: "100%" }}
                        onBlur={field.onBlur}
                        disableDialCodeAndPrefix={true}
                        disableFocusAfterCountrySelect={false}
                        hideDropdown={true}
                        disableCountryGuess={true}
                        className={`black-border mb-2 ${
                          errors.phone ? "border border-danger" : ""
                        }`}
                      />
                      <input
                        type="text"
                        className={`form-control black-border mb-2 ${
                          errors.email ? "border border-danger" : ""
                        }`}
                        maxLength={256}
                        //placeholder="abc@gmail.com"
                        {...register(`${fieldName}.${index}.type`)}
                      />
                      {index !== 0 ? (
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-circle m-2"
                          onClick={() => removePersonalPhone(index)}
                          style={{ color: "red" }}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-circle m-2"
                          style={{
                            visibility: "hidden",
                          }}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      )}
                    </div>
                    {errors?.phone?.[index]?.value && (
                      <p className="text-danger">
                        {errors?.phone?.[index]?.value.message}
                      </p>
                    )}
                  </>
                )}
              />
            ))}
          </div>
          {showButton && (
            <div className="d-flex justify-content-center align-items-center pe-4">
              <button
                type="button"
                className="btn btn-outline-primary btn-circle"
                data-toggle="modal"
                data-target="#primaryContactModal"
                onClick={() => {
                  appendPersonalPhone({
                    value: "",
                    type: "Personal",
                  });
                }}
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArrayDoubleFieldPhone;
