import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ContactBusinessSubHeader2 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const [selectedItem, setSelectedItem] = useState("Human Resources");
  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
  };

  const navigate = useNavigate();

  const handleNavigation = (e) => {
    e.preventDefault();
    navigate("/phone/call");
  };

  return (
    <div
      className="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <Link to="/contact/business/add">
        <button
          type="button"
          className="btn waves-effect waves-light btn-rounded btn-sm btn-outline-primary mb-2"
        >
          + Add Business Contact
        </button>
      </Link>

      <div className="row">
        <ul className="nav">
          <li className="nav-item">
            <Link
              to="/contact/lead/client"
              className={`nav-link ${
                location.pathname === "/contact/lead/client" ? "active" : ""
              } `}
            >
              Client
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact/lead/prospects"
              className={`nav-link ${
                location.pathname === "/contact/lead/prospects" ? "active" : ""
              } `}
            >
              Prospects
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact/lead/campaigns/list"
              className={`nav-link ${
                location.pathname === "/contact/lead/campaigns/list" ? "active" : ""
              } `}
            >
              Campaigns
            </Link>
          </li>
          <li>
            <span className="separator">|</span>
          </li>
          <li className="nav-item">
            <Link
              to="/contact/business/all"
              className={`nav-link ${
                location.pathname === "/contact/business/all" ? "active" : ""
              } `}
            >
              All
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact/business/favorites"
              className={`nav-link ${
                location.pathname === "/contact/business/favorites"
                  ? "active"
                  : ""
              } `}
            >
              Favorites
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" href="#">
              Wholesalers
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" href="#">
              Vendors
            </Link>
          </li>
          <li>
            <span className="separator">|</span>
          </li>
          <li className="nav-item">
            <Link
              to="/contact/business/emailPage"
              className={`nav-link ${
                location.pathname === "/contact/business/emailPage"
                  ? "active"
                  : ""
              } `}
            >
              Email
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" href="#" onClick={handleNavigation}>
              Phone Call
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/call/history" className="nav-link" href="#">
              Call History
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" href="#">
              Contact Summary
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact/list/tasks" className="nav-link ">
              Task
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact/list/notes" className="nav-link ">
              Notes
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact/event/calendar" className="nav-link">
              Calendar
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" href="#">
              Files
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" href="#">
              Text
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" href="#">
              Chat
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" href="#">
              US Postal Mail
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" href="#">
              Schedule Event
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact/business/information"
              className={`nav-link ${
                location.pathname === "/contact/business/information"
                  ? "active"
                  : ""
              } `}
            >
              Business Information
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className={`nav-link dropdown-toggle ${
                selectedItem === "Human Resources" ? "" : "active"
              }`}
              onClick={toggleDropdown}
              to="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={isOpen}
            >
              {selectedItem}
              <i className="fa fa-angle-down px-1" aria-hidden="true" />
            </Link>
            {isOpen && (
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Link
                  to="business/owner"
                  className="dropdown-item"
                  onClick={() => handleItemClick("Owners")}
                >
                  Owners
                </Link>
                <Link
                  to="/contact/business/executive"
                  className="dropdown-item"
                  onClick={() => handleItemClick("Key Executive")}
                >
                  Key Executive
                </Link>
                <Link
                  to="/contact/business/manager"
                  // className={`dropdown-item ${selectedItem=== "Managers" ? "active" : ""} `}
                  className="dropdown-item "
                  onClick={() => handleItemClick("Managers")}
                >
                  Managers
                </Link>
                <Link
                  to="/contact/business/employee"
                  className="dropdown-item"
                  onClick={() => handleItemClick("Employee")}
                >
                  Employee
                </Link>
                <Link
                  to="/contact/business/human/resource"
                  className="dropdown-item"
                  onClick={() => handleItemClick("All")}
                >
                  All
                </Link>
              </div>
            )}
          </li>
          <li>
            <span className="separator">|</span>
          </li>
          <li className="nav-item">
            <Link className="nav-link" href="#">
              Sale Summary
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " href="#">
              Sale Opportunity
            </Link>
          </li>
          {/* <li>
            <span className="separator">|</span>
          </li> */}
          {/* <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Quote <i className="fa fa-angle-down" aria-hidden="true" />
            </Link>
            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <Link className="dropdown-item" href="#">
                Medicare
              </Link>
              <Link className="dropdown-item" href="#">
                Life Insurance
              </Link>
              <Link className="dropdown-item" href="#">
                Auto Insurance
              </Link>
              <Link className="dropdown-item" href="#">
                Homeowners Insurance
              </Link>
              <Link className="dropdown-item" href="#">
                Solar Energy
              </Link>
            </div>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default ContactBusinessSubHeader2;
