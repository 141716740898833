import React, { useEffect, useState } from "react";
import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { useNavigate, useParams } from "react-router-dom";

const EventCalendar = () => {
  document.title="Events - Digital Call Center";
  const localizer = momentLocalizer(moment);
  const [myEvents, setEvents] = useState([]);
  const { jsonApi } = useApis();
  const navigate = useNavigate();
  const { id } = useParams();

  const dateFetch = async () => {
      const { error, data } = await jsonApi(
        appConstant.getAllEvents,
        {id},
        null,
        false,
        true
      );

      if (!error) {
        const events = data.data
          .map((event) => {
            if (event.date) {
              const startDate = new Date(event.date);
              const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
              return {
                title: event.eventsName,
                start: startDate,
                allDay: true,
                end: endDate,
              };
            } else {
              return null;
            }
          })
          .filter((event) => event !== null);
        setEvents(events);
      } else {
        console.error("Error fetching events:", data);
      }
  };

  useEffect(() => {
    dateFetch();
  }, []);

  return (
    <>
        <div className="container-fluid">
          <div className="row">

            <div className="col-xl-11">
            <div className="col-12 d-flex justify-content-between align-items-center">
            <h3 className="page-title text-dark font-weight-medium mt-2">
            Calendar
            </h3>
            <div className="col-11 d-flex justify-content-end ">
            <button
              data-toggle="modal"
              data-target="#personalContact"
              className="page-link me-2 border border-primary"
              onClick={() => navigate(`/contact/list/events/${id}`)}
            >
              Events List
            </button>
            <button
              data-toggle="modal"
              data-target="#personalContact"
              className="page-link me-2 border border-primary"
              onClick={() => navigate(`/contact/add/event/${id}`)}
            >
              + Add New Event
            </button>
          </div>
          </div>
              <div className="card">
                <div className="card-body custom-field-button">
                  <div style={{ height: "80vh" }}>
                    <Calendar
                      localizer={localizer}
                      defaultDate={new Date()}
                      defaultView={Views.MONTH}
                      events={myEvents}
                      components={{
                        toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-1 d-none d-xl-block">
              <img
                src="/assets/images/banner/ads.jpg"
                className="mx-auto img-fluid"
                alt="Ads"
                title="Ads"
              />
            </div>
          </div>
        </div>
    </>
  );
};

const CustomToolbar = (toolbar) => {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-label">{toolbar.label}</span>
      <span className="rbc-btn-group">
        <button onClick={() => toolbar.onNavigate("PREV")}>Back</button>
        <button onClick={() => toolbar.onNavigate("TODAY")}>Today</button>
        <button onClick={() => toolbar.onNavigate("NEXT")}>Next</button>
      </span>
    </div>
  );
};

export default EventCalendar;
