import React, { createContext, useContext, useState } from "react";

const FormContext = createContext();

export const useFormContext = () => {
  return useContext(FormContext);
};

export const FormProvider = ({ children }) => {
  const [formContextData, setFormContextData] = useState("");

  return (
    <FormContext.Provider
      value={{
        formContextData,
        setFormContextData,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
