import React, { useState } from "react";
import zipCodes from "../../json/zipCode.json";

const MailAddress = ({ errors, item, register, setValue, check }) => {
  const [zipData, setZipData] = useState({
    city: "",
    state: "",
  });
  const [country, setCountry] = useState("");

  const [showMailingAddressFields, setShowMailingAddressFields] =useState(false);

  const handleCheckboxChange = () => {
    setShowMailingAddressFields(!showMailingAddressFields);
  };

  const handleZipChange = (event) => {
    const zip = event.target.value;
    const zipInfo = zipCodes.find((entry) => entry.zip === zip);

    if (zipInfo) {
      setZipData({
        city: zipInfo.city,
        state: zipInfo.state,
      });
      setCountry("US");

      // Update the mailAddress only for city, state, zip, and country
      setValue("mailAddress", [
        {
          zip: zip,
          country: "US",
          cityState: `${zipInfo.city}, ${zipInfo.state}`,
          street: "", // Don't reset street or suiteNo here
          suiteNo: "",
        },
      ]);
    } else {
      setZipData({
        city: "",
        state: "",
      });
      setCountry("");

      // Clear mailAddress only for zip and cityState when zip is invalid
      setValue("mailAddress", [
        {
          zip: "",
          country: "US",
          cityState: "",
          street: "",
          suiteNo: "",
        },
      ]);
    }
  };

  const handleCityStateChange = (event) => {
    const value = event.target.value;
    const [city, state] = value.split(",").map((item) => item.trim());
    setZipData({
      city: city || "",
      state: state || "",
    });

    // City and State update for mailAddress as well
    setValue("mailAddress", [
      {
        zip: "", // Keep zip empty, allow manual entry if necessary
        country: "US",
        cityState: value,
        street: "",
        suiteNo: "",
      },
    ]);
  };

  return (
    <>
      <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
        {item.fieldShowName}
        <input
          type="checkbox"
          className="ml-2"
          checked={showMailingAddressFields}
          onChange={handleCheckboxChange}
        />
      </h4>

      {showMailingAddressFields && (
        <>
          <div className="row my-2">
        <div className="col-7 pt-2">
          <label htmlFor="zip">Zip Code</label>
        </div>
        <div className="col-5">
          <input
            type="text"
            maxLength={5}
            className={`form-control black-border ${
              errors.zip ? "border border-danger" : ""
            }`}
            {...register("zip", {
              required: "Zip code is required",
            })}
            onChange={handleZipChange}
          />
          {errors.zip && <p className="text-danger">{errors.zip.message}</p>}
        </div>
      </div>
      <div className="row my-2">
        <div className="col-7">
          <label htmlFor="country">Country</label>
        </div>
        <div className="col-5">
          <input
            type="text"
            maxLength={256}
            className={`form-control black-border ${
              errors.country ? "border border-danger" : ""
            }`}
            value={country}
            {...register("country", { required: false })}
          />
          {errors.country && (
            <p className="text-danger">{errors.country.message}</p>
          )}
        </div>
      </div>
      <div className="row my-2">
        <div className="col-7">
          <label htmlFor="cityState">City, State</label>
        </div>
        <div className="col-5">
          <input
            type="text"
            maxLength={256}
            className={`form-control black-border ${
              errors.cityState ? "border border-danger" : ""
            }`}
            value={`${zipData.city}, ${zipData.state}`}
            onChange={handleCityStateChange}
            {...register("cityState", {})}
          />
          {errors.cityState && (
            <p className="text-danger">{errors.cityState.message}</p>
          )}
        </div>
      </div>
     
           <div className="row my-2">
            <div className="col-7">
              <label htmlFor="street">Street</label>
            </div>
            <div className="col-5">
              <input
                type="text"
                maxLength={256}
                className={`form-control black-border ${
                  errors.street ? "border border-danger" : ""
                }`}
                {...register("street", { required: false })}
              />
              {errors.street && (
                <p className="text-danger">{errors.street.message}</p>
              )}
            </div>
          </div>

          <div className="row my-2">
            <div className="col-7">
              <label htmlFor="suiteNo">Suite #</label>
            </div>
            <div className="col-5">
              <input
                type="text"
                maxLength={256}
                className={`form-control black-border ${
                  errors.suiteNo ? "border border-danger" : ""
                }`}
                {...register("suiteNo", { required: false })}
              />
              {errors.suiteNo && (
                <p className="text-danger">{errors.suiteNo.message}</p>
              )}
            </div>
          </div>
     
     </>
      )}
    
    </>
  );
};

export default MailAddress;
