import React, { useState } from "react";
import ModelUi from "../model/ModelUi";
import HeadingFelid from "./HeadingFelid";
import SubHeadingFelid from "./SubHeadingFelid";
import TextFelid from "./TextFelid";
import EmailFelid from "./EmailFelid";
import PhoneFelid from "./PhoneFelid";
import NumberFelid from "./NumberFelid";
import DateFelid from "./DateFelid";
import TextAreaFelid from "./TextAreaFelid";
import SelectFelid from "./SelectFelid";
import RadioFelid from "./RadioFelid";

const AddFelidModel = ({
  openAddFelidModal,
  addFelidHandlerCloseModel,
  addFelidHandler,
}) => {
  const [felidType, setFelidType] = useState("");

  return (
    <ModelUi
      show={openAddFelidModal}
      handleClose={addFelidHandlerCloseModel}
      title={"Add felid in form"}
      centered={true}
    >
      <div className="row">
        <div className="col-12 align-self-center">
          <div className="form-group">
            <label htmlFor="employmentLevel">Select Felid Type</label>

            <select
              className="form-control"
              value={felidType}
              onChange={(e) => setFelidType(e.target.value)}
            >
              <option value="">Select Felid Type</option>
              <option value="Heading">Heading</option>
              <option value="SubHeading">Sub Heading</option>
              <option value="Text">Text</option>
              <option value="Email">Email</option>
              <option value="Phone">Phone</option>
              <option value="Number">Number</option>
              <option value="Date">Date</option>
              <option value="TextArea">Text Area</option>
              <option value="Select">Select </option>
              <option value="Radio">Radio </option>
            </select>
          </div>
        </div>
      </div>
      {felidType === "Heading" ? (
        <HeadingFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "SubHeading" ? (
        <SubHeadingFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "Text" ? (
        <TextFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "Email" ? (
        <EmailFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "Phone" ? (
        <PhoneFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "Number" ? (
        <NumberFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "Date" ? (
        <DateFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "TextArea" ? (
        <TextAreaFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "Select" ? (
        <SelectFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "Radio" ? (
        <RadioFelid addFelidHandler={addFelidHandler} />
      ) : (
        <></>
      )}
    </ModelUi>
  );
};

export default AddFelidModel;
