
function HoverableEmail({ emails }) {
  return emails.map((item) => (
    <div
      style={{ cursor: "pointer" }}
      title="Click to mail"
      onClick={() => (window.location.href = `mailto:${item.value}`)}
      className="hoverable-number"
    >
      {item.value}
    </div>
  ));
}

export default HoverableEmail;

// return (

//   <td style={{ position: "relative" }}>
//     {emails && emails.length > 0 ? (
//       emails.map((email, index) => (
//           <div
//             key={index}
//             onMouseEnter={() => setIsHovered(email)}
//             onMouseLeave={() => setIsHovered(null)}
//             style={{ display: "inline-block", marginRight: "10px" }}
//           >
//             <a
//               href={`mailto:${email}`}
//               style={{ textDecoration: "none", color: "inherit" }}
//             >
//               {email}
//             </a>

//             {isHovered === email && (
//               <div
//                 style={{
//                   position: "absolute",
//                   top: "-20px",
//                   left: "100px",
//                   backgroundColor: "#333",
//                   color: "#fff",
//                   padding: "5px",
//                   borderRadius: "3px",
//                   fontSize: "12px",
//                   whiteSpace: "nowrap",
//                   zIndex: 1,
//                 }}
//               >
//                 {email}
//               </div>
//             )}
//           </div>
//       ))
//   ): (
//       "No Data"
//       )}
//   </td>
// );
