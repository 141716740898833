import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import "./DatePickerStyle.css"

const LowIncomeSubsidy = ({ control, register, errors, item }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "lowIncomeSubsidys",
  });

  return (
    <div>
      <h5 className="text-dark font-weight-medium mb-4 pt-4">
        {item.fieldShowName}
      </h5>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Subsidy Start Date</th>
            <th>Subsidy End Date </th>
            <th>Premium Subsidy Level</th>
            <th>Co-Payment Level</th>
            <th>Subsidy Source</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((lowIncomeSubsidy, index) => (
            <tr key={lowIncomeSubsidy.id}>

              <td className="w-30">
                <Controller
                  control={control}
                  name={`lowIncomeSubsidys[${index}].subsidyStartDate`}
                  defaultValue={lowIncomeSubsidy.subsidyStartDate || ""}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value}
                      onChange={field.onChange}
                      format="DD/MM/YYYY"
                      className="form-control custom-date-picker"
                      calendarPosition="bottom-center"
                      selected={field.value ? new Date(field.value) : null}
                    />
                  )}
                />
              </td>

              <td className="w-30">
                <Controller
                  control={control}
                  name={`lowIncomeSubsidys[${index}].subsidyEndDate`}
                  defaultValue={lowIncomeSubsidy.subsidyEndDate || ""}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value}
                      onChange={field.onChange}
                      format="DD/MM/YYYY"
                      className="form-control custom-date-picker"
                      calendarPosition="bottom-center"
                      selected={field.value ? new Date(field.value) : null}
                    />
                  )}
                />
              </td>

              <td>
                <Controller
                  control={control}
                  name={`lowIncomeSubsidys[${index}].premiumSubsidyLevel`}
                  defaultValue={lowIncomeSubsidy.premiumSubsidyLevel || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <Controller
                  control={control}
                  name={`lowIncomeSubsidys[${index}].coPaymentLevel`}
                  defaultValue={lowIncomeSubsidy.coPaymentLevel || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <Controller
                  control={control}
                  name={`lowIncomeSubsidys[${index}].subsidySource`}
                  defaultValue={lowIncomeSubsidy.subsidySource || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  ×
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex">
        <button
          type="button"
          className="btn btn-primary ml-auto"
          onClick={() =>
            append({
              subsidyStartDate: "",
              subsidyEndDate: "",
              premiumSubsidyLevel: "",
              coPaymentLevel: "",
              subsidySource: "",
            })
          }
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default LowIncomeSubsidy;
