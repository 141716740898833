import { useForm, useFieldArray } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";

import React, { useContext, useEffect, useState } from "react";
import useApis from "../../../apis/useApis";
import appConstant from "../../../json/appConstant.json";
import { CallCenterContext } from "../../../context/CallCenterContext";
import { useNavigate, useParams } from "react-router-dom";
import { PhoneOff } from "react-feather";
import LeadField from "../LeadField";
import lifeInputField from "../../../json/lifeInputField.json";
import YesNoField from "../YesNoField";
import ArrayDoubleFieldPhone from "../ArrayDoubleFieldPhone";
import ArrayDoubleFieldEmail from "../ArrayDoubleFieldEmail";
import DateLead from "../DateLead";
import SocialSecurityNumber from "../SocialSecurityNumber";
import AddressField from "../AddressField";
import GenderField from "./GenderField";
import QuoteField from "../QuoteField";
import SalesInformation from "./SalesInformation";
import CoverageInformation from "./CoverageInformation";
import BeneficiarieNameRelationship from "./BeneficiarieNameRelationship";
import UndertakingInformation from "./UnderwritingInformation";
import UnderwritingInformation from "./UnderwritingInformation";

const UpdateLeadLifeForm = () => {
  document.title="Lead Life Form - Digital Call Center";
  const { jsonApi } = useApis();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [dob, setDob] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      beneficiarieNameRelationships: [
        {
          beneficiaryName: "",
          beneficiaryRelationship: "",
          beneficiaryPhone: "",
          beneficiaryAddress: "",
          beneficiaryDob: "",
          beneficiarySocialSecurity: "",
        },
      ],
      medicalConditionDetails: [
        {
          dateStarted: "",
          conditionType: "",
          medications: "",
          explanationOfCondition: "",
        },
      ],
      takingAnyMedicineDetail: [
        {
          medicineName: "",
          dosage: "",
          frequency: "",
          explanationOfTakingMedicine: "",
        },
      ],
    },
  });

  const { setPicked, handleEndCall, inCall, callDuration } =
    useContext(CallCenterContext);

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: id },
      {},
      false
    );
    console.log(res);

    if (!res?.error) {
      setData(res.data.data);
      populateForm(res.data.data);
    }
  };
  useEffect(() => {
    init();
  }, [id]);

  const onSubmit = async (data) => {
    const dataWithUserId = {
      // ...data,
      id: id,
      // notes: Array.isArray(data.notes) ? data.notes : [data.notes], // Ensure notes is an array

    };

    // Make API call to update user data
    const response = await jsonApi(
      appConstant.updateLeadLife,
      { id: id }, // Ensure 'id' is in the request URL or query
      dataWithUserId // Send the form data
    );

    console.log("Response:", response);

    if (!response.error) {
      reset();

      // navigate("/contact/personal");
    } else {
      console.error("Unexpected response:", response);
    }
  };

  const fetch = async () => {
    try {
      const businessFav = await jsonApi(
        appConstant.getZipCodesByCompany,
        {},
        null,
        true
      );

      if (!businessFav.error) {
      } else {
        setError();
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  // const onSubmit=(data)=>{
  //   console.log(data)
  //     }
  const endCall = () => {
    handleEndCall();
    setPicked("Call Ended");
  };
  const populateForm = (data) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(`${key}`, data[key]);
      });
      console.log(data.phone);
      if (data.phone.length === 0) {
        setValue("phone", [{ value: "", type: "Personal" }]);
      }
      if (data.email.length === 0) {
        setValue("email", [{ value: "", type: "Personal" }]);
      }
      setValue("married", true);
    }
  };

  useEffect(() => {
    const calculateAge = (date) => {
      if (!date) return "";
      var today = new Date();
      var birthDate = new Date(date); // create a date object directly from `dob1` argument
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };
    const age = calculateAge(dob);
    setError("");
    setValue("age", age || "");
  }, [dob, setValue]);

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-3">
            {inCall ? (
              <div>
                <div className="col-12 text-center">
                  Call Duration: {formatDuration(callDuration)}
                </div>
                <div className="col-12 text-center">
                  <button onClick={endCall} className="btn btn-danger">
                    <PhoneOff />
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col">
              <div className="card">
                <div className="card-body custom-field-button">
                  <div className="row">
                    {/* <div className="col-3 d-flex flex-column justify-content-start"> */}
                    <div className="col-4 d-flex flex-column px-3 box">
                      <div className="row text-dark font-weight-medium ">
                        <div className="mb-4 ">
                          <a href="#contact">
                            <h3>I. Contact Information </h3>
                          </a>
                        </div>
                        <div className="mb-4  ">
                          <a href="#sales">
                            <h3>II. Sales Information</h3>
                          </a>
                        </div>
                        <div className="mb-4  ">
                          <a href="#coverage">
                            <h3> III. Coverage Information</h3>
                          </a>
                        </div>
                        <div className="mb-4  ">
                          <a href="#underwriting">
                            <h3> IV. Underwriting Information </h3>
                          </a>
                        </div>
                        <div
                          className="w-100 mb-4"
                          style={{ marginLeft: "30px" }}
                        >
                          <a href="#quote">
                            <h3> Quote </h3>
                          </a>
                        </div>
                        <div
                          className="w-100 mb-4"
                          style={{ marginLeft: "30px" }}
                        >
                          <h3>Sales Summary</h3>
                          <p>
                            <strong>Sales Agent:</strong> Tom Toggas
                          </p>
                          <p>
                            <strong>Customer Service Rep:</strong> John Swartz
                          </p>
                          <p>
                            <strong>Belongs to Team:</strong> Team A
                          </p>
                          <p>
                            <strong>Sales Manager:</strong> Greg Paules
                          </p>
                          <p>
                            <strong>Product Type:</strong> Medicare
                          </p>
                          <p>
                            <strong>Lead Source:</strong> NextGen Leads
                          </p>
                          <p>
                            <strong>Campaign:</strong> Campaign 25
                          </p>
                          <p>
                            <strong>Region:</strong> Western
                          </p>
                          <p>
                            <strong>Ownership:</strong> Common
                          </p>
                          <p>
                            <strong>Client Stage:</strong> Prospect
                          </p>
                          <p>
                            <strong>Sales Stage:</strong> Quoted
                          </p>
                          <p>
                            <strong>Processing Stage:</strong> Submitted to
                            Underwriting
                          </p>
                          <p>
                            <strong>Client Rank:</strong> NA
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-8">
                      <div>
                        {lifeInputField.map((item) =>
                          item.type === "heading" ? (
                            <h3
                              className="page-title text-truncate text-dark font-weight-medium mb-4"
                              id={item.id}
                            >
                              {item?.fieldShowName}
                            </h3>
                          ) : item.type === 1 ? (
                            <LeadField
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                            />
                          ) : item.type === 2 ? (
                            <YesNoField
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                            />
                          ) : item.type === 3 ? (
                            <ArrayDoubleFieldPhone
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                              showButton={item.button}
                            />
                          ) : item.type === 4 ? (
                            <ArrayDoubleFieldEmail
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                              showButton={item.button}
                            />
                          ) : item.type === 5 ? (
                            <GenderField
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                              showButton={item.button}
                            />
                          ) : item.type === 6 ? (
                            <DateLead
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                            />
                          ) : item.type === 7 ? (
                            <SocialSecurityNumber
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                            />
                          ) : item.type === 8 ? (
                            <AddressField
                              errors={errors}
                              register={register}
                              setValue={setValue}
                              item={item}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                              check={item.check}
                            />
                          ) : item.type === "Break" ? (
                            <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
                              {item.fieldShowName}
                            </h4>
                          ) : item.type === 9 ? (
                            <SalesInformation
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                            />
                          ) : item.type === 10 ? (
                            <CoverageInformation
                              errors={errors}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              control={control}
                            />
                          ) : item.type === 11 ? (
                            <BeneficiarieNameRelationship
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              control={control}
                              item={item}
                            />
                          ) : item.type === 12 ? (
                            <UnderwritingInformation
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              control={control}
                              item={item}
                            />
                          ) : item.type === 15 ? (
                            <QuoteField
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                              item={item}
                            />
                          ) : (
                            <ArrayDoubleFieldPhone
                              errors={errors}
                              fieldName={item.fieldName}
                              fieldShowName={item.fieldShowName}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              useFieldArray={useFieldArray}
                              control={control}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  {/* Start Page Content */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        {/* Button Container */}
                        <div className="col-12 d-flex justify-content-center">
                          <div className="form-group row">
                            <button
                              type="submit"
                              className="next-step btn_green mx-2"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end row*/}
                  </div>
                </div>
                {/* multi-column ordering */}
              </div>

              {/* multi-column ordering */}
            </div>
          </form>
        </div>
      </div>

      {/* End Container fluid  */}
    </>
  );
};

export default UpdateLeadLifeForm;
