import React, { useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";

const MedicationInformation = ({ control, register, errors, item }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "medicationInformations",
  });

  const [showMedicationFields, setShowMedicationFields] = useState(false);
  const handleButtonClick = () => {
    setShowMedicationFields(!showMedicationFields);
  };
  return (
    <div>
      <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
        {item.fieldShowName} (Add Medication)
        {/* <input
          type="checkbox"
          className="ml-2"
          checked={showMedicationFields}
          onChange={handleCheckboxChange}
        /> */}
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2"
          onClick={handleButtonClick}
        >

          {showMedicationFields ? "Close" : "Add"}
        </button>
      </h4>

      {showMedicationFields && (
        <>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name of MED</th>
                <th>Quantity</th>
                <th>Dosage</th>
                <th>For What Reason</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((medicationInformation, index) => (
                <tr key={medicationInformation.id}>
                  <td>{index + 1}</td>

                  <td>
                    <Controller
                      control={control}
                      name={`medicationInformations[${index}].nameOfMed`}
                      defaultValue={medicationInformation.nameOfMed || ""}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          {...field}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name={`medicationInformations[${index}].quantity`}
                      defaultValue={medicationInformation.quantity || ""}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          {...field}
                        />
                      )}
                    />
                  </td>

                  <td>
                    <Controller
                      control={control}
                      name={`medicationInformations[${index}].dosage`}
                      defaultValue={medicationInformation.dosage || ""}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          {...field}
                        />
                      )}
                    />
                  </td>

                  <td>
                    <Controller
                      control={control}
                      name={`medicationInformations[${index}].reason`}
                      defaultValue={medicationInformation.reason || ""}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          {...field}
                        />
                      )}
                    />
                  </td>

                  <td>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => remove(index)}
                    >
                      ×
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="d-flex">
            <button
              type="button"
              className="btn btn-primary ml-auto"
              onClick={() =>
                append({
                  nameOfMed: "",
                  quantity: "",
                  dosage: "",
                  reason: "",
                })
              }
            >
              Add
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default MedicationInformation;

// import React, { useState } from "react";

// const MedicationDetail = ({ errors, register }) => {
//   const [medications, setMedications] = useState([
//     { nameOfMed: "", quantity: "", dosage: "", reason: "" },
//   ]);

//   const addMedication = () => {
//     setMedications([
//       ...medications,
//       { nameOfMed: "", quantity: "", dosage: "", reason: "" },
//     ]);
//   };

//   const removeMedication = (index) => {
//     if (medications.length > 1) {
//       const newMedications = medications.filter((_, i) => i !== index);
//       setMedications(newMedications);
//     }
//   };
//   const handleChange = (index, field, value) => {
//     const newMedications = [...medications];
//     newMedications[index][field] = value;
//     setMedications(newMedications);
//   };

//   return (
//     <div>
//       <table className="table table-bordered">
//         <thead>
//           <tr>
//             <th>S.No</th>
//             <th>Name of MED</th>
//             <th>Quantity</th>
//             <th>Dosage</th>
//             <th>For What Reason</th>
//           </tr>
//         </thead>
//         <tbody>
//           {medications.map((medication, index) => (
//             <tr key={index}>
//               <td>{index + 1}</td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={medication.nameOfMed}
//                   onChange={(e) =>
//                     handleChange(index, "nameOfMed", e.target.value)
//                   }
//                   {...register(`medications[${index}].nameOfMed`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={medication.quantity}
//                   onChange={(e) =>
//                     handleChange(index, "quantity", e.target.value)
//                   }
//                   {...register(`medications[${index}].quantity`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={medication.dosage}
//                   onChange={(e) =>
//                     handleChange(index, "dosage", e.target.value)
//                   }
//                   {...register(`medications[${index}].dosage`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={medication.reason}
//                   onChange={(e) =>
//                     handleChange(index, "reason", e.target.value)
//                   }
//                   {...register(`medications[${index}].reason`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="row">
//         <div className="text-right mb-3 col-6">
//           {medications.length > 1 && (
//             <div className="d-flex">
//               <button
//                 type="button"
//                 className="btn btn-danger"
//                 onClick={() => removeMedication(medications.length - 1)}
//                 style={
//                   {
//                     // bottom: "10px",
//                     // right: "10px",
//                     // width: "15px", // Small width
//                     // height: "15px", // Small height (to make it circular)
//                     // borderRadius: "50%", // Circular shape
//                     // padding: "0", // Remove padding
//                     // fontSize: "15px", // Adjust font size to fit in the button
//                     // display: "flex", // Use flex to center the plus sign
//                     // justifyContent: "center",
//                     // alignItems: "center",
//                     // lineHeight: "1", // Ensure the text is centered vertically
//                   }
//                 }
//               >
//                 ×
//               </button>
//             </div>
//           )}
//         </div>
// <div className="text-right mb-3 col-6 ">
//   <div className="d-flex justify-content-end">
//     <button
//       // type="button"
//       // className="btn btn-primary"
//       type="button"
//       className="btn btn-primary position-absolute"
//       onClick={addMedication}
//     >
//       +
//     </button>
//   </div>
// </div>
//       </div>
//     </div>
//   );
// };

// export default MedicationDetail;
