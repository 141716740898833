import React, { useState } from "react";

const SubHeadingFelid = ({ addFelidHandler }) => {
  const [inputValue, setInputValue] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    addFelidHandler({ ...inputValue, type: "SubHeading" });
    setInputValue(""); // Clear the input after submission
  };
  const onChange = (e) => {
    setInputValue((pre) => {
      return { ...pre, [e.target.name]: e.target.value };
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 align-self-center">
          <div className="form-group">
            <label htmlFor="fullName">Sub Heading Name</label>
            <input
              type="text"
              maxLength={1024}
              name="heading"
              required
              className="form-control black-border"
              id="fullName"
              value={inputValue.heading}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="col-12 align-self-center">
          <div className="w-100 d-flex justify-content-center align-item-center">
            <button type="submit" className="btn btn-primary">
              Add Felid
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SubHeadingFelid;
