import React, { useEffect, useState } from "react";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { useLocation, useParams } from "react-router-dom";
import commonHeader from "../../json/commonHeader.json";
import DataTable from "../pagination/DataTable";

const CallHistory = () => {
  const location = useLocation();
  const { id } = useParams();
  if (location.pathname === "/call/history") {
    document.title = "Call History - Digital Call Center";
  }
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [fetchData, setFetchData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState("fullName");
  const [sortOrder, setSortOrder] = useState("asc");

  const fetchCallHistory = async () => {
    const historyData = await jsonApi(
      appConstant.getCallHistory,
      { page, limit, search: search, sortField, sortOrder, id },
      null
    );
    if (!historyData.error && Array.isArray(historyData.data.data)) {
      setFetchData(historyData.data.data);
      setTotalData(historyData.data.total);
    } else {
      setError(historyData.message ? historyData.message : "No data received");
    }
  };

  useEffect(() => {
    fetchCallHistory();
  }, [page, limit, search, sortField, sortOrder]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          
          <div className="col-xl-11">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <h3 className="page-title text-dark font-weight-medium mt-2 ml-2">
              Call History
            </h3>
          </div>
            <div className="card mb-3">
              <div className="card-body">
                {error ? (
                  <div className="alert alert-danger">{error}</div>
                ) : (
                  <DataTable
                    headers={commonHeader.callHistoryHeader}
                    fetchedData={fetchData}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    totalData={totalData}
                    setLimit={setLimit}
                    init={fetchCallHistory}
                    // setSortField={setSortField}
                    // setSortOrder={setSortOrder}
                    // sortOrder={sortOrder}
                  />
                )}
              </div>
            </div>
          </div>
          {/* <div className="col-xl-1 d-none d-xl-block">
            <img
              src="assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              title="Ads"
              alt="Ads"
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CallHistory;
