import React, { useEffect, useState } from "react";
import commonHeader from "../../../../json/commonHeader.json";
import appConstant from "../../../../json/appConstant.json";
import useApis from "../../../../apis/useApis";
import DataTable from "../../../pagination/DataTable";

const HumanResourcesData = () => {
  document.title="Business Contact - Digital Call Center";
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchContactHuman = async () => {
    setLoading(true);
    const contactHuman = await jsonApi(
      appConstant.getBusinessContact,
      { page, limit, search: search, type: "Human Resource" },
      null,
      true
    );

    if (!contactHuman.error) {
      setAllContactDetails(contactHuman.data.data || []);
      setTotalData(contactHuman.data.total);
    } else {
      setError(
        contactHuman.message ? contactHuman.message : "No data received"
      );
    }
  };

  useEffect(() => {
    fetchContactHuman();
  }, [page, limit, search]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <>
              {error ? (
                <div className="alert alert-danger">{error}</div>
              ) : (
                <div className="card-body custom-field-button">
                  <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                    Human Resource Data
                  </h4>
                  <div className="row "></div>
                  <DataTable
                    headers={commonHeader.contactHumanDataHeader}
                    fetchedData={allContactDetails}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    totalData={totalData}
                    setLimit={setLimit}
                    setSearch={setSearch}
                    init={fetchContactHuman}
                  />
                </div>
              )}
            </>
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto d-block img-fluid"
            title="Ads"
            alt="ads"
          />
        </div>
      </div>
    </div>
  );
};

export default HumanResourcesData;
