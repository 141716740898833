import React, { useState, useEffect } from "react";

import useApis from "../../apis/useApis";
import appConstant from "./../../json/appConstant.json";
import AddFelidModel from "./AddFelidModel";
import ViewFelidComponent from "./ViewFelidComponent";
import ModelUiBig from "../model/ModelUiBig";
import ViewForm from "./viewForm/ViewForm";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const CustomFrom = () => {
  document.title = "Custom Form - Digital Call Center";
  const [initError, setInitError] = useState("");
  const [data, setData] = useState([]);
  const [dummyData, setDummyData] = useState([]);
  const [formListName, setFormListName] = useState({});
  const [ready, setReady] = useState(false);
  const [openAddFelidModal, setOpenAddFelidModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [name, setName] = useState([]);
  const openFormModal = async () => {
    setFormModal(true);
  };

  const { jsonApi } = useApis();
  const initFunctions = async () => {
    const fetchData = await jsonApi(appConstant.formSkeletonGet, {}, {});
    if (!fetchData.error) {
      setName(fetchData.data.data);
      // setData(JSON.parse(fetchData.data.data.data));
      // setDummyData(JSON.parse(fetchData.data.data.dummyData));
      // setReady(true);
    } else {
      setData([]);
      setDummyData([]);
      setReady(false);
    }
  };
  useEffect(() => {
    initFunctions();
  }, []);

  const changeOfFromName = async (e) => {
    const fetchData = await jsonApi(
      appConstant.skeletonGet,
      { id: e.target.value },
      {}
    );

    if (!fetchData.error) {
      // setName(fetchData.data.data);
      setData(fetchData.data.data.skeleton);
      setFormListName(fetchData.data.data);
      setReady(true);
    } else {
      setData([]);
      setDummyData([]);
      setReady(false);
    }
  };

  const addFelidHandler = (dataFelid) => {
    data.push(dataFelid);
    setData(data);
    addFelidHandlerCloseModel();
  };

  useEffect(() => {}, []);
  const addFelidButtonHandler = () => {
    setOpenAddFelidModal(true);
  };
  const addFelidHandlerCloseModel = () => {
    setOpenAddFelidModal(false);
  };

  const saveSkeleton = async () => {
    const fetchData = await jsonApi(
      appConstant.updateSkeleton,
      { id: formListName._id },
      { skeleton: data }
    );
  };
  return (
    <>
      <div className="container-fluid">
        {initError ? (
          <div class="alert alert-danger" role="alert">
            {initError}
          </div>
        ) : (
          <></>
        )}
        <div className="row">
          <h3 className="page-title text-dark font-weight-medium mt-2 ml-3">
            Custom Form
          </h3>
          <div className="col-xl-11">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-9 align-self-center">
                    <div className="form-group">
                      <label htmlFor="employmentLevel">Select from name</label>

                      <select
                        className="form-control"
                        value={formListName._id}
                        onChange={changeOfFromName}
                        disabled={ready}
                      >
                        <option value="">Select Form Type</option>
                        {name.map((name) => (
                          <option value={name._id}>{name.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3 align-self-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={openFormModal}
                    >
                      See Form
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary m-2"
                      onClick={saveSkeleton}
                    >
                      Save Form
                    </button>
                  </div>
                </div>
                <div class="">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Type</th>
                        <th scope="col">Name</th>
                        <th scope="col">Label</th>
                        <th scope="col">Placeholder</th>
                        <th scope="col">Required</th>
                        <th scope="col">Options</th>
                        <th scope="col">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((value, index) => (
                        <ViewFelidComponent
                          value={value}
                          key={index}
                          index={index}
                          removeHandler={() =>
                            setData((pre) => {
                              return pre.filter((item, i) => i !== index);
                            })
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="row">
                  <div className="col-12 ">
                    <div className="w-100 d-flex justify-content-center align-item-center">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addFelidButtonHandler}
                      >
                        Add Felid
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12 ">
                    <div className="w-100 d-flex justify-content-center align-item-center">
                      <button
                        type="button"
                        className="btn btn-primary m-2"
                        onClick={saveSkeleton}
                      >
                        Save Form
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              alt=""
              title="Ads"
            />
          </div>
        </div>
      </div>
      <AddFelidModel
        addFelidHandlerCloseModel={addFelidHandlerCloseModel}
        openAddFelidModal={openAddFelidModal}
        addFelidHandler={addFelidHandler}
      />
      <ModelUiBig
        show={formModal}
        handleClose={() => setFormModal(false)}
        title={`${formListName?.name} Form`}
      >
        <ViewForm
          data={{}}
          fromSkeleton={data}
          closeModal={() => setFormModal(false)}
          formListName={formListName?.name}
          show={formModal}
          demo={true}
        />
      </ModelUiBig>
    </>
  );
};

export default CustomFrom;
