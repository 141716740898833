import React, { useEffect, useState } from "react";
import appConstant from "../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { useNavigate, useParams } from "react-router-dom";
import commonHeader from "../../json/commonHeader.json";
import DataTable from "../pagination/DataTable";

const ListEvents = () => {
  document.title = "Events - Digital Call Center";
  const [data, setData] = useState([]);
  const [initError, setInitError] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { jsonApi } = useApis();
  const { id } = useParams();

  useEffect(() => {
    init();
  }, [page, limit, search]);

  const init = async () => {
    const res = await jsonApi(
      appConstant.getUpcomingEvents,
      { page, limit, search: search, id },
      null,
      null
    );
    if (!res.error) {
      setData(res.data.data);
      setTotalData(res.data.total);
      setInitError("");
    } else {
      setInitError(res.message ? res.message : "Something went wrong");
      console.error(res.message);
    }
  };

  return (
    <>
      {/* <div className="page-breadcrumb">
        <div className="row">
          <div className="col-12 align-self-center">
            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1 ml-4">
              List Of Events
            </h3>
          </div>
        </div>
        <div className="col-11 d-flex justify-content-end ">
          <button
            data-toggle="modal"
            data-target="#personalContact"
            className="page-link me-2 border border-primary"
            onClick={() => navigate(`/contact/event/calendar/${id}`)}
          >
            Show Calendar
          </button>
          <button
            data-toggle="modal"
            data-target="#personalContact"
            className="page-link me-2 border border-primary"
            onClick={() => navigate(`/contact/add/event/${id}`)}
          >
            + Add New Event
          </button>
        </div>
      </div> */}

      <div className="container-fluid">
        {initError && (
          <div className="alert alert-danger" role="alert">
            {initError}
          </div>
        )}

        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <h3 className="page-title text-dark font-weight-medium mt-2 ml-3 col-4">
              List Of Events
            </h3>
            <div className="col-8 d-flex justify-content-end ">
              <button
                data-toggle="modal"
                data-target="#personalContact"
                className="page-link me-2 border border-primary"
                onClick={() => navigate(`/contact/event/calendar/${id}`)}
              >
                Show Calendar
              </button>
              <button
                data-toggle="modal"
                data-target="#personalContact"
                className="page-link me-2 border border-primary"
                onClick={() => navigate(`/contact/add/event/${id}`)}
              >
                + Add New Event
              </button>
            </div>
          </div>
          <div className="col-xl-11">
            <div className="card mb-3">
              <div className="card-body">
                <DataTable
                  headers={commonHeader.eventsHeader}
                  fetchedData={data}
                  page={page}
                  limit={limit}
                  setPage={setPage}
                  totalData={totalData}
                  setLimit={setLimit}
                  setSearch={setSearch}
                  init={init}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              title="Ads"
              alt="Ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListEvents;
