import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";

const SubscriptionDetail = () => {
  document.title = "Subscription Detail - Digital Call Center";
  const { id } = useParams();
  const [error, setError] = useState(null);
  const { jsonApi } = useApis();
  const [fetchData, setFetchData] = useState([]);
  console.log(id);

  const getCompanyId = async () => {
    if (id) {
      try {
        const companyData = await jsonApi(
          appConstant.getCompanyById,
          { id },
          null,
          true
        );

        console.log(companyData);
        if (!companyData.error && companyData.data) {
          setFetchData(companyData.data);
          setError(companyData.message || "Something went wrong");
        }
      } catch (err) {
        setError("An error occurred while fetching company data.");
        console.error(err);
      }
    }
  };
  useEffect(() => {
    getCompanyId();
  }, [id]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <h3 className="page-title text-dark font-weight-medium mt-2 ml-3">
              Company Detail
            </h3>
            <div
              className="card mb-3 shadow-sm"
              style={{ borderRadius: "8px" }}
            >
              <div className="card-header bg-primary text-white">
                <h3 className="mb-0">Company Profile</h3>
              </div>

              <div className="card-body">
                <section className="mb-4">
                  <h4 className="text-primary border-bottom pb-2 mb-3">
                    <i className="bi bi-building me-2"></i>Company Details
                  </h4>
                  <div className="row text-secondary">
                    {[
                      { label: "Name", value: fetchData.companyName },
                      { label: "Address", value: fetchData.companyAddress },
                      { label: "URL", value: fetchData.companyUrl },
                      { label: "Email", value: fetchData.email },
                      {
                        label: "Industry Type",
                        value: fetchData.typeOfIndustry,
                      },
                      { label: "User Name", value: fetchData.userName },
                      // {
                      //   label: "Products Offered",
                      // },
                      // {
                      //   label: "Permissions",
                      //   value: fetchData.corporatePermissions ? fetchData.corporatePermissions.join(", ") : "N/A",
                      // },
                    ].map((item, index) => (
                      <div key={index} className="col-md-6 mb-2">
                        <div className="d-flex justify-content-between text-nowrap">
                          <strong>{item.label}:</strong>
                          <span>{item.value || "N/A"}</span>
                        </div>
                      </div>
                    ))}
                    <div classNmae="d-flex justify-content-between text-nowrap">
                      <strong className="px-3">Permissions:</strong>
                      <span className="px-4">
                        {fetchData.corporatePermissions
                          ? fetchData.corporatePermissions.join(", ")
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </section>

                <section className="mb-4">
                  <h4 className="text-primary border-bottom pb-2 mb-3">
                    <i className="bi bi-geo-alt me-2"></i>Address Details
                  </h4>
                  <div className="row text-secondary">
                    {[
                      { label: "City", value: fetchData.city },
                      { label: "State", value: fetchData.State },
                      { label: "Zip", value: fetchData.zip },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="col-md-4 mb-2 d-flex text-nowrap"
                      >
                        <strong className="me-2">{item.label}:</strong>
                        <span>{item.value || "N/A"}</span>
                      </div>
                    ))}
                  </div>
                </section>

                <section className="mb-4">
                  <h4 className="text-primary border-bottom pb-2 mb-3">
                    <i className="bi bi-person-lines-fill me-2"></i>Contact
                    Details
                  </h4>
                  <div className="row text-secondary">
                    {[
                      { label: "Contact Name", value: fetchData.contactName },
                      { label: "Contact Email", value: fetchData.contactEmail },
                      {
                        label: "Corporate Phone",
                        value: fetchData.corporatePhone,
                      },
                      { label: "Corporate Fax", value: fetchData.corporateFax },
                      {
                        label: "Contact Extension",
                        value: fetchData.contactExtension,
                      },
                      { label: "Cell Number", value: fetchData.cellNumber },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="col-md-4 mb-2 d-flex text-nowrap"
                      >
                        <strong className="me-2">{item.label}:</strong>
                        <span>{item.value || "N/A"}</span>
                      </div>
                    ))}
                  </div>
                </section>

                <section className="mb-4">
                  <h4 className="text-primary border-bottom pb-2 mb-3">
                    Bank Details
                  </h4>
                  <div className="row text-secondary">
                    {[
                      {
                        label: "Bank Account",
                        value:
                          fetchData.bankAccount?.length > 0
                            ? fetchData.bankAccount
                            : "Not Available",
                      },
                      {
                        label: "Bank Card",
                        value:
                          fetchData.bankCard?.length > 0
                            ? fetchData.bankCard
                            : "Not Available",
                      },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="col-md-6 mb-2 d-flex text-nowrap"
                      >
                        <strong className="me-2">{item.label}:</strong>
                        <span>{item.value}</span>
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              alt=""
              title="Ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionDetail;
