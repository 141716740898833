import React, { useEffect, useState } from "react";
import commonHeader from "../../../json/commonHeader.json";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import DataTable from "../../pagination/DataTable";

const BusinessContactData = () => {
  document.title="Business Contact - Digital Call Center";
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [sortField, setSortField] = useState("fullName");
  const [sortOrder, setSortOrder] = useState("asc");

  const fetchBusinessContactData = async () => {
    const businessContact = await jsonApi(
      appConstant.getBusinessContact,
      {
        page,
        limit,
        search: search,
        type: ["Human Resource", "Business Info", "Business Contact"],
        sortField,
        sortOrder,
      },
      null,
      true
    );

    if (!businessContact.error) {
      setAllContactDetails(businessContact.data.data || []);
      setTotalData(businessContact.data.total);
    } else {
      setError(
        businessContact.message ? businessContact.message : "No data received"
      );
    }
  };

  useEffect(() => {
    fetchBusinessContactData();
  }, [page, limit, search, sortField, sortOrder]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <>
              {error ? (
                <div className="alert alert-danger">{error}</div>
              ) : (
                <div className="card-body custom-field-button">
                  <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                    Business Data
                  </h4>
                  <DataTable
                    headers={commonHeader.businessContactData}
                    fetchedData={allContactDetails}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    totalData={totalData}
                    setLimit={setLimit}
                    setSearch={setSearch}
                    init={fetchBusinessContactData}
                    setSortField={setSortField}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                  />
                </div>
              )}
            </>
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto d-block img-fluid"
            title="Ads"
            alt="ads"
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessContactData;
