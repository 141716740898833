import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { validateEvent } from "../validation/Validation";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import "../LeadData/DatePickerStyle.css";

const CreateEvents = () => {
  document.title = "Events - Digital Call Center";
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [dateCalendar, setDateCalendar] = useState(null);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const { id } = useParams();

  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const formattedDate = dateCalendar ? dateCalendar.toISOString() : null;
    const payload = { id, ...data, date: formattedDate };

    if (isEditing) {
      return;
    }
    setIsEditing(true);

    const response = await jsonApi(
      appConstant.createEvents,
      null,
      payload,
      false,
      true
    );
    if (!response.error) {
      setDateCalendar(null);
      reset({
        eventsName: "",
        date: "",
        eventsDescription: "",
      });
      setError("");
      navigate(`/contact/list/events/${id}`);
    }
    setTimeout(() => setIsEditing(false), 1000);
    // else {
    //   setError(response.message || "Failed to create event.");
    // }
  };

  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      setDateCalendar(date);
      setValue("date", date.toISOString(), { shouldValidate: true });
      setError("");
    } else {
      setError("Invalid date format. Please select a valid date.");
    }
  };

  return (
    <>
      {/* <div className="page-breadcrumb">
        <div className="row">
          <div className="col-12 align-self-center">
            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1 ml-4">
              Add New Event
            </h3>
          </div>
        </div>
        <div className="col-11 d-flex justify-content-end ">
          <button
            data-toggle="modal"
            data-target="#personalContact"
            className="page-link me-2 border border-primary"
            onClick={() => navigate("/contact/event/calendar")}
          >
            Show Calendar
          </button>
        </div>
      </div> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <h3 className="page-title text-dark font-weight-medium mt-2 ml-3">
              Add New Event
            </h3>
            <div className="col-6 d-flex justify-content-end ">
              <button
                data-toggle="modal"
                data-target="#personalContact"
                className="page-link me-2 border border-primary"
                onClick={() => navigate(`/contact/event/calendar/${id}`)}
              >
                Show Calendar
              </button>
            </div>
          </div>
          <div className="col-xl-11">
            <div className="card">
              <div className="card-body custom-field-button">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}

                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">Date</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        control={control}
                        name="date"
                        defaultValue={null}
                        render={({ field }) => {
                          return (
                            <DatePicker
                              value={field.value ? new Date(field.value) : null}
                              onChange={(date) => {
                                const selectedDate = date
                                  ? date.toDate()
                                  : null;
                                field.onChange(selectedDate); // Update react-hook-form field value
                                handleDateChange(selectedDate); // Update dateCalendar state
                              }}
                              // onChange={(date) =>
                              //   field.onChange(date ? date.toDate() : null)
                              // }
                              format="DD/MM/YYYY"
                              className="form-control custom-date-picker"
                              calendarPosition="bottom-center"
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              minDate={new Date()}
                            />
                          );
                        }}
                      />
                      {errors.date && (
                        <p className="text-danger">{errors.date.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Event Name
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="eventsName"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            maxLength={256}
                            placeholder="Event Name"
                            className={`form-control black-border ${
                              errors.eventsName ? "is-invalid" : ""
                            }`}
                            {...register("eventsName", {
                              required: "Event Name is required",
                              validate: (value) =>
                                validateEvent(value, "Event Name"),
                            })}
                          />
                        )}
                      />
                      {errors.eventsName && (
                        <p className="text-danger">
                          {errors.eventsName.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Event Description
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="eventsDescription"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            placeholder="Event Description"
                            maxLength={1024}
                            className={`form-control black-border ${
                              errors.eventsDescription ? "is-invalid" : ""
                            }`}
                            {...register("eventsDescription", {
                              required: "Event Description is required",
                            })}
                          />
                        )}
                      />
                      {errors.eventsDescription && (
                        <p className="text-danger">
                          {errors.eventsDescription.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-lg-2 col-md-3 col-sm-4">&nbsp;</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-rounded px-4"
                      >
                        Create Event
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
              alt="Ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEvents;
